import { Circle, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { KEY_GOOGLE_MAPS } from "src/core/GLOBAL_VAR";
import React, { useState } from "react";

interface SimpleMapProps {
  position: { latitud: number; longitud: number };
  onMarkerPosition: (lat: number, lng: number) => void;
  radius: number;
}

const containerStyle = {
  height: "500px",
  width: "100%",
};

const image = {
  url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
  // size: new window.google.maps.Size(20, 32),
  // origin: new window.google.maps.Point(0, 0),
  // anchor: new window.google.maps.Point(10, 32),
};

const SimpleMap: React.FC<SimpleMapProps> = ({
  position,
  radius,
  onMarkerPosition,
}) => {
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  }>({ lat: position.latitud, lng: position.latitud });

  const handleMapClick = ({ lat, lng }: { lat: number; lng: number }) => {
    setMarkerPosition({ lat, lng });
    onMarkerPosition(lat, lng);
  };

  const MyMarker: React.FC = () => (
    <>
      <Marker
        position={{
          lat: position.latitud || 40.401580832976244,
          lng: position.longitud || -3.6907246590693443,
        }}
        icon={image}
      />
      {radius && (
        <Circle
          center={{
            lat: position.latitud || 40.401580832976244,
            lng: position.longitud || -3.6907246590693443,
          }}
          radius={radius}
          options={{
            strokeWeight: 2,
            fillOpacity: 0.2,
            strokeColor: "#000",
            fillColor: "#b81d1df0",
          }}
        />
      )}
    </>
  );

  return (
    <LoadScript googleMapsApiKey={KEY_GOOGLE_MAPS}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: position.latitud,
          lng: position.longitud,
        }}
        onClick={(e) => {
          const lat = e.latLng?.lat();
          const lng = e.latLng?.lng();
          handleMapClick({ lat: Number(lat), lng: Number(lng) });
        }}
        zoom={13}
      >
        {markerPosition.lat && markerPosition.lng && <MyMarker />}
      </GoogleMap>
    </LoadScript>
  );
};
export default React.memo(SimpleMap);
