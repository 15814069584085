import { Space } from "antd";
import { ISpaceProps } from "src/core/interfaces";
import styled from "styled-components";

const SpaceStyled = styled(Space)<ISpaceProps>`
  ${({ block }) => block && "width: 100%;"}
  justify-content: ${({ justify }) => justify || "flex-start"};
  ${({ padding }) =>
    padding &&
    `
    padding: ${padding}px;
  `}
  ${({ whiteSpace }) =>
    whiteSpace &&
    `
    white-space: ${whiteSpace};
  `}
`;

export default SpaceStyled;
