import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Modal,
  message,
  Table,
  Tooltip,
  Tag,
  Form,
  Button,
  Input,
  List,
  Row,
  Col,
  Divider,
  Space,
  Spin,
} from "antd";
import {
  CheckCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
/* import InputMask from 'react-input-mask';
import { createDevice, getDevice, updateDevice } from 'src/services/devices'; */
import moment from "moment";

interface IFile {
  id: number;
  name: string;
  description: string;
  url: string;
  type: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  advertiserId: number;
  advertiser: { id: number; name: string };
  tags: { id: number; name: string }[];
  size: number;
  delay: number;
}

interface IModalInfo {
  isOpen: 0 | 1 | 2;
  toggle: (value: 0 | 1 | 2) => any;
  file: IFile;
}

const ModalInfoFile = ({ isOpen, toggle, file }: IModalInfo) => {
  const [isLoading, setIsLoading] = useState(true);

  const closeModal = () => {
    toggle(0);
    setIsLoading(false);
  };

  const PagePreview = () => {
    const video = useRef<HTMLVideoElement>(null);

    if (file.type.includes("image")) {
      return (
        <div style={{ marginTop: "30px" }}>
          <img
            width="100%"
            src={file.url}
            alt="Un momento..."
            style={{ border: "none" }}
          />
        </div>
      );
    }
    if (file.type.includes("video")) {
      return (
        <div
          style={{
            marginTop: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <video
            ref={video}
            src={file.url}
            controls
            autoPlay
            loop
            width="100%"
            height={"420px"}
            style={{ border: "none", background: "#000000" }}
            className="transited"
          />
        </div>
      );
    }
    return <></>;
  };

  const PageInfo = () => {
    return (
      <div>
        <List.Item className="transited">
          <Divider style={{ color: "#fff", margin: "10px 0px" }} />
          <Row>
            <span style={{ paddingRight: "10px" }}>
              <InfoCircleOutlined />
            </span>
            <div style={{ marginRight: "10px" }}>
              <strong>ID: </strong>
            </div>
            <div>{file.id}</div>
          </Row>
          <Divider style={{ margin: "10px 0px" }} />
          <Row>
            <span style={{ paddingRight: "10px" }}>
              <InfoCircleOutlined />
            </span>
            <div style={{ marginRight: "10px" }}>
              <strong>Nombre: </strong>
            </div>
            <div>{file.name}</div>
          </Row>
          <Divider style={{ margin: "10px 0px" }} />
          <Row>
            <span style={{ paddingRight: "10px" }}>
              <InfoCircleOutlined />
            </span>
            <div style={{ marginRight: "10px" }}>
              <strong>Fecha de subida: </strong>
            </div>
            <div>{moment(file.createdAt).format("DD-MM-YYYY")}</div>
          </Row>

          <Divider style={{ margin: "10px 0px" }} />
          <Row>
            <span style={{ paddingRight: "10px" }}>
              <InfoCircleOutlined />
            </span>
            <div style={{ marginRight: "10px" }}>
              <strong>Url </strong>
            </div>
            <div>
              <a target={"_blank"} href={file.url}>
                {file.url.split("/")[file.url.split("/").length - 1]}
              </a>
            </div>
          </Row>
          <Divider style={{ margin: "10px 0px" }} />
          <Row>
            <span style={{ marginRight: "10px" }}>
              <InfoCircleOutlined />
            </span>
            <div style={{ marginRight: "10px" }}>
              <strong>Estado: </strong>
            </div>
            <div>{file.status ? "Activo" : "Inactivo"}</div>
          </Row>
          <Divider style={{ margin: "10px 0px" }} />
          <Row>
            <span style={{ paddingRight: "10px" }}>
              <InfoCircleOutlined />
            </span>
            <div style={{ marginRight: "10px" }}>
              <strong>Descripción: </strong>
            </div>
            <div>
              <p>{file.description}</p>
            </div>
          </Row>
          <Divider style={{ margin: "10px 0px" }} />
          {file.advertiser && (
            <Row>
              <span style={{ paddingRight: "10px" }}>
                <InfoCircleOutlined />
              </span>
              <div style={{ marginRight: "10px" }}>
                <strong>Anunciante: </strong>
              </div>
              <div>{file.advertiser.name}</div>
            </Row>
          )}

          <Divider style={{ margin: "10px 0px" }} />
          <Row>
            <span style={{ paddingRight: "10px" }}>
              <InfoCircleOutlined />
            </span>
            <div style={{ marginRight: "10px" }}>
              <strong>Tipo: </strong>
            </div>
            <div>{file.type}</div>
          </Row>
          <Divider style={{ margin: "10px 0px" }} />
          <Row>
            <span style={{ paddingRight: "10px" }}>
              <InfoCircleOutlined />
            </span>
            <div style={{ marginRight: "10px" }}>
              <strong>Duración: </strong>
            </div>
            <div>{file.delay} segundos</div>
          </Row>
          <Divider style={{ margin: "10px 0px" }} />
          <Row>
            <span style={{ paddingRight: "10px" }}>
              <InfoCircleOutlined />
            </span>
            <div style={{ marginRight: "10px", marginBottom: "5px" }}>
              <strong>Tags: </strong>
            </div>
            <div style={{ width: "100%" }}>
              {file.tags.length > 0 &&
                file.tags.map((item) => (
                  <small style={{ margin: "3px" }} className="tag">
                    {item.name}
                  </small>
                ))}
            </div>
          </Row>
        </List.Item>
      </div>
    );
  };

  return (
    <Modal
      style={{ padding: "0px" }}
      getContainer={"div"}
      bodyStyle={{ background: isOpen == 1 ? "#fff" : "unset" }}
      title={isOpen == 1 ? `Informacion del archivo` : ""}
      open={isOpen != 0}
      width={isOpen == 1 ? 400 : undefined}
      onCancel={closeModal}
      footer={<></>}
    >
      {isOpen == 1 ? <PageInfo /> : <PagePreview />}
    </Modal>
  );
};

export default ModalInfoFile;
