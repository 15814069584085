import React, { FC, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Checkbox, message, List, Button, Col } from "antd";
import { IZoneFormProps } from "src/core/interfaces";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CardToolsToggle from "src/components/CardToolsToggle";
import {
  CheckCircleFilled,
  ClockCircleOutlined,
  CloseCircleOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import ListPackages from "./ListPackages";
import moment from "moment";
import SortableItem from "./SortableItem";
import { IZoneInput } from "src/core/dtos";
import { getPackages, getPackagesByZoneId } from "src/services/campaign";
import usePageLimit from "src/hooks/usePageLimit";
import { getTotalDelay } from "src/services/files";

dayjs.extend(customParseFormat);

interface IPackagesSelectProps {
  checked: CheckboxValueType[];
  /*   setChecked: (values: CheckboxValueType[]) => void; */
  setChecked: React.Dispatch<React.SetStateAction<CheckboxValueType[]>>;
  messageApi: any;
  setAllSelected: (value: boolean) => void;
  allSelected: boolean;
  delay: number;
}

const PackagesSelect: FC<IPackagesSelectProps> = ({
  checked,
  setChecked,
  messageApi,
  setAllSelected,
  allSelected,
  delay,
}) => {
  const { id } = useParams();
  const [packages, setPackages] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState<string[]>();
  const [totalDelay, setTotalDelay] = useState<number>(delay);
  const [orderList, setOrderList] = useState(false);

  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();

  const calculateTotalDelay = (checkedValues: CheckboxValueType[]) => {
    let TotalDelay = 0;
    checkedValues.forEach((value) => {
      const file = packages.find((item: any) => item.id === value);
      if (file) {
        TotalDelay += file.delay;
      }
    });
    return TotalDelay;
  };

  // useEffect(() => {
  //   setTotalDelay(calculateTotalDelay(checked));
  // }, [checked]);

  const formattedTotalDelay = moment.utc(totalDelay * 1000).format("mm:ss");

  const loadPackages = async () => {
    const response: any = id
      ? await getPackagesByZoneId(+id, [
          { key: "page", value: page },
          { key: "limit", value: limit },
          { key: "search", value: String(search) },
          { key: "status", value: "APPROVED" },
        ])
      : await getPackages([
          { key: "page", value: page },
          { key: "limit", value: limit },
          { key: "search", value: String(search) },
          { key: "status", value: "APPROVED" },
        ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    let sortedData = [...data?.data];

    if (data) {
      sortedData.sort((a, b) => {
        let orderA = a.order || 0;
        let orderB = b.order || 0;
        return orderA - orderB;
      });
    }

    const filterCheck = sortedData
      .filter((item: any) => allSelected || item.selected)
      .map((item: any) => item.id);

    setPackages([...sortedData]);
    setTotal(data?.total);
    setChecked([...checked, ...filterCheck]);

    setTotalDelay(delay);
  };

  const loads = async () => {
    setLoading(true);
    await loadPackages();
    setLoading(false);
  };

  useEffect(() => {
    loads();
  }, [page, search, tags]);

  const loadTotalDelay = async () => {
    const response: any = await getTotalDelay();

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    setTotalDelay(data?.data);
  };

  /*   const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setPackages((prev) => {
        const activeIndex = prev.findIndex((i) => i.id == active.id);
        const overIndex = prev.findIndex((i) => i.id == over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  }; */

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setPackages((prev) => {
        const activeIndex = prev.findIndex((i) => i.id == active.id);
        const overIndex = prev.findIndex((i) => i.id == over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });

      setChecked((prev) => {
        const activeIndex = prev.findIndex((i) => i == active.id);
        const overIndex = prev.findIndex((i) => i == over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  return (
    <>
      <Card>
        <Card>
          <CardToolsToggle
            style={{ margin: "0px" }}
            placeholder="Buscar archivo..."
            /*   setTags={setTags}
              setSearch={setSearch} */
            tools={[
              {
                type: "default",
                tooltip: allSelected
                  ? "Quitar seleccion de las campañas"
                  : "Seleccionar todos los campañas",
                icon: allSelected ? (
                  <CloseCircleOutlined />
                ) : (
                  <CheckCircleFilled />
                ),
                title: allSelected ? `Quitar seleccion` : `Seleccionar todo`,
                size: "large",
                onClick: () => {
                  if (allSelected) {
                    setChecked([]);
                    setTotalDelay(calculateTotalDelay([]));
                  } else {
                    loadTotalDelay();
                    setChecked(
                      packages.map((item: any) => {
                        return item.id;
                      })
                    );
                  }
                  setAllSelected(!allSelected);
                },
              },
              {
                type: "default",
                tooltip: "Tiempo total de reproducción",
                icon: <ClockCircleOutlined />,
                title: `${formattedTotalDelay}`,
                size: "large",
              },
              {
                type: "dashed",
                size: "large",
                title: "",
                tooltip: orderList
                  ? "Seleccionar archivos"
                  : "Ordenar archivos seleccionados",
                icon: orderList ? (
                  <UnorderedListOutlined />
                ) : (
                  <OrderedListOutlined />
                ),
                onClick: () => setOrderList(!orderList),
              },
            ]}
          />
        </Card>
        {orderList ? (
          <DndContext onDragEnd={onDragEnd}>
            <Card style={{ overflowY: "auto", width: "100%" }}>
              <SortableContext
                items={packages.map((i) => i.id)}
                strategy={verticalListSortingStrategy}
              >
                <List
                  loading={loading}
                  dataSource={packages.filter((item) =>
                    checked.includes(item.id)
                  )}
                  renderItem={(item) => (
                    <SortableItem Package={item} refresh={loads} isAssign />
                  )}
                />
              </SortableContext>
            </Card>
          </DndContext>
        ) : (
          <Checkbox.Group
            value={checked ?? []}
            onChange={(checkedValues: CheckboxValueType[]) => {
              setChecked(checkedValues);
              setTotalDelay(calculateTotalDelay(checkedValues));
            }}
            style={{ width: "100%" }}
          >
            <Card style={{ overflowY: "auto", width: "100%" }}>
              <List
                loading={loading}
                dataSource={packages}
                renderItem={(item) => (
                  <ListPackages Package={item} refresh={loads} />
                )}
              />
              {packages?.length < total && (
                <Col
                  style={{
                    marginTop: "1em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => handlePageChange(page + 1)}
                  >
                    Cargar más
                  </Button>
                </Col>
              )}
            </Card>
          </Checkbox.Group>
        )}
      </Card>
    </>
  );
};
export default PackagesSelect;
