import AppLayout from "src/layouts/AppLayout";
import AuthLayout from "src/layouts/AuthLayout";
import Page from "src/components/layout/Page";
import { useSelector } from "src/redux/hooks";
import { RootState } from "src/redux/store";
import NotFound404 from "src/components/404";

const NotFound = () => {
  const { isLogged } = useSelector((state: RootState) => state.auth);

  if (!isLogged) {
    return (
      <AuthLayout>
        <Page title="404 | App4Riders">
          <NotFound404
            text="Lo sentimos, la página que intentas visitar no existe."
            route="/auth/login"
            textButton="Ir al inicio de sesión"
          />
        </Page>
      </AuthLayout>
    );
  }

  return (
    <AppLayout title="404">
      <NotFound404
        text="Lo sentimos, la página que intentas visitar no existe."
        route="/dashboard"
        textButton="Ir al inicio"
      />
    </AppLayout>
  );
};

export default NotFound;
