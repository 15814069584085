import { memo } from "react";
import Routes from "./routes/index";
import { AppProvider } from "./context/AppProvider";

function App() {
  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
}

export default memo(App);
