import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Select, Space, message } from "antd";
import { useEffect, useState } from "react";
import { IZone } from "src/core/dtos";
import { getZones } from "src/services/zones";

const SelectZone = ({
  selected,
  setSelected,
}: {
  selected: number | false;
  setSelected: (number: number | false) => void;
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: number; label: string }[]>(
    []
  );
  const loadZones = async () => {
    const {
      data: { message, statusCode, data },
    } = await getZones();

    if (statusCode != 200) {
      messageApi.error("Ha ocurrido un error al cargar los datos");
      return;
    }

    const tempOptions: any[] = data.map((zone: IZone) => ({
      value: zone.id,
      label: zone.name,
    }));

    setOptions(tempOptions);
  };

  const load = async () => {
    setLoading(true);
    await loadZones();
    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <>
      <Form.Item style={{ width: "100%", margin: "0px" }}>
        <Select
          showSearch
          allowClear
          size="large"
          loading={loading}
          optionFilterProp="children"
          placeholder="Selecciona una zona"
          style={{ width: "100%", marginBottom: "0px" }}
          onChange={(e) => {
            setSelected(e);
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={options}
        />
      </Form.Item>
      {contextHolder}
    </>
  );
};

export default SelectZone;
