import CardStyled from "./../../../components/styled/Card";
import styled from "styled-components";

export const LoginFormWrapper = styled.section`
  background-color: #79ac99;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginFormCard = styled(CardStyled)`
  width: 400px;
  margin: 20px 10px 10px 10px;
  box-shadow: rgb(11 43 158 / 15%) 0px 6px 20px -6px;
`;

export const LoginFillerComponentStyled = styled.div`
  background: #4d266f;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
  > * {
    color: white !important;
  }
`;
