import {
  PlusOutlined,
  MergeCellsOutlined,
  MobileOutlined,
  UploadOutlined,
  FilterOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Row, Spin } from "antd";
import SpaceStyled from "src/components/styled/Space";
import DevicesTable from "../components/DevicesTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CardToolsToggle from "src/components/CardToolsToggle";
import ModalAsignDeviceToClient from "../components/ModalAsignDeviceToClient";
import ModalAddDevice from "../components/ModalAddDevice";
import ModalCloneDevice from "../components/ModalCloneDevice";
import ModalMapDevice from "../components/ModalMapDevice";
import { IPermission } from "src/core/dtos";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Head from "src/components/Head";
import Card from "src/components/Card";
import { AppContext } from "src/context/AppProvider";

export default () => {
  const { user, permissions } = useSelector((state: any) => state.auth);
  const { socket } = useContext(AppContext);
  const permDev = permissions.find(
    (item: IPermission) => item.module === "Devices"
  );
  const [search, setSearch] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeEvent, setActiveEvent] = useState(false);
  const [isOpenMap, setIsOpenMap] = useState(false);
  const [isOpenClone, setIsOpenClone] = useState(false);
  const [countDevices, setCountDevices] = useState<number>(0);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [showStatus, setShowStatus] = useState(0);

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleStatus = (status: number) => {
    setShowStatus(status);
    setShowFilter(false);
  };

  const filterMenu = (
    <Menu>
      <Menu.Item key="active" onClick={() => handleStatus(1)}>
        Dispositivos activos
      </Menu.Item>
      <Menu.Item key="inactive" onClick={() => handleStatus(2)}>
        Dispositivos inactivos
      </Menu.Item>
      <Menu.Item key="locked" onClick={() => handleStatus(3)}>
        Dispositivos bloqueados
      </Menu.Item>
      <Menu.Item key="unlocked" onClick={() => handleStatus(4)}>
        Dispositivos no bloqueados
      </Menu.Item>
    </Menu>
  );

  return (
    <SpaceStyled block={true} direction="vertical">
      <Row justify="center">
        <Col xs={24} md={22}>
          <SpaceStyled block={true} direction="vertical" size="large">
            <Head bread={["Dispositivos"]}>
              {user?.role === "ALLY" ? (
                "Dispositivos"
              ) : state?.id ? (
                <>
                  Dispositivos de{" "}
                  <Link
                    to={`/riders/${state.id}/edit`}
                    style={{ color: "#143359" }}
                  >
                    {state.name}
                  </Link>
                </>
              ) : (
                "Gestión de dispositivos"
              )}
            </Head>
            <Card>
              <CardToolsToggle
                placeholder="Buscar dispositivo..."
                setSearch={setSearch}
                tools={
                  state?.id
                    ? []
                    : [
                        {
                          type: "default",
                          tooltip: "Ver dispositivos activos en el mapa",
                          title: "Ver mapa",
                          icon: <EnvironmentOutlined />,
                          visibility:
                            user.role === "CLIENT" ? false : permDev.isCre,
                          onClick: () => navigate("/map"),
                        },
                        {
                          type: "primary",
                          tooltip:
                            (state?.id ? "Asignar" : "Agregar") +
                            " un dispositivo nuevo",
                          title:
                            (state?.id ? "Asignar" : "Agregar") +
                            " dispositivo",
                          icon: <PlusOutlined />,
                          visibility:
                            user.role === "CLIENT" ? false : permDev.isCre,
                          onClick: () => setIsOpenModal(true),
                        },
                      ]
                }
              />
              {showFilter && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "16px",
                  }}
                >
                  <Dropdown
                    overlay={filterMenu}
                    trigger={["click"]}
                    visible={showFilter}
                    placement="topRight"
                  >
                    <Button type="text" onClick={handleFilter}></Button>
                  </Dropdown>
                </div>
              )}
              <Spin spinning={loading}>
                <DevicesTable
                  search={search}
                  activeEvent={activeEvent}
                  setDevicesToSocket={setDevices}
                  id={
                    user?.role === "ALLY"
                      ? user?.allyId
                      : state?.id
                      ? state?.id
                      : false
                  }
                  setOpenModal={setIsOpenModal}
                  setOpenMap={setIsOpenMap}
                  setCountDevices={setCountDevices}
                  setEdit={setEdit}
                  showStatus={showStatus}
                />
              </Spin>
            </Card>
          </SpaceStyled>
        </Col>
      </Row>

      {state?.id && isOpenModal && (
        <ModalAsignDeviceToClient
          isOpen={isOpenModal}
          toggle={setIsOpenModal}
          client={state}
          setActiveEvent={() => setActiveEvent(!activeEvent)}
        />
      )}

      {state?.id && isOpenClone && (
        <ModalCloneDevice
          isOpen={isOpenClone}
          toggle={setIsOpenClone}
          client={state}
          setActiveEvent={() => setActiveEvent(!activeEvent)}
        />
      )}

      <ModalMapDevice
        isOpen={isOpenMap}
        toggle={setIsOpenMap}
        edit={edit}
        setEdit={() => setEdit(false)}
      />

      {!state?.id && (
        <ModalAddDevice
          isOpen={isOpenModal}
          toggle={setIsOpenModal}
          edit={edit}
          setEdit={() => setEdit(false)}
          setActiveEvent={() => setActiveEvent(!activeEvent)}
        />
      )}
    </SpaceStyled>
  );
};
