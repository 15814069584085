import { Col, Layout, Row } from "antd";
import {
  IAuthWrapperColStyledProps,
  IAuthWrapperRowStyledProps,
} from "src/core/interfaces";
import styled from "styled-components";

const { Header, Footer } = Layout;

export const AuthWrapperRowStyled = styled(Row)<IAuthWrapperRowStyledProps>`
  min-height: 100vh;
  ${({ ismobile }) => ismobile && "margin-top: 64px;"}
`;

export const AuthWrapperColStyled = styled(Col)<IAuthWrapperColStyledProps>`
  min-height: ${({ ismobile }) => (ismobile ? "unset" : "100vh")};
`;

export const AuthHeaderStyled = styled(Header)`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: #fff !important;
`;

export const AuthFooterStyled = styled(Footer)`
  text-align: center;
`;
