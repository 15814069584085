import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Tooltip, Typography } from "antd";
import SpaceStyled from "src/components/styled/Space";
import AlliesTable from "src/modules/allies/components/AlliesTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IPermission } from "src/core/dtos";
import { useEffect, useState } from "react";
import Head from "src/components/Head";
import Card from "src/components/Card";
import CardToolsToggle from "src/components/CardToolsToggle";

const AlliesPage = () => {
  const { permissions } = useSelector((state: any) => state.auth);
  const permAlly = permissions.find(
    (item: IPermission) => item.module === "Allies"
  );
  const navigate = useNavigate();
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);

  useEffect(() => {
    // cleaning search when input is empty
    if (!tempSearch) setSearch("");
  }, [tempSearch]);

  return (
    <SpaceStyled block={true} direction="vertical">
      <Row justify="center">
        <Col xs={24} md={22}>
          <SpaceStyled block={true} direction="vertical" size="large">
            <Head bread={["Riders"]}>Gestión de riders</Head>
            <Card>
              <CardToolsToggle
                placeholder="Buscar riders..."
                setSearch={setSearch}
                tools={[
                  {
                    type: "primary",
                    tooltip: "Crear nuevo rider",
                    title: "Crear rider",
                    icon: <PlusOutlined />,
                    visibility: permAlly.isCre,
                    onClick: () => navigate("new"),
                  },
                ]}
              />
              <AlliesTable search={search} tags={tags} />
            </Card>
          </SpaceStyled>
        </Col>
      </Row>
    </SpaceStyled>
  );
};

export default AlliesPage;
