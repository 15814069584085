import { Modal, message, Form, Button, Input } from "antd";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createDevice, getDevice, updateDevice } from "src/services/devices";
import MacAddressInput from "./MacAddressInput";

export interface IModalRegisterDevice {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  setActiveEvent: () => void;
  edit: any;
  setEdit: Dispatch<SetStateAction<any>>;
}

const ModalAddDevice = ({
  isOpen,
  toggle,
  setActiveEvent,
  edit,
  setEdit,
}: IModalRegisterDevice) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(true);
  const [code, setCode] = useState("");
  const [form] = Form.useForm();

  const loadDevice = async () => {
    setIsLoading(true);

    const { data, status } = (await getDevice(edit)) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error("Ha ocurrido un error al cargar los datos");
      setIsLoading(false);
      return;
    }

    if (data?.statusCode != 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    form?.setFieldsValue(data?.data);
    console.log("setCode: ", data?.data?.code);
    setCode(data?.data?.code);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      if (edit) {
        loadDevice();
      }
      setIsLoading(false);
    }
  }, [isOpen, edit]);

  const closeModal = () => {
    setActiveEvent();
    setEdit(false);
    toggle(false);
    setCode("");
    form?.setFieldsValue({ code: "" });
    setIsLoading(false);
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);

    const form = { code: String(values.code).toUpperCase() };

    const { data } = (await (edit
      ? updateDevice(edit, form)
      : createDevice(form))) || { data: undefined };

    if (data?.statusCode != 200) {
      messageApi.error(
        data?.message ||
          `No se ha ${edit ? "actualizado" : "guardado"} correctamente`
      );
      setIsLoading(false);
      return;
    }

    messageApi.success(
      `Dispositivos ${edit ? "actualizado" : "guardado"} correctamente`
    );

    closeModal();
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={`${edit ? "Editar un" : "Agregar nuevo"} dispositivo`}
        open={isOpen}
        width={720}
        onCancel={() => toggle(false)}
        footer={<></>}
      >
        <Form
          form={form}
          name="horizontal_login"
          layout="inline"
          onFinish={onFinish}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Form.Item
              name="code"
              style={{ width: "60%" }}
              rules={[
                { required: true, message: "Introduzca una dirección MAC!" },
                { min: 12, message: "La dirección es incorrecta" },
                {
                  pattern: /[0-9a-fA-F:]{17}/,
                  message: "La dirección MAC es incorrecta",
                },
              ]}
            >
              <MacAddressInput
                initialValue={code}
                loading={isLoading}
                onChange={setCode}
              />
            </Form.Item>
          </div>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <Button
              onClick={() => closeModal()}
              type="default"
              htmlType="button"
              style={{ marginRight: 15 }}
            >
              Volver
            </Button>
            <Form.Item shouldUpdate>
              <Button
                loading={isLoading}
                type="primary"
                htmlType="submit"
                disabled={code?.length < 17}
              >
                {isLoading ? "Un momento" : "Guardar"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default ModalAddDevice;
