import { CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  message,
  Row,
  Typography,
} from "antd";
import { getPermissionsByUser, updateManyPermissions } from "src/services/users";
import SpaceStyled from "src/components/styled/Space";
import { modules, ObjModules } from "src/core/constants";
import { IModulePermission } from "src/core/interfaces";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { IPermission } from "src/core/dtos";

const defaultPermissions: IModulePermission[] = modules.map((module) => ({
  module: module,
  isCre: false,
  isRead: false,
  isEdit: false,
  isDel: false,
}));

const UserPermissionsForm = ({ id }: { id: number }) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [permissions, setPermissions] = useState(defaultPermissions);
  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const handleChangePermission = (index: number, perms: IModulePermission) => {
    const newPermissions = [...permissions];
    newPermissions[index].isCre = perms?.isCre;
    newPermissions[index].isRead = perms?.isRead;
    newPermissions[index].isEdit = perms?.isEdit;
    newPermissions[index].isDel = perms?.isDel;
    setPermissions(newPermissions);
  };

  const getUserPermissionsModules = useCallback(async () => {
    const response = getPermissionsByUser(+id)

    const { data } = await response || { data: undefined };

    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      return;
    }
    const temp = defaultPermissions.map(item => {
      const perm = data?.data?.find((obj: IPermission) => ObjModules[`${obj.module}`] === item.module)
      if (!!perm) item = perm
      return item;
    })
    setPermissions(temp)
    console.log(temp);
  }, [id]);

  useEffect(() => {
    getUserPermissionsModules();
  }, [getUserPermissionsModules]);

  const save = async () => {
    setIsLoadingForm(true);

    const { data } = await updateManyPermissions(+id, { permissions: permissions }) || { data: undefined };

    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al actualizar los permisos");
      setIsLoadingForm(false);
      return;
    }

    messageApi.success(data?.message || "Se han actualizado los permisos exitosamente");
    setIsLoadingForm(false);
    navigate("/users");
  };

  return (
    <Form layout="vertical" onSubmitCapture={save}>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical" size="middle">
        <Card title="Permisos">
          <SpaceStyled block={true} direction="vertical" size="large">
            <Typography.Text strong>
              Seleciona los permisos por módulos
            </Typography.Text>
            <SpaceStyled direction="horizontal">
              <Row justify="start" gutter={[8, 8]}>
                <Col xs={24} md={12}>
                  <Button
                    type="primary"
                    onClick={() =>
                      setPermissions(
                        permissions.map((perm) => ({
                          ...perm,
                          isCre: true,
                          isRead: true,
                          isEdit: true,
                          isDel: true,
                        }))
                      )
                    }
                    icon={<CheckSquareOutlined />}
                  >
                    Seleccionar todos
                  </Button>
                </Col>
                <Col xs={24} md={12}>
                  <Button
                    type="primary"
                    danger
                    onClick={() =>
                      setPermissions(
                        permissions.map((perm) => ({
                          ...perm,
                          isCre: false,
                          isRead: false,
                          isEdit: false,
                          isDel: false,
                        }))
                      )
                    }
                    icon={<CloseSquareOutlined />}
                  >
                    Deseleccionar todos
                  </Button>
                </Col>
              </Row>
            </SpaceStyled>
            <Row>
              {modules.map((module, i) => (
                <Col key={module} xs={24} md={12} lg={8}>
                  <Form.Item label={module} name={module}>
                    {module === "Configuración" ? (
                      <Row>
                        <Checkbox
                          checked={permissions[i]?.isEdit}
                          onChange={(e) =>
                            handleChangePermission(i, {
                              ...permissions[i],
                              isEdit: e.target.checked,
                              isRead: e.target.checked,
                            })
                          }
                        >
                          Editar
                        </Checkbox>
                      </Row>
                    ) : (
                      <>
                        {" "}
                        <Row>
                          <Checkbox
                            onChange={(e) =>
                              handleChangePermission(i, {
                                ...permissions[i],
                                isCre: e.target.checked,
                                isRead: e.target.checked,
                                isEdit: e.target.checked,
                                isDel: e.target.checked,
                              })
                            }
                          >
                            Todos
                          </Checkbox>
                        </Row>
                        <Row>
                          <Checkbox
                            checked={permissions[i]?.isRead}
                            onChange={(e) =>
                              handleChangePermission(i, {
                                ...permissions[i],
                                isRead: e.target.checked,
                                // remove all permissions if isRead is false
                                isCre: !e.target.checked
                                  ? false
                                  : permissions[i].isCre,
                                isEdit: !e.target.checked
                                  ? false
                                  : permissions[i].isEdit,
                                isDel: !e.target.checked
                                  ? false
                                  : permissions[i].isDel,
                              })
                            }
                          >
                            Leer
                          </Checkbox>
                        </Row>
                        <Row>
                          <Checkbox
                            checked={permissions[i]?.isCre}
                            onChange={(e) =>
                              handleChangePermission(i, {
                                ...permissions[i],
                                isCre: e.target.checked,
                                isRead: !permissions[i].isRead
                                  ? e.target.checked
                                  : true,
                              })
                            }
                          >
                            Crear
                          </Checkbox>
                        </Row>
                        <Row>
                          <Checkbox
                            checked={permissions[i]?.isEdit}
                            onChange={(e) =>
                              handleChangePermission(i, {
                                ...permissions[i],
                                isEdit: e.target.checked,
                                isRead: !permissions[i].isRead
                                  ? e.target.checked
                                  : true,
                              })
                            }
                          >
                            Editar
                          </Checkbox>
                        </Row>
                        <Row>
                          <Checkbox
                            checked={permissions[i]?.isDel}
                            onChange={(e) =>
                              handleChangePermission(i, {
                                ...permissions[i],
                                isDel: e.target.checked,
                                isRead: !permissions[i].isRead
                                  ? e.target.checked
                                  : true,
                              })
                            }
                          >
                            Eliminar
                          </Checkbox>
                        </Row>
                      </>
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </SpaceStyled>
          <Row align={"middle"} justify={"end"}>
            <Form.Item style={{ marginRight: "7px", minWidth: "140px" }}>
              <Button
                type="default"
                block
                size="large"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Atrás
              </Button>
            </Form.Item>
            <Form.Item style={{ marginLeft: "7px", minWidth: "140px" }}>
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                loading={isLoadingForm}
              >
                Guardar
              </Button>
            </Form.Item>
          </Row>
        </Card>
      </SpaceStyled>
    </Form>
  );
};

export default UserPermissionsForm;
