import React, { useEffect, useRef, useState } from "react";
import socketIOClient, { Socket } from "socket.io-client";
import { SOCKET_URL } from "src/core/GLOBAL_VAR";
import { IAppContext } from "src/core/interfaces";

const connectionConfig = {
  jsonp: false,
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: Infinity,
  transports: ["websocket"],
  reconnectionDelayMax: 5000,
  timeout: 10000,
  autoConnect: true,
  forceNew: true,
};

export const AppContext = React.createContext<IAppContext>({
  selectedMenu: "",
  setSelectedMenu: (menu: string) => { },
  open: true,
  setOpen: (open: boolean) => { },
  socket: socketIOClient(SOCKET_URL, connectionConfig),
});

export const AppProvider: React.FC<{ children: any }> = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState("/dashboard");
  const [open, setOpen] = useState(true);
  const socket = useRef<Socket>(socketIOClient(SOCKET_URL, connectionConfig));

  const connect = () => {
    if (socket.current?.id) return;
    socket.current.on("connect", async () => {
      console.log("✅ Conectado:", socket.current.id);
    });
    socket.current.on("disconnect", () => {
      console.log("📴 Desconectado, reconectando...");
      connect();
    });
    socket.current.on("reconnect", () => {
      console.log("🔁 Reconectado");
    });
    socket.current.on("error", (err) => {
      console.log("⚠️ Error en la conexión:", err);
      setTimeout(connect, 2000);
    });
  }
  useEffect(() => {
    connect();
    return () => {
      if (socket.current) {
        socket.current.off("reconnect", () => console.log("Socket reconnected!"));
        socket.current.disconnect()
      }
    };
  }, [socket]);

  return (
    <AppContext.Provider
      value={{
        open,
        setOpen,
        selectedMenu,
        setSelectedMenu,
        socket: socket.current,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
