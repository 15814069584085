import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Typography, message } from "antd";
import SpaceStyled from "../../../components/styled/Space";
import { ILoginInput } from "../../../core/dtos";
import { LoginFormCard, LoginFormWrapper } from "./LoginFormStyled";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { recover } from "src/services/auth";



const RecoveryForm = () => {

    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleRecovery = async (values: ILoginInput) => {
        setIsLoading(true);
        const response: any = recover(values.email);
        const { data, status } = await response || { data: undefined, status: undefined };

        if (!status) {
            messageApi.error(data?.message || "Ha ocurrido un error");
            setIsLoading(false);
            return;
        }

        if (data?.statusCode !== 200) {
            messageApi.error(data?.message || "Ha ocurrido un error");
            setIsLoading(false);
            return;
        }

        messageApi.open({
            type: "success",
            content: data?.message,
            duration: 15
        });
        setIsLoading(false);
    }


    return (
        <>
            {contextHolder}
            <LoginFormWrapper>
                <SpaceStyled direction="vertical" size="large" align="center">
                    <LoginFormCard>
                        <Typography.Title level={3}>Recuperar contraseña</Typography.Title>
                        <SpaceStyled direction="vertical" size="middle" block={true}>
                            <Form layout="vertical" onFinish={handleRecovery}>
                                <Form.Item
                                    name="email"
                                    label="Correo electrónico"
                                    rules={[
                                        { required: true, message: "Por favor ingrese su correo electrónico" },
                                        { type: "email", message: "Por favor ingrese un correo electrónico válido" }
                                    ]}
                                    hasFeedback
                                >
                                    <Input
                                        placeholder="Correo electrónico"
                                        prefix={<UserOutlined />}
                                        autoComplete="username"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" block={true} htmlType="submit" loading={isLoading}>
                                        Enviar
                                    </Button>
                                </Form.Item>
                                {error && <Typography.Text style={{ textAlign: "center" }} onMouseEnter={() => setError(null)} type="danger">{error}</Typography.Text>}
                            </Form>
                        </SpaceStyled>
                    </LoginFormCard>
                </SpaceStyled>
            </LoginFormWrapper>
        </>

    );
};

export default RecoveryForm;
