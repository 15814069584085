import { IUser, IPermission } from "../../core/dtos";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuthState {
  user: IUser | null;
  token: string | null;
  isLogged: boolean;
  isLoading: boolean;
  permissions: IPermission[] | null;
}

const initialState: IAuthState = {
  user: null,
  token: null,
  isLogged: false,
  isLoading: false,
  permissions: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<IUser | null>) => {
      state.user = payload;
    },
    setToken: (state, { payload }: PayloadAction<string | null>) => {
      state.token = payload;
    },
    setIsLogged: (state, { payload }: PayloadAction<boolean>) => {
      state.isLogged = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setPermissions: (state, { payload }: PayloadAction<IPermission[] | null>) => {
      state.permissions = payload;
    },
    cleanAll: (state) => {
      state.user = null;
      state.token = null;
      state.isLogged = false;
      state.isLoading = false;
      state.permissions = null;
    },
  },
});

export const { setUser, setToken, setIsLogged, setIsLoading, setPermissions, cleanAll } =
  authSlice.actions;

export default authSlice.reducer;

export const authSelector = (state: { authStore: IAuthState }) =>
  state.authStore;
