import { Button, Col, List, Row, Tooltip } from "antd";
import React, { useState } from "react";
import { EyeFilled, MenuOutlined } from "@ant-design/icons";
import moment from "moment";
import box_icon from "./../../../assets/icons/box-file.png";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableItem = ({ Package, refresh, isAssign }: { Package: any, refresh: () => void, isAssign?: boolean }) => {

  const [modalIsOpen, setModalIsOpen] = useState<0 | 1 | 2>(0);
  const [hover, setHover] = useState(false);
  const {
    attributes,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: Package.id,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  return (<>
    <List.Item
      id={Package.id}
      className="transited"
      onMouseEnter={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      ref={setNodeRef} style={style} {...attributes}
    >
      <Row justify={"space-between"} align={"middle"}>
        <Row justify={"start"} align={"middle"}>
          <Col style={{ padding: "0px 20px 0px 0px" }} onMouseEnter={() => setHover(true)}>
            <MenuOutlined
              ref={setActivatorNodeRef}
              style={{ touchAction: 'none', cursor: 'move' }}
              {...listeners}
            />
          </Col>
          <Col style={{ padding: "0px 20px 0px 0px" }} onMouseEnter={() => setHover(true)}>
            <img alt="example" src={box_icon} style={{ width: "25px", filter: "saturate(0.5)" }} />
          </Col>
          <Col onMouseEnter={() => setHover(true)} >
            <div>{Package.name || "Untitled"}</div>
            <small>
              <strong style={{ marginRight: "15px" }}>Fecha:</strong><i>{moment(Package.createdAt).format("DD-MMMM-YYYY")}</i>
              <span style={{ margin: "15px 0px" }}>┊</span>
              <span>{Package.description}</span>
            </small>
            <div style={{ overflowX: "auto" }}>
              {Package?.tags?.length > 0 && Package?.tags?.map((item: any) =>
                <small className='tag'>
                  {item.name}
                </small>)}
            </div>
          </Col>
        </Row>
      </Row>
      {/* <div>
        <Tooltip className="d-xs-none" title={"Vista previa"} placement="bottom">
          <Button
            style={{ margin: "0px 5px" }}
            shape="circle"
            icon={<EyeFilled onClick={() => setModalIsOpen(2)} key="show" />}
            type="dashed"
            size="middle"
          />
        </Tooltip>
      </div> */}
    </List.Item>
  </>);
}

export default SortableItem;