import { Grid } from "antd";
import LoginForm from "../components/LoginForm";
import LoginFillerComponent from "../components/LoginFillerComponent";
import Page from "src/components/layout/Page";
import AuthLayout from "src/layouts/AuthLayout";
import { AuthWrapperColStyled } from "src/components/layout/authLayout/AuthWrapperStyled";

const Login = () => {
  const { md } = Grid.useBreakpoint();

  return (
    <Page title="Iniciar sesión | App4Riders">
      <AuthLayout>
        <AuthWrapperColStyled
          xs={{ order: 2, span: 24 }}
          md={{ order: 1, span: 12 }}
          ismobile={!md}
        >
          <LoginFillerComponent />
        </AuthWrapperColStyled>
        <AuthWrapperColStyled
          xs={{ order: 1, span: 24 }}
          md={{ order: 2, span: 12 }}
          ismobile={!md}
        >
          <LoginForm />
        </AuthWrapperColStyled>
      </AuthLayout>
    </Page>
  );
};

export default Login;
