import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, message, Table, Tooltip, Tag } from "antd";
import { getDevices } from "src/services/devices";
import { asignDevices } from "src/services/allies";
import CardToolsToggle from "src/components/CardToolsToggle";
import usePageLimit from "src/hooks/usePageLimit";
import { ColumnsType } from "antd/es/table";
import SpaceStyled from "src/components/styled/Space";
import { Link } from "react-router-dom";
import {
  CheckCircleOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import DeviceStatus from "./DeviceStatus";

export interface IModalAsignClient {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  setActiveEvent: () => void;
  client: {
    id: number;
    name: string;
  };
}

export interface IDevice {
  key: React.Key;
  id: number;
  code: string;
  status: boolean;
  checked: boolean;
}

const ModalAsignDeviceToClient = ({
  isOpen,
  toggle,
  client,
  setActiveEvent,
}: IModalAsignClient) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [search, setSearch] = useState("");
  const [currentItems, setCurrentItems] = useState<IDevice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<IDevice[]>([]);
  const [total, setTotal] = useState(0);

  const loadDevices = () => {
    setIsLoading(true);
    getDevices([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
      { key: "available", value: "true" },
    ])
      .then(({ data }) => {
        if (data.statusCode === 200) {
          setCurrentItems(data.data.map((item: IDevice) => {
            return { ...item, key: item.id }
          }));
          setTotal(data.data.length ? data.total : 0)
        }
      })
      .catch((error) => {
        messageApi.error("Ha ocurrido un error al cargar los datos");
      })
      .finally(() => setIsLoading(false))
  };

  const closeModal = () => {
    toggle(false);
    setActiveEvent();
    setCurrentItems([]);
    setSelectedRows([]);
    setIsLoading(false);
  };

  const addDevicesToClient = () => {
    setIsLoading(true);
    asignDevices(
      client.id,
      selectedRows.map((item) => {
        return { id: item.id };
      })
    )
      .then(({ data }) => {
        messageApi.success("Dispositivos agregados correctamente");
        closeModal();
      })
      .catch((error) => {
        messageApi.error("Error al agregar los dispositivos");
      });
  };

  useEffect(() => {
    if (isOpen) loadDevices();
  }, [client, isOpen, search]);

  const paginatedFiltered = useMemo(() => {
    return currentItems.slice((page - 1) * limit, page * limit)
  }, [page, limit, currentItems]);

  const tableColumns: ColumnsType<IDevice> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      width: "12%",
      sortDirections: ["descend", "ascend"],
      render: (_: any, item) => (
        <DeviceStatus initialStatus={item.status} code={item.code} />
      ),
    },
  ];

  return (
    <Modal
      title={`Asignar dispositivo a ${client?.name}`}
      open={isOpen}
      width={720}
      onCancel={() => toggle(false)}
      footer={<div></div>}
    >
      <CardToolsToggle
        placeholder="Buscar dispositivo..."
        setSearch={setSearch}
        tools={
          selectedRows.length < 1
            ? []
            : [
              {
                type: "primary",
                tooltip: "Asignar dispositivos",
                title: "Asignar dispositivos",
                icon: <PlusOutlined />,
                onClick: () => addDevicesToClient(),
              },
            ]
        }
      />
      {isOpen && (
        <Table
          columns={tableColumns}
          dataSource={paginatedFiltered}
          loading={isLoading}
          rowSelection={{
            onChange: (
              selectedRowKeys: React.Key[],
              selectedRows: IDevice[]
            ) => {
              setSelectedRows(selectedRows);
            },
          }}
          rowClassName="bg-white"
          pagination={{
            defaultCurrent: 1,
            current: page,
            pageSize: limit,
            defaultPageSize: 10,
            onChange(page, pageSize) {
              handlePageChange(page);
              handleLimitChange(pageSize);
            },
            showSizeChanger: true,
            total: total,
          }}
        />
      )}
    </Modal>
  );
};

export default ModalAsignDeviceToClient;
