import { Card } from "antd";
import styled from "styled-components";

const CardStyled = styled(Card)`
  border: 1px solid rgba(91, 105, 135, 0.22);
  overflow: hidden;
  border-radius: 24px;
`;

export default CardStyled;
