import { useState, FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, message, Form } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IPackageInput } from "src/core/dtos";
import { PackagesCreate } from "src/services/campaign";
import Head from "src/components/Head";
import PackageForm from "../components/PackageForm";

const CreatePackage: FC = () => {
  //
  const navigate = useNavigate();
  const valuesForm: IPackageInput = {
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    numPasses: 0,
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");
  const [form] = Form.useForm();

  const onFinish = async (values: IPackageInput) => {
    setIsLoading(true);
    const { data, statusCode, message } = (await PackagesCreate(values)
      .then(({ data }) => data)
      .catch((err) => err)) || {
      data: undefined,
      statusCode: undefined,
      message: "",
    };
    const Message = message?.code ? false : message;
    if (statusCode != 200) {
      messageApi.error(Message || "Ha ocurrido un error al crear la campaña");
      setIsLoading(false);
      return;
    }

    navigate("/campaign");
    setIsLoading(false);
  };

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head
                bread={[
                  { title: "Campañas", path: "/campaign" },
                  { title: "Nuevo" },
                ]}
              >
                Registrar campaña
              </Head>
              <PackageForm
                onFinish={onFinish}
                values={valuesForm}
                isLoading={isLoading}
                error={error}
                form={form}
              />
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default CreatePackage;
