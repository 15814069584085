import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import useAuth from "src/hooks/useAuth";
import { getAlly, updateQR } from "src/services/allies";
import SpaceStyled from "src/components/styled/Space";
import ImageProfile from "../../../components/ImageProfile";
import { IAlly } from "src/core/dtos";

const ProfileCard = () => {
  const { user } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState<IAlly>();

  const loadAlly = async () => {
    const response: any = getAlly(Number(user?.allyId ?? 0));

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200 && data?.statusCode !== 404) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }
    setInitialValues({ ...data.data, email: user?.email, status: data.data?.status ? 'Activo' : 'Inactivo' });
    form?.setFieldsValue({
      email: user?.email,
      url: data.data?.url,
      floatButton: data.data?.floatButton,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    loadAlly();
  }, []);

  const onFinish = async (values: any) => {
    // setIsSubmitting(true);
    // const { data } = (await updateQR(Number(user?.allyId), {
    //   url: values.url,
    //   floatButton: initialValues.floatButton,
    // })) || { data: undefined };
    // if (data?.statusCode !== 200) {
    //   return messageApi.error(
    //     data?.message || "Ha ocurrido un error al actualizar el QR"
    //   );
    // }
    // messageApi.success(data?.message || "Se ha actualizado el QR exitosamente");
    // setIsSubmitting(false);
  };

  const PersonalInformation = () => (
    <Col xs={24}>
      <Form
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onFinish={onFinish}
      >
        {user?.allyId ? (
          <>
            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                <Form.Item label="Nombres" name="name">
                  <Input placeholder="Nombre" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                {user?.allyId && (
                  <Form.Item label="Apellidos" name="lastName" hasFeedback>
                    <Input placeholder="url" autoComplete="url" disabled />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                <Form.Item label="NIF" name="rif" hasFeedback>
                  <Input placeholder="rif" autoComplete="rif" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                <Form.Item label="Telefono" name="nroPhone">
                  <Input placeholder="Telefono" disabled />
                </Form.Item>
              </Col>

              {/*     <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                <Form.Item label="Correo electrónico" name="email">
                  <Input placeholder="Correo electrónico" disabled />
                </Form.Item>
              </Col> */}
            </Row>
            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                <Form.Item label="Tipo de empleado" name="typeEmployee">
                  <Input placeholder="Tipo de empleado" autoComplete="typeEmployee" disabled />
                </Form.Item>
              </Col>
              {initialValues?.typeEmployee === "Cuenta ajena" && (
                <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                  <Form.Item label="Empresa" name="companyName">
                    <Input placeholder="Empresa" disabled />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                <Form.Item label="Tipo de vehiculo" name="typeVehicle" >
                  <Input placeholder="Tipo de vehiculo" disabled />
                </Form.Item>
              </Col>

              <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                <Form.Item label="ID de mochila" name="idMac" hasFeedback>
                  <Input 
                    placeholder="ID de mochila" 
                    defaultValue={initialValues?.devices?.length ? initialValues.devices[0].code : 'Sin asignar'} 
                    disabled 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                <Form.Item label="Estado del rider" name="status" initialValue={initialValues?.status} >
                  <Input defaultValue={Boolean(initialValues?.status) ? 'Activo' : 'Inactivo'} placeholder="Estado" disabled />
                </Form.Item>
              </Col>

              <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
                <Form.Item label="Ciudad" name="city" hasFeedback>
                  <Input placeholder="Ciudad" autoComplete="city" disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24} lg={24} style={{ paddingRight: "15px" }}>
                <Form.Item label="Dirección" name="address">
                  <Input placeholder="Dirección" disabled />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
              <Form.Item label="Nombre" name="name" initialValue={user?.name}>
                <Input placeholder="Nombre" disabled />
              </Form.Item>
            </Col>

            <Col xs={24} md={24} lg={12} style={{ paddingRight: "15px" }}>
              <Form.Item label="Correo electrónico" name="email" initialValue={user?.email} >
                <Input placeholder="Correo electrónico" disabled />
              </Form.Item>
            </Col>
          </Row>
        )}

        {user?.allyId && (
          <>
            {/*      <Button
              type="primary"
              htmlType="submit"
              block
              size="middle"
              loading={isSubmitting}
            >
              Guardar
            </Button> */}
          </>
        )}
      </Form>
    </Col>
  );

  return (
    <>
      <Card>
        {contextHolder}
        {/* <SpaceStyled block={true} direction="horizontal" size="large"> */}
        <Spin spinning={isLoading}>
          <Row>
            {user?.allyId && (
              <Col
                xs={24}
                lg={8}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ImageProfile
                  id={Number(user.allyId)}
                  initialValue={initialValues?.logo}
                />
              </Col>
            )}
            <Col xs={24} lg={user?.allyId ? 16 : 24}>
              <PersonalInformation />
            </Col>
          </Row>
        </Spin>
        {/* </SpaceStyled> */}
      </Card>
    </>
  );
};

export default ProfileCard;
