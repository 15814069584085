import instance from "./_http";
import { IAPISourceList, IFile, getParams } from "src/core/dtos";

export const getPackages = (params?: IAPISourceList<"Packages">[]) =>
  instance
    .get(`/packages${getParams(params)}`)
    .catch(({ response }) => response);
export const getPackagesByZoneId = (
  id: number,
  params?: IAPISourceList<"Packages">[]
) =>
  instance
    .get(`/packages/zoneall/${id}${getParams(params)}`)
    .catch(({ response }) => response);
export const getPackagesAdvertiserId = (
  id: number,
  params?: IAPISourceList<"Packages">[]
) =>
  instance
    .get(`/packages/advertiser/${id}${getParams(params)}`)
    .catch(({ response }) => response);
export const getPackage = (id: number) =>
  instance.get(`/packages/${id}`).catch(({ response }) => response);
export const PackagesCreate = (data: any) =>
  instance
    .post("/packages", data, {
      headers: { "Content-Type": "application/json" },
    })
    .catch(({ response }) => response);
export const updatePackage = (id: number, data: any) =>
  instance
    .put(`/packages/${id}`, data, {
      headers: { "Content-Type": "application/json" },
    })
    .then(({ data }) => data)
    .catch(({ response }) => response);
export const updatePackageStatus = (id: number, data: any) =>
  instance
    .put(`/packages/status/${id}`, data, {
      headers: { "Content-Type": "application/json" },
    })
    .catch(({ response }) => response);
export const deletePackage = (id: number) =>
  instance.delete(`/packages/${id}`).catch(({ response }) => response);
export const getFilesScheduling = (
  id: number,
  params?: IAPISourceList<"Packages">[]
) =>
  instance
    .get(`/files/schedulingall/${id}${getParams(params)}`)
    .catch(({ response }) => response);
