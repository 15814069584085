import { S3Client } from '@aws-sdk/client-s3';

export const URL = "https://nyc3.digitaloceanspaces.com";
// export const STORAGE_NAME = "arcangelsoluciones-storage";
export const BUCKET_NAME = "ads4riders-development";
export const BUCKET_URL = "https://ads4riders-development.nyc3.digitaloceanspaces.com";
export const ACCESSKEY = "DO00CBH8EXNHB4QP9EG9";
export const SECRETKEY = "44aQlSe9jB3xY7u7HvzwalYYsvHJTKwDexRwX0Q0vmE";

const s3 = new S3Client({
  endpoint: URL,
  region: "us-east-1",
  credentials: {
    accessKeyId: ACCESSKEY,
    secretAccessKey: SECRETKEY,
  }
});

export default s3;