import { PutObjectCommand } from "@aws-sdk/client-s3";
import type { PutObjectCommandInput } from "@aws-sdk/client-s3";
import s3, { BUCKET_NAME } from "./aws-s3-config";

const uploadFile = async (path: string, file: any, parentPath = "images") => {
  const params: PutObjectCommandInput = {
    Bucket: BUCKET_NAME,
    Key: `${parentPath}/${path}`,
    Body: file,
    ACL: "public-read",
    Metadata: {
      "x-amz-meta-my-key": "public-read",
    },
  };

  try {
    const object = await s3.send(new PutObjectCommand(params));
    return true;
  } catch (err) {
    return false;
  }
};

export default uploadFile;
