import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import UserForm from "src/modules/users/components/UserForm";
import { Row, Col, Typography, message, Form, Spin } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IUserInput } from "src/core/dtos";
import { EditOutlined } from "@ant-design/icons";
import { getUser, updateUser } from "src/services/users";
import Head from "src/components/Head";

const EditUser: FC = () => {
  const { id } = useParams();
  const [values, setValues] = useState<IUserInput>({
    name: "",
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");

  const getUserEdit = () => {
    getUser(Number(id))
      .then(({ data }) => {
        if (data.data) {
          setValues(data?.data);
        }
      })
      .catch((error) => {
        messageApi.error("No se encontró el aliado");
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getUserEdit();
  }, []);

  const onFinish = ({ confirm, ...values }: IUserInput) => {
    setIsLoadingForm(true);
    updateUser(Number(id), values)
      .then(({ data }) => {
        messageApi.success(data.message);
        navigate("/users");
      })
      .catch((err) => {
        messageApi.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoadingForm(false);
      });
  };

  useEffect(() => {
    if (error)
      setTimeout(() => {
        setError("");
      }, 5000);
  }, [error]);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head bread={["Usuarios", "Editar"]}>Editar usuario</Head>
              <Spin spinning={isLoading}>
                {!isLoading && (
                  <UserForm
                    onFinish={onFinish}
                    values={values}
                    isLoading={isLoadingForm}
                    error={error}
                    form={form}
                  />
                )}
              </Spin>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default EditUser;
