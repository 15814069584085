import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, DatePicker, Row, Dropdown, Menu } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import Head from "src/components/Head";
import Card from "src/components/Card";
import CardToolsToggle from "src/components/CardToolsToggle";
import NotificationsTable from "src/modules/notifications/components/NotificationsTable";
import SpaceStyled from "src/components/styled/Space";
import { Dayjs } from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";

const { RangePicker } = DatePicker;

const NotificationsPage = () => {
    const { user } = useSelector((state: any) => state.auth);
    const { role } = useSelector((state: any) => state.auth.user);
    const [tempSearch, setTempSearch] = useState("");
    const [selectedMonth, setSelectedMonth] = useState<RangeValue<Dayjs>>(null);
    const [search, setSearch] = useState("");
    const [ShowFilter, setShowFilter] = useState(false);

    const [ShowGroup, setShowGroup] = useState(true);
    const [ShowEvent, setShowEvent] = useState(false);


    const handleGroup = () => {
        setShowGroup(true);
        setShowEvent(false);
    };

    const handleEvent = () => {
        setShowGroup(false);
        setShowEvent(true);
    };

    const handleFilter = () => {
        setShowFilter(!ShowFilter);
    };

    const handleSearch = (value: string) => {
        setSearch(value);
    };

    useEffect(() => {
        if (!tempSearch) {
            setSearch("");
        }
    }, [tempSearch]);

    const filterMenu = (
        <Menu>
            <Menu.Item key="filter-month">
                <Button style={{ marginLeft: "5px" }} onClick={handleFilter}>
                    Filtrar por mes
                </Button>
            </Menu.Item>
            <Menu.Item key="group">
                <Button style={{ marginLeft: "5px" }} onClick={handleGroup}>
                    Agrupar por aliado
                </Button>
            </Menu.Item>
            <Menu.Item key="event">
                <Button style={{ marginLeft: "5px" }} onClick={handleEvent}>
                    Agrupar por evento
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <SpaceStyled block={true} direction="vertical">
            <Row justify="center">
                <Col xs={24} md={22}>
                    <SpaceStyled block={true} direction="vertical" size="large">
                        <Head bread={["Notificaciones"]}>Gestión de notificaciones</Head>
                        <Card>
                            <Row gutter={[10, 10]} justify={{ xs: "center", sm: "center", md: "space-between" }}>
                                <Col xs={24} md={12}>
                                    <CardToolsToggle
                                        placeholder="Buscar notificaciones..."
                                        setSearch={handleSearch}
                                        tools={[]}
                                    />
                                </Col>
                                <Col xs={24} md={12} style={{ display: "flex", justifyContent: "end" }}>
                                    <Dropdown overlay={filterMenu} placement="bottomRight" trigger={["click"]}>
                                        <Button style={{ marginLeft: "5px", marginRight: "5px" }}>
                                            <FilterOutlined />
                                        </Button>
                                    </Dropdown>
                                    {ShowFilter && (
                                        <RangePicker
                                            picker="month"
                                            placeholder={["Mes de inicio", "Mes de fin"]}
                                            value={selectedMonth}
                                            onChange={(
                                                dates: RangeValue<Dayjs>,
                                                dateStrings: [string, string]
                                            ) => {
                                                setSelectedMonth(dates);
                                            }}
                                            style={{ marginBottom: "20px" }}
                                        />
                                    )}
                                </Col>
                            </Row>
                            <NotificationsTable
                                search={search}
                                id={user.id}
                                startDate={selectedMonth?.[0] || null}
                                endDate={selectedMonth?.[1] || null}
                                ShowGroup={ShowGroup}
                                ShowEvent={ShowEvent}
                            />
                        </Card>
                    </SpaceStyled>
                </Col>
            </Row>
        </SpaceStyled>
    );
};

export default NotificationsPage;