import { useState, useEffect } from "react";
import { Row, Col, Typography, message } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { TeamOutlined, AppstoreOutlined, FileImageOutlined, QrcodeOutlined, BuildOutlined, TrophyOutlined, ClockCircleOutlined, TabletOutlined } from "@ant-design/icons";
import CardStatistic from "src/modules/dashboard/components/CardStatistic";
import { countAllies } from "src/services/allies";
import { countDevices } from "src/services/devices";
import { countActivities } from "src/services/activities";
import { countInt } from "src/services/interactions";
import CardTools from "src/modules/dashboard/components/CardTools";

const CustomerDashboard = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [clients, setClients] = useState(0);
  const [devices, setDevices] = useState(0);
  const [interactions, setInteractions] = useState({ all: 0, advertising: 0, game: 0, qr: 0, finished: 0 });
  const [activities, setActivities] = useState({ total: 0, devices: 0 });
  const [rangeDate, setRangeDate] = useState<string[]>([]);

  const getAllies = async () => {
    const { data } = await countAllies() || { data: undefined };
    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      return;
    }
    setClients(data?.data);
  }

  const getDevices = async () => {
    const { data } = await countDevices() || { data: undefined };
    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      return;
    }
    setDevices(data?.data);
  }

  const getInteractions = async () => {
    const { data } = await countInt(rangeDate[0], rangeDate[1]) || { data: undefined };
    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      return;
    }
    setInteractions(data?.data);
  }
  const getActivities = async () => {
    const { data } = await countActivities(rangeDate[0], rangeDate[1]) || { data: undefined };
    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      return;
    }
    setActivities(data?.data);
  }

  useEffect(() => {
    getAllies();
    getDevices();
  }, [])

  useEffect(() => {
    getInteractions();
    getActivities();
  }, [rangeDate])

  return (
    <SpaceStyled block={true} direction="vertical">
      {contextHolder}
      <Row justify="center">
        <Col xs={24} md={22}>
          <SpaceStyled block={true} direction="vertical" size="large">
            <Typography.Title level={2} style={{ margin: "0px" }}>Panel de control</Typography.Title>
            <CardTools title="Información del sistema" />
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <CardStatistic
                  title="Riders registrados"
                  value={clients}
                  icon={<TeamOutlined />}
                  tooltip="Cantidad de aliados registrados"
                />
              </Col>
              <Col xs={24} md={12}>
                <CardStatistic
                  title="Dispositivos registrados"
                  value={devices}
                  icon={<AppstoreOutlined />}
                  tooltip="Cantidad de dispositivos registrados en la plataforma"
                />
              </Col>
            </Row>
            <CardTools title="Interacciones" text={"Cantidad de interacciones registradas: " + interactions.all} filter setRangeDate={setRangeDate} />
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <CardStatistic
                  title="Cantidad de horas de actividad"
                  value={activities.total}
                  icon={<ClockCircleOutlined />}
                  tooltip="Cantidad total de horas de actividad"
                  precision={2}
                />
              </Col>
              <Col xs={24} lg={12}>
                <CardStatistic
                  title="Dispositivos activos"
                  value={activities.devices}
                  icon={<TabletOutlined />}
                  tooltip="Cantidad de dispositivos que estuvieron activos"
                />
              </Col>
            </Row>
            {/* <Row gutter={[16,16]}>
              <Col xs={24} md={12} lg={6}>
                <CardStatistic 
                  title="Publicidades"
                  value={interactions.advertising}
                  icon={<FileImageOutlined />}
                  tooltip="Cantidad de interacciones por publicidades"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <CardStatistic 
                  title="Uso de QR"
                  value={interactions.qr}
                  icon={<QrcodeOutlined />}
                  tooltip="Cantidad de interacciones por uso de QR"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <CardStatistic 
                  title="Juegos"
                  value={interactions.game}
                  icon={<BuildOutlined />}
                  tooltip="Cantidad de interacciones por uso de juegos"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <CardStatistic 
                  title="Juegos culminados"
                  value={interactions.finished}
                  icon={<TrophyOutlined />}
                  tooltip="Cantidad de interacciones por juegos culminados"
                />
              </Col>
            </Row> */}
          </SpaceStyled>
        </Col>
      </Row>
    </SpaceStyled>
  );
}

export default CustomerDashboard;