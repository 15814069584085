import { Row, Col, Card, Statistic, Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import CountUp from "react-countup";
import type { FormatConfig, Formatter, valueType } from "antd/es/statistic/utils";
interface ICardStatistic {
  title: string;
  value: number;
  icon: any;
  tooltip: string;
  precision?: number;
};

const formatter: Formatter | undefined = (value: valueType, config?: FormatConfig) => <CountUp end={+value} duration={2} decimals={config?.precision}/>

const CardStatistic = ({ title, value, icon, tooltip, precision }: ICardStatistic) => {
  return (
    <Card style={{ minHeight: '150px' }}>
      <Row justify="space-between">
        <Col>
          <Statistic
            title={title}
            value={value}
            valueStyle={{ color: "#e97536" }}
            prefix={icon}
            formatter={() => formatter(value, { precision })}
          />
        </Col>
        <Col>
          <Tooltip
            placement="left"
            title={tooltip}
          >
            <InfoCircleTwoTone />
          </Tooltip>
        </Col>
      </Row>
    </Card>
  );
};
export default CardStatistic;
