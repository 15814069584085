import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Card, Row, Col, Alert } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IUserFormProps } from "src/core/interfaces";

const UserForm: FC<IUserFormProps> = ({
  onFinish,
  values,
  isLoading,
  error,
  form,
}) => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState(values);

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <SpaceStyled block={true} direction="vertical" size="middle">
        <Card>
          {error && <Alert type="error" message={error} showIcon />}
          <Row justify="start">
            <Col md={24} lg={14}>
              <Form.Item
                label="Nombre"
                name="name"
                initialValue={initialValues.name}
                rules={[
                  { required: true, message: "El nombre es requerido" },
                ]}
                hasFeedback
              >
                <Input placeholder="Ingrese el nombre" size="large" />
              </Form.Item>

              <Form.Item
                label="Correo electrónico"
                name="email"
                initialValue={initialValues.email}
                rules={[
                  {
                    type: "email",
                    message: "No es un correo válido",
                  },
                  {
                    required: true,
                    message: "El correo electrónico es requerido",
                  },
                ]}
                hasFeedback
              >
                <Input
                  placeholder="Ingrese el correo electrónico"
                  size="large"
                  autoComplete="email"
                />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                rules={[
                  { required: values.email ? false : true, message: "La contraseña es requerida" },
                  {
                    min: 8,
                    message: "La contraseña debe tener al menos 8 caracteres",
                  },
                  {
                    max: 20,
                    message: "La contraseña debe tener máximo 20 caracteres",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  placeholder="Ingresar contraseña"
                  size="large"
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirmar contraseña"
                dependencies={['password']}
                hasFeedback
                rules={[
                  { required: values.email ? false : true, message: "Por favor confirme su contraseña" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Las contraseñas no coinciden")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirmar contraseña"
                  size="large"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row align={"middle"} justify={"end"}>
            <Form.Item style={{ marginRight: "7px", minWidth: "140px" }} >
              <Button
                type="default"
                block
                size="large"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Atrás
              </Button>
            </Form.Item>
            <Form.Item style={{ marginLeft: "7px", minWidth: "140px" }}>
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                loading={isLoading}
              >
                Guardar
              </Button>
            </Form.Item>
          </Row>
        </Card>
      </SpaceStyled>
    </Form>
  );
};
export default UserForm;