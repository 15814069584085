import { Button, Col, List, Row, Tooltip, Checkbox } from "antd";
import { useState } from "react";
import { EyeFilled } from "@ant-design/icons";
import { IZone } from "src/core/dtos";
import ModalInfoFileSelect from "./ModalInfoFileSelect";

const ListZonesPackageSelect = ({
  file,
  isAssign,
}: {
  file: IZone;
  refresh: (first?: boolean) => void;
  isAssign?: boolean;
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<0 | 1 | 2>(0);
  const [hover, setHover] = useState(false);

  return (
    <>
      <List.Item
        className="transited"
        onMouseEnter={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          background: hover ? "#f9f9f9" : "#fff",
        }}
      >
        <Row justify={"space-between"} align={"middle"}>
          <Row justify={"start"} align={"middle"}>
            {isAssign && (
              <Col
                style={{ padding: "0px 20px 0px 0px" }}
                onMouseEnter={() => setHover(true)}
              >
                <Checkbox value={file.id} />
              </Col>
            )}

            <Col onMouseEnter={() => setHover(true)}>
              <div>{file.name || "Untitled"}</div>
              <small>
                <strong style={{ paddingRight: "5px" }}>Descripción: </strong>
                <i>{file.description}</i>
              </small>
            </Col>
          </Row>
        </Row>
        <div>
          <Tooltip className="d-xs-none" title={"Ver zona"} placement="bottom">
            <Button
              style={{ margin: "0px 5px" }}
              shape="circle"
              icon={<EyeFilled onClick={() => setModalIsOpen(2)} key="show" />}
              type="dashed"
              size="middle"
            />
          </Tooltip>
        </div>
      </List.Item>
      {modalIsOpen != 0 && (
        <ModalInfoFileSelect
          file={file}
          isOpen={modalIsOpen}
          toggle={setModalIsOpen}
        />
      )}
    </>
  );
};

export default ListZonesPackageSelect;
