import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import esES from "antd/locale/es_ES";
import { ConfigProvider } from "antd";
import './theme.less';
import "./assets/styles.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider direction="ltr" locale={esES}>
        <App />
      </ConfigProvider>
    </PersistGate>
  </Provider>
);
reportWebVitals();
