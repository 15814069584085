import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Card, Row, Col, InputNumber, Divider, Typography } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { LockOutlined } from "@ant-design/icons";
import { ISettingFormProps } from "src/core/interfaces";

const SettingForm: FC<ISettingFormProps> = ({ onFinish, values, isLoading, form }) => {
    
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState(values);
    const [pass, setPass] = useState("");

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row justify="center">
                    <Col xs={24} md={22}>
                        <SpaceStyled block={true} direction="vertical" size="middle">

                            <Card>
                                <SpaceStyled block={true} direction="vertical" size="middle">
                                    <Typography.Title level={4}>
                                        Configuración del sistema
                                    </Typography.Title>

                                    <Divider />

                                    <Form.Item
                                        name="password"
                                        label="Contraseña para desbloqueo del dispositivo"
                                        rules={[

                                            { min: 8, message: "La contraseña debe tener al menos 8 caracteres" },
                                            { max: 20, message: "La contraseña debe tener máximo 20 caracteres" },
                                        ]}
                                    >
                                        <Input.Password prefix={<LockOutlined />} placeholder="Contraseña" onChange={(e) => setPass(e.target.value)} />
                                    </Form.Item>
                                    <Form.Item
                                        name="confirmPassword"
                                        label="Confirmar contraseña"
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: (!pass ? false : true), message: "Confirme la contraseña",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Las contraseñas no coinciden'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password prefix={<LockOutlined />} placeholder="Confirmar contraseña" />
                                    </Form.Item>
                          
                                    <Form.Item
                                        name="timeBloqDev"
                                        label="Tiempo de espera para bloquear dispositivos inactivos"
                                        help="Tiempo indicado en horas"
                                        initialValue={initialValues.timeBloqDev}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Ingrese el tiempo de espera para bloquear dispositivos",
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            min={1}
                                            type="number"
                                            style={{ width: "100%" }}
                                            placeholder="Tiempo de espera para bloqueo"
                                        />
                                    </Form.Item>
                                </SpaceStyled>
                                <Row align="middle" justify="end" style={{ marginTop: "20px" }}>
                                    <Form.Item style={{ marginRight: "7px", minWidth: "140px" }} >
                                        <Button
                                            type="default"
                                            block
                                            size="large"
                                            onClick={() => {
                                                navigate(-1);
                                            }}
                                        >
                                            Atrás
                                        </Button>
                                    </Form.Item>
                                    <Form.Item style={{ marginLeft: "7px", minWidth: "140px" }}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            block
                                            size="large"
                                            loading={isLoading}
                                        >
                                            Guardar
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </Card>
                        </SpaceStyled>
                    </Col>
                </Row>

            </Form>
        </>
    );
};

export default SettingForm;

