import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import UserPermissionsForm from "src/modules/users/components/UserPermissionsForm";
import { Row, Col, message, Form, Spin } from "antd";
import SpaceStyled from "src/components/styled/Space";
import Head from "src/components/Head";

const PermissionsUser: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head bread={["Usuarios", "Permisos"]}>Permisos del usuario</Head>
              <Spin spinning={isLoading}>
                {!isLoading && (
                  <UserPermissionsForm id={Number(id)} />
                )}
              </Spin>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default PermissionsUser;
