import { useState, useEffect, useRef, useContext } from "react";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EyeFilled,
  LinkOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Card,
  Divider,
  Dropdown,
  message,
  Popconfirm,
  Space,
  Spin,
  Tooltip,
} from "antd";
import type { MenuProps } from "antd";
import ModalInfoFile from "./ModalInfoFile";
import moment from "moment";
import { deleteFile } from "src/services/files";
import ModalEditFile from "./ModalEditFile";
import { copyToClipboard, handleDownload } from "src/components/utils";
import { useSelector } from "react-redux";
import { IPermission } from "src/core/dtos";
import { AppContext } from "src/context/AppProvider";
import usePageLimit from "src/hooks/usePageLimit";

interface IFile {
  id: number;
  name: string;
  description: string;
  url: string;
  type: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  advertiserId: number;
  advertiser: { id: number; name: string };
  tags: { id: number; name: string }[];
  delay: number;
  size: number;
}

const CardFiles = ({
  file,
  refresh,
}: {
  file: IFile;
  refresh: (first?: boolean) => void;
}) => {
  const { socket } = useContext(AppContext);
  const { permissions } = useSelector((state: any) => state.auth);
  const permFile = permissions.find(
    (item: IPermission) => item.module === "Files"
  );
  const [modalIsOpen, setModalIsOpen] = useState<0 | 1 | 2>(0);
  const [modalEditFileIsOpen, setModalEditFileIsOpen] =
    useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { handlePageChange } = usePageLimit();

  const onConfirmDelete = async (id: number) => {
    deleteFile(id)
      .then(() => {
        messageApi.success("Archivo eliminado correctamente");
        handlePageChange(1);
        refresh(true);
      })
      .catch((error) => {
        console.error(error);
        messageApi.error("Error al eliminar el archivo");
      });
  };

  const CustomFile = ({ type, url, name }: IFile) => {
    const [loaded, setLoaded] = useState<string>();
    const loadfile = async () => {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          setLoaded(URL.createObjectURL(blob));
        })
        .catch((err) => console.error(err));
    };
    useEffect(() => {
      loadfile();
    }, [url]);

    if (type.includes("image")) {
      const image = useRef<HTMLImageElement>(null);
      return (
        <>
          <div
            style={{
              borderRadius: "7px 7px 0px 0px",
              display: loaded ? "none" : "flex",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "122px",
              maxHeight: "122px",
            }}
          >
            <Spin />
          </div>
          <Tooltip title={name || "Untitled"} placement="top">
            <div
              style={{
                borderRadius: "7px 7px 0px 0px",
                display: loaded ? "flex" : "none",
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "122px",
                maxHeight: "122px",
              }}
            >
              <img
                src={loaded}
                ref={image}
                alt="example"
                className="transited"
                style={{
                  width: "100%",
                  filter: "blur(1px) saturate(0.6) brightness(0.8)",
                }}
                onMouseEnter={() => {
                  if (image.current) {
                    image.current.style.filter = "blur(0px) saturate(1)";
                  }
                }}
                onMouseOut={() => {
                  if (image.current) {
                    image.current.style.filter =
                      "blur(1px) saturate(0.6) brightness(0.8)";
                  }
                }}
              />
            </div>
            <Divider style={{ margin: "0px" }} />
          </Tooltip>
        </>
      );
    }

    if (type.includes("video")) {
      const video = useRef<HTMLVideoElement>(null);

      return (
        <>
          <div
            style={{
              borderRadius: "7px 7px 0px 0px",
              display: loaded ? "none" : "flex",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "122px",
              maxHeight: "122px",
            }}
          >
            <Spin />
          </div>
          <Tooltip title={name || "Untitled"} placement="top">
            <div
              style={{
                borderRadius: "7px 7px 0px 0px",
                display: loaded ? "flex" : "none",
                overflow: "hidden",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "122px",
                maxHeight: "122px",
              }}
            >
              <video
                loop
                src={loaded}
                ref={video}
                className="transited"
                style={{
                  width: "100%",
                  filter: "blur(1px) saturate(0.6) brightness(0.8)",
                }}
                onClick={() => {
                  if (video.current) {
                    var isPlaying =
                      video.current.currentTime > 0 &&
                      !video.current.paused &&
                      !video.current.ended &&
                      video.current.readyState >
                        video.current.HAVE_CURRENT_DATA;
                    if (isPlaying) return;
                    video.current.volume = 0.05;
                    video.current.play();
                  }
                }}
                onMouseEnter={() => {
                  if (video.current) {
                    video.current.style.filter =
                      "blur(0px) saturate(1) brightness(1)";
                  }
                }}
                onMouseOut={() => {
                  if (video.current) {
                    var isPlaying =
                      video.current.currentTime > 0 &&
                      !video.current.paused &&
                      !video.current.ended &&
                      video.current.readyState >
                        video.current.HAVE_CURRENT_DATA;
                    if (!isPlaying) return;
                    video.current.pause();
                    video.current.style.filter =
                      "blur(1px) saturate(0.6) brightness(0.8)";
                  }
                }}
              />
            </div>
            <Divider style={{ margin: "0px" }} />
          </Tooltip>
        </>
      );
    }
    return <></>;
  };

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Ver detalles
        </a>
      ),
      icon: <EyeFilled />,
      disabled: false,
      onClick: () => {
        setModalIsOpen(1);
      },
    },
    {
      key: 5,
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Editar archivo
        </a>
      ),
      icon: <EditOutlined />,
      disabled: false,
      onClick: () => {
        setModalEditFileIsOpen(true);
      },
    },
    {
      key: 2,
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Obtener vinculo
        </a>
      ),
      icon: <LinkOutlined />,
      disabled: false,
      onClick: () => {
        copyToClipboard(file.url);
        messageApi.success(`Copiado al portapapeles!`);
      },
    },
    {
      key: 3,
      label: (
        <a
          href="#"
          target={"_blank"}
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Descargar
        </a>
      ),
      icon: <CloudDownloadOutlined />,
      disabled: false,
      onClick: () =>
        handleDownload(
          file.url,
          file.url.split("/")[file.url.split("/").length - 1]
        ),
    },
    { key: 6, type: "divider" },
    {
      key: 4,
      label: (
        <Popconfirm
          title="¿Seguro quieres eliminar este archivo?"
          okButtonProps={{ danger: true }}
          onConfirm={() => onConfirmDelete(file.id)}
          okText="Si"
          cancelText="No"
        >
          <a
            href="#"
            style={{ color: "#000000" }}
            onClick={(e) => {
              e.preventDefault();
            }}
            rel="noopener noreferrer"
          >
            Eliminar
          </a>
        </Popconfirm>
      ),
      icon: <DeleteOutlined />,
      disabled: false,
    },
  ];

  return (
    <>
      {contextHolder}
      <Card
        className="transited"
        style={{
          margin: "10px",
          cursor: "pointer",
        }}
        headStyle={{ textAlign: "center" }}
        cover={<CustomFile {...file} />}
        bodyStyle={{
          padding: "10px",
          background: String(file.name).toUpperCase().includes("JUEGO")
            ? "#fffdf9"
            : "#f9fffd",
        }}
        actions={[
          permFile.isDel ? (
            <Tooltip title={"Eliminar contenido"} placement="bottom">
              <Popconfirm
                title="¿Seguro quieres eliminar este archivo?"
                okButtonProps={{ danger: true }}
                onConfirm={() => onConfirmDelete(file.id)}
              >
                <DeleteOutlined key="remove" />
              </Popconfirm>
            </Tooltip>
          ) : (
            <Tooltip title={"Descargar"} placement="left">
              <CloudDownloadOutlined
                key="download"
                onClick={() =>
                  handleDownload(
                    file.url,
                    file.url.split("/")[file.url.split("/").length - 1]
                  )
                }
              />
            </Tooltip>
          ),
          <Tooltip title={"Vista previa"} placement="bottom">
            <EyeFilled onClick={() => setModalIsOpen(2)} key="show" />
          </Tooltip>,
          <Dropdown
            trigger={["click"]}
            placement="topRight"
            menu={{
              items: items.filter((item) => {
                if (!permFile.isEdit && item?.key === 5) return false;
                if (!permFile.isDel && (item?.key === 4 || item?.key === 6))
                  return false;
                return true;
              }),
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <Tooltip title={"Opciones"} placement="right">
                  <EllipsisOutlined key="ellipsis" />
                </Tooltip>
              </Space>
            </a>
          </Dropdown>,
        ]}
      >
        <span>
          <small style={{ margin: "1px 0px" }}>
            <strong>Nombre: </strong>
            <i>{file.name}</i>
          </small>
          <br />
          <small style={{ margin: "1px 0px" }}>
            <strong>Tamaño: </strong>
            <i>{file.size} MB</i>
          </small>
          <br />
          <small style={{ margin: "1px 0px" }}>
            <strong>Fecha: </strong>
            <i>{moment(file.createdAt).format("DD-MM-YYYY")}</i>
          </small>
          <br />
          <small style={{ margin: "1px 0px" }}>
            <strong>Tipo: </strong>
            <i
              style={{
                color: String(file.name).toUpperCase().includes("JUEGO")
                  ? "#d48900"
                  : "#00cb81",
              }}
            >
              {String(file.name).toUpperCase().includes("JUEGO")
                ? "Juego"
                : "Archivo"}
            </i>
          </small>
          <br />
          <div style={{ maxHeight: "80px", overflowY: "auto" }}>
            <small
              style={{ margin: "1px 0px", display: "flex", flexWrap: "wrap" }}
            >
              {file?.tags?.length > 0 &&
                file?.tags?.map((item) => (
                  <small
                    className="tag"
                    onClick={() =>
                      navigator.clipboard
                        .writeText(String(item.name))
                        .then(() =>
                          messageApi.success("Tag copiado al portapapeles")
                        )
                        .catch((err) =>
                          messageApi.success(
                            "Error al copiar al portapapeles:",
                            err
                          )
                        )
                    }
                  >
                    {item.name}
                  </small>
                ))}
            </small>
          </div>
        </span>
      </Card>
      {modalIsOpen != 0 && (
        <ModalInfoFile
          file={file}
          isOpen={modalIsOpen}
          toggle={setModalIsOpen}
        />
      )}
      {modalEditFileIsOpen && (
        <ModalEditFile
          file={file}
          isOpen={modalEditFileIsOpen}
          toggle={setModalEditFileIsOpen}
          setActiveEvent={refresh}
        />
      )}
    </>
  );
};

export default CardFiles;
