import instance from "./_http";
import { IAPISourceList, IFile, getParams } from "src/core/dtos";

export const getAllies = (params?: IAPISourceList<"Allies">[]) => instance.get(`/allies${getParams(params)}`).catch(({ response })=>response);
export const getAlly = (id: number) => instance.get(`/allies/${id}`).catch(({ response })=>response);
export const createAlly = (data: any) => instance.post("/allies", data).catch(({ response })=>response);
export const updateAlly = (id: number, data: any) => instance.put(`/allies/${id}`, data, { headers: { "Content-Type": "application/json" } } ).catch(({ response })=>response);
export const deleteAlly = (id: number) => instance.delete(`/allies/${id}`).catch(({ response })=>response);
export const updateSolvency = (id: number, isSolvent: boolean) => instance.put(`/allies/solvency/${id}`, { isSolvent }).catch(({ response })=>response);
export const countAllies = () => instance.get(`/allies/count/all`).catch(({ response })=>response);
export const updateQR = (id: number, data: { url: string, floatButton: boolean }) => instance.put(`/allies/qr/${id}`, data, { headers: { "Content-Type": "application/json" }}).catch(({ response })=>response);

//******* DEVICES *******
export const asignDevices = (id: number, data: { id: number }[]) => 
  instance.put(`/allies/${id}`,{ devices: data },{ headers: { "Content-Type": "application/json" }})
  .catch(({ response })=>response);

//****** FILES *******
export const updateFile = (allyId: number, file: IFile) => instance.post('/files', { file, allyId }).catch(({ response })=>response);