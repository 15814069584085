import { Grid } from "antd";
import Page from "src/components/layout/Page";
import Verification from "../components/Verification";

const ActiveUser = () => {
  const { md } = Grid.useBreakpoint();

  return (
    <Page title="Confirmacion de correo | App4Riders">
      <Verification />
    </Page>
  );
};

export default ActiveUser;