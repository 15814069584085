import { FC, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Typography, message, Form, Spin } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IZoneInput } from "src/core/dtos";
import { updatePackage } from "src/services/campaign";
import Head from "src/components/Head";
import { AppContext } from "src/context/AppProvider";
import ZoneForm from "../components/ZoneForm";
import { getZone, updateZone } from "src/services/zones";

const EditZone: FC = () => {
  const { id } = useParams();
  const { socket } = useContext(AppContext);

  const [valuesForm, setValuesForm] = useState<IZoneInput>({
    name: "",
    description: "",
    latitude: 0,
    longitude: 0,
    range: 0,
    delay: 0,
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");

  const loadZone = async () => {
    const response: any = getZone(Number(id));

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    setValuesForm(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    loadZone();
  }, []);

  const onFinish = async (values: IZoneInput) => {
    setIsLoadingForm(true);
    const response: any = updateZone(Number(id), values);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };
    const message = data?.message?.code ? false : data.message;

    if (!status) {
      messageApi.error(message || "Ha ocurrido un error al actualizar la zona");
      setIsLoadingForm(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(message || "Ha ocurrido un error al actualizar la zona");
      setIsLoadingForm(false);
      return;
    }

    navigate("/zones");
    setIsLoadingForm(false);
  };

  useEffect(() => {
    if (error)
      setTimeout(() => {
        setError("");
      }, 5000);
  }, [error]);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head
                bread={[
                  {
                    title: "Zonas",
                    path: "/zones",
                  },
                  {
                    title: "Editar",
                  },
                ]}
              >
                Editar zona
              </Head>
              <Spin spinning={isLoading}>
                {!isLoading && (
                  <ZoneForm
                    onFinish={onFinish}
                    values={valuesForm}
                    isLoading={isLoadingForm}
                    error={error}
                    form={form}
                  />
                )}
              </Spin>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default EditZone;
