import { useState, FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, message, Form } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IZoneInput } from "src/core/dtos";
import Head from "src/components/Head";
import { AppContext } from "src/context/AppProvider";
import ZoneForm from "../components/ZoneForm";
import { ZoneCreate } from "src/services/zones";

const CreateZone: FC = () => {
  const navigate = useNavigate();
  const { socket } = useContext(AppContext);

  const valuesForm: IZoneInput = {
    name: "",
    description: "",
    latitude: 0,
    longitude: 0,
    range: 0,
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");
  const [form] = Form.useForm();

  const onFinish = async (values: IZoneInput) => {
    setIsLoading(true);
    const { data, statusCode, message } = (await ZoneCreate(values)
      .then(({ data }) => data)
      .catch((err) => err)) || {
      data: undefined,
      statusCode: undefined,
      message: "",
    };
    const Message = message?.code ? false : message;

    if (statusCode != 200) {
      messageApi.error(Message || "Ha ocurrido un error al crear la zona");
      setIsLoading(false);
      return;
    }
    navigate("/zones");
    setIsLoading(false);
  };

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head
                bread={[{ title: "Zonas", path: "/zones" }, { title: "Nuevo" }]}
              >
                Registrar zona
              </Head>
              <ZoneForm
                onFinish={onFinish}
                values={valuesForm}
                isLoading={isLoading}
                error={error}
                form={form}
              />
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default CreateZone;
