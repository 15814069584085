import { Row, Col, Typography } from "antd";
import SpaceStyled from "src/components/styled/Space";
import CardTools from "src/modules/dashboard/components/CardTools";
const AdminDashboard = () => {
  return (
    <SpaceStyled block={true} direction="vertical">
      <Row justify="center">
        <Col xs={24} md={22}>
          <SpaceStyled block={true} direction="vertical" size="large">
            <Typography.Title level={2} style={{ margin: "0px" }}>
              Bienvenido a App4Riders
            </Typography.Title>
            <CardTools title="Panel principal" />
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
              </Col>
              <Col xs={24} md={12}>
              </Col>
            </Row>
          </SpaceStyled>
        </Col>
      </Row>
    </SpaceStyled>
  );
};
export default AdminDashboard;
