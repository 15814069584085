import { Button, Form, Space, message } from "antd";
import { useState } from "react";

const SelectStatus = ({
  selected,
  setSelected,
}: {
  selected: string | false;
  setSelected: (value: string | false) => void;
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const options = [
    { value: "APPROVED", label: "Aprobado" },
    { value: "REJECTED", label: "Rechazado" },
    { value: "PENDING", label: "Pendiente" },
  ];

  return (
    <>
      <Form.Item style={{ width: "100%", margin: "0px" }}>
        <Space>
          {options.map((option) => (
            <Button
              key={option.value}
              type={selected === option.value ? "primary" : "default"}
              onClick={() => setSelected(option.value)}
              loading={loading}
              style={
                selected === option.value ? { backgroundColor: "#1890ff" } : {}
              }
            >
              {option.label}
            </Button>
          ))}
        </Space>
      </Form.Item>
      {contextHolder}
    </>
  );
};

export default SelectStatus;
