import React, { useEffect, useMemo, useState, useContext } from "react";
import { Modal, message, Table, Tag, Form, Select } from "antd";
import { getDevicesAlly } from "src/services/devices";
import { cloneDevice } from "src/services/files";
import CardToolsToggle from "src/components/CardToolsToggle";
import usePageLimit from "src/hooks/usePageLimit";
import { ColumnsType } from "antd/es/table";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { AppContext } from "src/context/AppProvider";

export interface IModalCloneDevice {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  setActiveEvent: () => void;
  client: {
    id: number;
    name: string;
  };
}

export interface IDevice {
  key: React.Key;
  id: number;
  code: string;
  socketId?: string;
  status: boolean;
  checked: boolean;
}

const ModalCloneDevice = ({
  isOpen,
  toggle,
  client,
  setActiveEvent,
}: IModalCloneDevice) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { socket } = useContext(AppContext);
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [search, setSearch] = useState("");
  const [currentItems, setCurrentItems] = useState<IDevice[]>([]);
  const [selectItems, setSelectItem] = useState<IDevice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<IDevice[]>([]);
  const [selectDevice, setSelectDevice] = useState(0);
  const [form] = Form.useForm();

  const loadDevices = async () => {
    setIsLoading(true);
    const { data } = (await getDevicesAlly(client.id, [
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
    ])) || { data: undefined };

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }
    setCurrentItems(
      data?.data?.map((item: IDevice) => {
        return { ...item, key: item.id };
      })
    );
    if (!selectItems.length)
      setSelectItem(
        data?.data?.map((item: IDevice) => {
          return { value: item.id, label: item.code };
        })
      );
    setIsLoading(false);
  };

  const closeModal = () => {
    toggle(false);
    setActiveEvent();
    setCurrentItems([]);
    setSelectedRows([]);
    setIsLoading(false);
    setSelectDevice(0);
    form.setFieldValue("device", 0);
  };

  const save = async () => {
    setIsLoading(true);
    const { data } = (await cloneDevice(selectDevice, {
      devicesId: selectedRows.map((item) => {
        return item.id;
      }),
    })) || { data: undefined };

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al clonar dispositivos"
      );
      setIsLoading(false);
      return;
    }

    messageApi.success(
      data?.message || "Se han clonado los dispositivos correctamente"
    );
    closeModal();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) loadDevices();
  }, [client, isOpen, search]);

  const paginatedFiltered = useMemo(() => {
    return currentItems.slice((page - 1) * limit, page * limit);
  }, [page, limit, currentItems]);

  const tableColumns: ColumnsType<IDevice> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      width: "12%",
      sortDirections: ["descend", "ascend"],
      render: (text) =>
        text ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Activo
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="default">
            Inactivo
          </Tag>
        ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Modal
        title={`Clonar configuración de dispositivo`}
        open={isOpen}
        confirmLoading={isLoading}
        width={720}
        onCancel={closeModal}
        onOk={save}
        okText="Guardar"
        cancelText="Cancelar"
        okButtonProps={{
          disabled: selectedRows.length && selectDevice !== 0 ? false : true,
        }}
      >
        <Form.Item
          name="device"
          label="Dispositivo a clonar"
          hasFeedback
          rules={[
            { required: true, message: "Debe seleccionar el dispositivo" },
          ]}
        >
          <Select
            placeholder="Seleccione un dispositivo"
            options={selectItems}
            onSelect={(value) => {
              setSelectDevice(value);
              setSelectedRows(selectedRows.filter((item) => item.id !== value));
            }}
          />
        </Form.Item>
        <CardToolsToggle
          placeholder="Buscar dispositivo..."
          setSearch={setSearch}
          tools={[]}
        />
        {isOpen && (
          <Table
            columns={tableColumns}
            dataSource={paginatedFiltered}
            loading={isLoading}
            rowSelection={{
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: IDevice[]
              ) => {
                setSelectedRows(selectedRows);
              },
              getCheckboxProps: (record: IDevice) => ({
                disabled: record.id === selectDevice,
              }),
            }}
            rowClassName="bg-white"
            pagination={{
              defaultCurrent: 1,
              current: page,
              pageSize: limit,
              defaultPageSize: 10,
              onChange(page, pageSize) {
                handlePageChange(page);
                handleLimitChange(pageSize);
              },
              showSizeChanger: true,
              total: currentItems.length,
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default ModalCloneDevice;
