import { useState, useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";
import UserForm from "src/modules/users/components/UserForm";
import { Row, Col, Typography, message, Form } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IUserInput } from "src/core/dtos";
import { createUser } from "src/services/users";
import Head from "src/components/Head";

const CreateUser: FC = () => {
  const values: IUserInput = {
    name: "",
    email: "",
    password: "",
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");
  const [form] = Form.useForm();

  const onFinish = async ({ confirm, ...values }: IUserInput) => {
    setIsLoading(true);
    createUser(values)
      .then(({ data }) => {
        messageApi.success(data.message);
        navigate("/users");
      })
      .catch((err) => {
        messageApi.error(err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  useEffect(() => {
    if (error)
      setTimeout(() => {
        setError("");
      }, 5000);
  }, [error]);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head bread={["Usuarios", "Nuevo"]}>Registrar nuevo usuario</Head>
              <UserForm
                onFinish={onFinish}
                values={values}
                isLoading={isLoading}
                error={error}
                form={form}
              />
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default CreateUser;
