import instance from "./_http";
import { IAPISourceList, IUserInput, getParams } from "src/core/dtos";

export const getUsers = (params?: IAPISourceList<"Users">[]) => instance.get(`/users${getParams(params)}`).catch(({ response })=>response);
export const getUser = (id: number, includes?: boolean) => instance.get(`/users/${id}${includes ? "?includes=true" : ""}`).catch(({ response })=>response);
export const createUser = (data: IUserInput) => instance.post("/users", data);
export const updateUser = (id: number, data: IUserInput) => instance.put(`/users/${id}`, data);
export const deleteUser = (id: number) => instance.delete(`/users/${id}`);

// ********** PERMISOS *******
export const getPermissionsByUser = (id: number) => instance.get(`/permissions/user/${id}`).catch(({ response })=>response);
export const updateManyPermissions = (id: number, data: any) => instance.put(`/permissions/user/${id}`, data, { headers: { "Content-Type": "application/json" } }).catch(({ response })=>response);
export const clonePermissions = (id: number, data: any) => instance.put(`/permissions/clone/${id}`, data, { headers: { "Content-Type": "application/json" } }).catch(({ response })=>response);
