import React, { useEffect, useMemo, useState } from "react";
import { Button, Table, Tooltip, message } from "antd";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { INotification } from "src/core/dtos";
import usePageLimit from "src/hooks/usePageLimit";
import {
  getNotifications,
  updateNotification,
} from "src/services/notifications";
import { Dayjs } from "dayjs";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

interface NotificationsTableProps {
  search: string;
  id: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  ShowGroup: boolean;
  ShowEvent: boolean;
}

const NotificationsTable: React.FC<NotificationsTableProps> = ({
  search,
  id,
  startDate,
  endDate,
  ShowGroup,
  ShowEvent,
}) => {
  const [filteredNotifications, setFilteredNotifications] = useState<
    INotification[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [total, setTotal] = useState(0);
  const { page, limit, handlePageChange, handleLimitChange } = usePageLimit();
  const [currentItems, setCurrentItems] = useState<INotification[]>([]);
  const [selectedNotification, setSelectedNotification] =
    useState<INotification>();
  const [unfilteredNotifications, setUnfilteredNotifications] = useState<
    INotification[]
  >([]);
  const [filteredByTitle, setFilteredByTitle] = useState<INotification[]>([]);

  const loadNotifications = async () => {
    setIsLoading(true);
    const startDateString = startDate ? startDate.format("YYYY-MM-DD") : "";
    const endDateString = endDate ? endDate.format("YYYY-MM-DD") : "";
    const response: any = await getNotifications([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
      { key: "startDate", value: startDateString },
      { key: "endDate", value: endDateString },
    ]);
    const { data, status } = response || { data: undefined, status: undefined };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }
    let filteredNotifications = data?.data;

    if (ShowGroup) {
      filteredNotifications = filteredNotifications.filter(
        (notification: INotification, index: number, self: INotification[]) =>
          self.findIndex(
            (n: INotification) => n.recipient === notification.recipient
          ) === index
      );
    }

    if (ShowEvent) {
      const uniqueTitles = new Set<string>();
      filteredNotifications = filteredNotifications.filter(
        (notification: INotification) => {
          if (!uniqueTitles.has(notification.title)) {
            uniqueTitles.add(notification.title);
            return true;
          }
          return false;
        }
      );
      setFilteredByTitle(filteredNotifications); // Actualizar el estado filteredByTitle
    }

    setNotifications(filteredNotifications);
    setUnfilteredNotifications(data.data);
    setCurrentItems([...filteredNotifications]);
    setTotal(data?.total);
    setIsLoading(false);
  };

  useEffect(() => {
    loadNotifications();
  }, [page, limit, search, startDate, endDate, ShowGroup, ShowEvent]);

  useEffect(() => {
    let itemsToShow = notifications;

    if (ShowGroup) {
      itemsToShow = itemsToShow.filter(
        (notification: INotification, index: number, self: INotification[]) =>
          self.findIndex(
            (n: INotification) => n.recipient === notification.recipient
          ) === index
      );
    }

    if (ShowEvent) {
      const uniqueTitles = new Set<string>();
      itemsToShow = itemsToShow.filter((notification: INotification) => {
        if (!uniqueTitles.has(notification.title)) {
          uniqueTitles.add(notification.title);
          return true;
        }
        return false;
      });
    }

    setCurrentItems(itemsToShow);
  }, [notifications, ShowGroup, ShowEvent]);

  const paginatedFilteredSortedData = useMemo(() => {
    return currentItems.slice((page - 1) * limit, page * limit);
  }, [currentItems, page, limit]);

  const markAsReadNotification = async (item: INotification) => {
    setIsLoading(true);

    const response: any = await updateNotification(item.id, {
      read: !item.read,
    });
    setSelectedNotification(item);
    const { data, status } = response || { data: undefined, status: undefined };

    if (!status) {
      messageApi.error(data?.message || "Ha ocurrido un error");
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error");
      setIsLoading(false);
      return;
    }

    messageApi.success(
      data?.message || "Se ha actualizado la notificación correctamente"
    );
    loadNotifications();
  };

  let columns: ColumnType<INotification>[] = [];

  if (ShowGroup) {
    columns = [
      {
        title: "Rider",
        dataIndex: "name",
        sorter: (a, b) => a.recipient.localeCompare(b.recipient),
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        sorter: (a, b) => {
          if (a.createdAt && b.createdAt) {
            return (
              moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
            );
          }
          return 0;
        },
        render: (date: string) => {
          return moment(date).format("DD/MM/YYYY HH:mm");
        },
      },
    ];
  } else if (ShowEvent) {
    columns = [
      {
        title: "Titulo",
        dataIndex: "title",
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: "Fecha",
        dataIndex: "createdAt",
        sorter: (a, b) => {
          if (a.createdAt && b.createdAt) {
            return (
              moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
            );
          }
          return 0;
        },
        render: (date: string) => {
          return moment(date).format("DD/MM/YYYY HH:mm");
        },
      },
    ];
  }

  const expandedColumns: ColumnType<INotification>[] = [
    {
      title: "Titulo",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      sorter: (a, b) => {
        if (a.createdAt && b.createdAt) {
          return moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf();
        }
        return 0;
      },
      render: (date: string) => {
        return moment(date).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      title: "Acciones",
      dataIndex: "id",
      render: (id: number, item: INotification) => (
        <Tooltip
          title={item.read ? "Marcar como no leído" : "Marcar como leído"}
        >
          <Button
            type="text"
            onClick={() => {
              setSelectedNotification(item);
              if (!item.read) {
                markAsReadNotification(item);
              }
            }}
            disabled={item.read}
            icon={
              item.read ? (
                <CheckCircleOutlined style={{ color: "green" }} />
              ) : (
                <ExclamationCircleOutlined style={{ color: "orange" }} />
              )
            }
          />
        </Tooltip>
      ),
    },
  ];
  const columnsToShow = ShowEvent || ShowGroup ? columns : columns.slice(1);

  const expandedRowRender = (record: INotification) => {
    const filteredByRecipientId = unfilteredNotifications.filter(
      (notification) => notification.recipient === record.recipient
    );

    const filteredByTitle = unfilteredNotifications.filter(
      (notification) => notification.title === record.title
    );
    if (ShowEvent) {
      return (
        <Table
          columns={[
            {
              title: "Rider",
              dataIndex: "name",
              sorter: (a, b) => a.recipient.localeCompare(b.recipient),
            },
            {
              title: "Descripción",
              dataIndex: "description",
              sorter: (a, b) => a.description.localeCompare(b.description),
            },
            {
              title: "Fecha",
              dataIndex: "createdAt",
              sorter: (a, b) => {
                if (a.createdAt && b.createdAt) {
                  return (
                    moment(b.createdAt).valueOf() -
                    moment(a.createdAt).valueOf()
                  );
                }
                return 0;
              },
              render: (date: string) => {
                return moment(date).format("DD/MM/YYYY");
              },
            },
            {
              title: "Acciones",
              dataIndex: "id",
              render: (id: number, item: INotification) => (
                <Tooltip
                  title={
                    item.read ? "Marcar como no leído" : "Marcar como leído"
                  }
                >
                  <Button
                    type="text"
                    onClick={() => {
                      setSelectedNotification(item);
                      if (!item.read) {
                        markAsReadNotification(item);
                      }
                    }}
                    disabled={item.read}
                    icon={
                      item.read ? (
                        <CheckCircleOutlined style={{ color: "green" }} />
                      ) : (
                        <ExclamationCircleOutlined
                          style={{ color: "orange" }}
                        />
                      )
                    }
                  />
                </Tooltip>
              ),
            },
          ]}
          dataSource={filteredByTitle}
          pagination={false}
          rowKey="id"
        />
      );
    } else {
      return (
        <Table
          columns={expandedColumns}
          dataSource={filteredByRecipientId}
          pagination={false}
          rowKey="id"
        />
      );
    }
  };
  const rowExpandable = () => true;

  return (
    <>
      {contextHolder}
      <Table
        columns={columnsToShow}
        dataSource={paginatedFilteredSortedData}
        expandable={{ expandedRowRender, rowExpandable }}
        rowKey="id"
        loading={isLoading}
        pagination={{
          current: page,
          pageSize: limit,
          total: total,
          onChange: handlePageChange,
          showSizeChanger: true,
          onShowSizeChange: handleLimitChange,
        }}
      />
    </>
  );
};

export default NotificationsTable;
