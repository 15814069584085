import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { camelize } from './utils';

interface IBread {
  items: {
    title: string;
    path?: string;
    Icon?: any;
  }[]|string[];
}

const Bread = ({ items = []}: IBread) => (
  <div style={{ background:"white", borderRadius:"10px", marginTop:"10px", border: "1px solid #dfdfdf", padding: "5px 0px 5px 10px" }}>
    <Breadcrumb>
      <Breadcrumb.Item href="/">
        <HomeOutlined />
      </Breadcrumb.Item>
      {items&&items.map((item, i)=>{
        if(typeof item === "string"){
            return <Breadcrumb.Item key={item}>{camelize(String(item))}</Breadcrumb.Item>;
        }
        return <Breadcrumb.Item href={item.path||undefined} key={i}>
                  {item.Icon&&<item.Icon style={{ padding: "0px 4px 0px 0px" }}/>}
                  {item.title}
               </Breadcrumb.Item>;
      })}
    </Breadcrumb>
  </div>
);

export default Bread;