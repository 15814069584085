import { FC, ReactNode, useMemo } from "react";
import { ITopicMenuProps } from "src/core/interfaces";
import MenuStyled from "../styled/MenuStyled";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const TopicMenu: FC<ITopicMenuProps> = ({
  topics,
  selectedKey,
  changeSelectedKey,
}) => {
  const navigate = useNavigate();
  const { user, permissions } = useSelector((state: any) => state.auth);

  const filteredItems = useMemo(() => {
    let filtered: {
      icon: ReactNode;
      label: string;
      key: string;
      onClick?: () => void;
    }[] = [];

    topics.forEach((topic) => {
      if (
        topic?.key === "Home" || (user.role ===
          "ADMIN" &&
          topic?.key === "Advertisers") ||
        (user.role === "ADVERTISER" && topic?.key === "Scheduling")
      ) {
        filtered.push({
          label: topic.label,
          key: topic.path,
          icon: topic.icon,
          onClick: () => {
            changeSelectedKey(topic.path);
            navigate(topic.path);
          },
        });
        return;
      }
      if (user.role === "ALLY" && topic?.key !== "Devices") return;
      if (user.role === "CUSTOMER" && topic?.key !== "Allies") return;
      if (
        !permissions.some(
          (item: any) => item.module === topic.key && item.isRead === true
        )
      )
        return;
      filtered.push({
        label: topic.label,
        key: topic.path,
        icon: topic.icon,
        onClick: () => {
          changeSelectedKey(topic.path);
          navigate(topic.path);
        },
      });
    });

    return filtered;
  }, [changeSelectedKey, navigate, topics]);

  return (
    <MenuStyled
      style={{ background: "#78ab98", color: "#341304" }}
      mode="inline"
      selectedKeys={[selectedKey]}
      items={filteredItems}
    />
  );
};
export default TopicMenu;
