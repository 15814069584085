import { IAPISourceList, getParams } from "src/core/dtos";
import instance from "./_http";

export const getDevices = (params?: IAPISourceList<"Devices">[]) => instance.get(`/devices${getParams(params)}`).catch(({ response })=>response);
export const getDevicesAlly = (id: number, params?: IAPISourceList<"Devices">[]) => instance.get(`/devices/ally/${id}${getParams(params)}`).catch(({ response })=>response);
export const getDevice = (id: number) => instance.get(`/devices/${id}`).catch(({ response })=>response);
export const createDevice = (data: any) => instance.post("/devices", data).catch(({ response })=>response);
export const updateDevice = (id: number, data: any) => instance.put(`/devices/${id}`, data, { headers: { "Content-Type": "application/json" } }).catch(({ response })=>response);
export const deleteDevice = (id: number) => instance.delete(`/devices/${id}`).catch(({ response })=>response);
export const blockDevice = (id: number) => instance.put(`/devices/block/${id}`).catch(({ response })=>response);
export const deleteAllyDevice = (id: number, data: any) => instance.put(`/devices/ally/${id}`, data).catch(({ response })=>response);
export const countDevices = () => instance.get(`/devices/count/all`).catch(({ response })=>response);