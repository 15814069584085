import React from 'react';


const Card = (props: any) => {
    return (
        <div style={{ padding: "20px", background: "white", border: "1px solid #dfdfdf", borderRadius: "7px" }}>
            {props.children}
        </div>
    );
}

export default Card;