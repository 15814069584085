import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import useAuth from "src/hooks/useAuth";
import { getAlly, updateQR } from "src/services/allies";
import SpaceStyled from "src/components/styled/Space";
import ImageProfile from "../../../components/ImageProfile";
import { IAdvertiser, IAlly } from "src/core/dtos";
import { getAdvertiser } from "src/services/advertisers";

const ProfileAdvertiserForm = () => {
  const { user } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<any>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState<IAdvertiser>();

  console.log(user);

  const loadAlly = async () => {
    const response: any = getAdvertiser(Number(user?.advertiserId ?? 0));

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200 && data?.statusCode !== 404) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }
    setInitialValues({
      ...data.data,
      email: user?.email,
      status: data.data?.status ? "Activo" : "Inactivo",
    });
    form?.setFieldsValue({
      email: user?.email,
      url: data.data?.url,
      floatButton: data.data?.floatButton,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    loadAlly();
  }, []);

  const onFinish = async (values: any) => {
    // setIsSubmitting(true);
    // const { data } = (await updateQR(Number(user?.allyId), {
    //   url: values.url,
    //   floatButton: initialValues.floatButton,
    // })) || { data: undefined };
    // if (data?.statusCode !== 200) {
    //   return messageApi.error(
    //     data?.message || "Ha ocurrido un error al actualizar el QR"
    //   );
    // }
    // messageApi.success(data?.message || "Se ha actualizado el QR exitosamente");
    // setIsSubmitting(false);
  };

  const PersonalInformation = () => (
    <>
      <Card>
        <Descriptions column={2}>
          <Descriptions.Item label="Nombres">
            {initialValues?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Apellidos">
            {initialValues?.lastName}
          </Descriptions.Item>
          <Descriptions.Item label="NIF">
            {initialValues?.nif}
          </Descriptions.Item>
          <Descriptions.Item label="Telefono">
            {initialValues?.nroPhone}
          </Descriptions.Item>
          <Descriptions.Item label="Estado del anunciante">
            {Boolean(initialValues?.status) ? "Activo" : "Inactivo"}
          </Descriptions.Item>
          <Descriptions.Item label="Ciudad">
            {initialValues?.city}
          </Descriptions.Item>
          <Descriptions.Item label="Sector">
            {initialValues?.sector}
          </Descriptions.Item>
          <Descriptions.Item label="Persona de contacto">
            {initialValues?.contactPerson}
          </Descriptions.Item>
          <Descriptions.Item label="Dirección">
            {initialValues?.address}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card style={{ marginTop: "20px" }} title="Información de las campañas">
        <Descriptions column={3}>
          <Descriptions.Item label="Total de campañas">
            {initialValues?.packageCount}
          </Descriptions.Item>
          <Descriptions.Item label="Total de pases">
            {initialValues?.totalPasses}
          </Descriptions.Item>
          <Descriptions.Item label="Total de días">
            {initialValues?.totalCampaignDays}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );

  return (
    <>
      <Card>
        {contextHolder}
        {/* <SpaceStyled block={true} direction="horizontal" size="large"> */}
        <Spin spinning={isLoading}>
          <Row>
            <Col
              xs={24}
              lg={24}
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <ImageProfile
                id={Number(user?.advertiserId)}
                initialValue={initialValues?.image}
              />
            </Col>
            <Col xs={24} lg={user?.allyId ? 16 : 24}>
              <PersonalInformation />
            </Col>
          </Row>
        </Spin>
        {/* </SpaceStyled> */}
      </Card>
    </>
  );
};

export default ProfileAdvertiserForm;
