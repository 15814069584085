import { type ReportHandler } from 'web-vitals'

const reportWebVitals = async (onPerfEntry?: ReportHandler): Promise<any> => {
  if (onPerfEntry !== null) {
    if (onPerfEntry instanceof Function) {
      await import('web-vitals')
        .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
          getCLS(onPerfEntry)
          getFID(onPerfEntry)
          getFCP(onPerfEntry)
          getLCP(onPerfEntry)
          getTTFB(onPerfEntry)
        })
        .catch((error: any) => {
          console.log(error)
        })
    }
  }
}

export default reportWebVitals
