import { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColumnsType } from "antd/es/table";
import usePageLimit from "src/hooks/usePageLimit";
import { IUser, IPermission, IAPISourceList } from "src/core/dtos";
import SpaceStyled from "src/components/styled/Space";
import { IUsersTableProps } from "src/core/interfaces";
import { getUsers, deleteUser } from "src/services/users";

const UsersTable: FC<IUsersTableProps> = ({ search, type }) => {
  const { permissions } = useSelector((state: any) => state.auth);
  const permUser = permissions.find(
    (item: IPermission) => item.module === "Users"
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [users, setUsers] = useState<IUser[]>([]);
  const [total, setTotal] = useState(0);

  const getAllUsers = async () => {
    setIsLoading(true);

    let params: IAPISourceList<"Users">[] = [
      { key: "page", value: page },
      { key: "limit", value: limit },
    ];

    search && params.push({ key: "search", value: String(search) });
    type && params.push({ key: "role", value: String(type) });

    const response: any = (await getUsers(params)) || {
      data: undefined,
      status: undefined,
    };

    const { data, status } = response;

    if (!status) {
      messageApi.error("Error al cargar los usuarios");
      setIsLoading(false);
      return;
    }
    setUsers(data.data);
    setTotal(data.total);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllUsers();
  }, [page, limit, search, type]);

  const usersTableColumns: ColumnsType<IUser> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Opciones",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_: any, user) => (
        <SpaceStyled justify="center" block={true}>
          <Tooltip title="Ver detalles">
              <Link to={`/users/${user.id}`}>
                <Button
                  shape="circle"
                  icon={<EyeOutlined />}
                  type="primary"
                  size="middle"
                  ghost
                />
              </Link>
            </Tooltip>
          {permUser?.isEdit && (
            <Tooltip title="Editar usuario">
              <Link to={`/users/${user.id}/edit`}>
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  type="primary"
                  size="middle"
                />
              </Link>
            </Tooltip>
          )}
          {user.role !== "ALLY" && permUser?.isEdit && (
            <Tooltip title="Permisos del usuario">
              <Link to={`/users/${user.id}/permissions`}>
                <Button
                  shape="circle"
                  icon={<SettingOutlined />}
                  type="primary"
                  size="middle"
                  ghost
                />
              </Link>
            </Tooltip>
          )}
          {permUser.isDel && (
            <Tooltip title="Eliminar usuario">
              <Popconfirm
                title="¿Está seguro de eliminar el usuario?"
                okButtonProps={{ danger: true }}
                onConfirm={() => onConfirmDelete(user)}
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  type="primary"
                  danger
                  size="middle"
                />
              </Popconfirm>
            </Tooltip>
          )}
        </SpaceStyled>
      ),
    },
  ];

  const onConfirmDelete = async (user: IUser) => {
    deleteUser(user.id)
      .then(({ data }) => {
        messageApi.success("Usuario eliminado correctamente");
        handlePageChange(1);
        getAllUsers();
      })
      .catch((error) => {
        messageApi.error("Error al eliminar el usuario");
      });
  };

  return (
    <>
      {contextHolder}
      <Table
        columns={usersTableColumns}
        dataSource={users}
        loading={isLoading}
        rowClassName="bg-white"
        pagination={{
          defaultCurrent: 1,
          current: page,
          pageSize: limit,
          defaultPageSize: 10,
          onChange(page, pageSize) {
            handlePageChange(page);
            handleLimitChange(pageSize);
          },
          showSizeChanger: true,
          total: total,
          pageSizeOptions: ["15", "25", "50", "100"],
        }}
        scroll={{ x: "calc(400px + 50%)" }}
      />
    </>
  );
};

export default UsersTable;
