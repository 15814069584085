import { SOLVENCY, MODULES, NotificationType } from "./enums";

export interface ILoginInput {
  email: string;
  password: string;
}

export interface ILoginResponse {
  token: string;
  user: IUser;
  password: string;
}

// ************* Interface User **********
export interface IUser {
  id: number;
  name: string;
  email: string;
  role: "ADMIN" | "ALLY";
  allyId?: number;
  advertiserId?: number;
}

export interface IUserDetail {
  id: number;
  name: string;
  email: string;
  role: "ADMIN" | "ALLY";
  allyId?: number;
  advertiserId?: number;
  ally?: IAlly;
  advertiser?: IAdvertiser;
}

export interface IUserInput {
  name: string;
  email: string;
  password: string;
  confirm?: string;
}

// *********** Interface Ally ***********
export interface IAlly {
  id: number;
  name: string;
  lastName: string;
  rif: string;
  companyName: string;
  typeEmployee: string;
  typeVehicle: string;
  idMac: string;
  city: string;
  address: string;
  nroPhone: string;
  nroPhone2: string;
  logo: string;
  status: boolean;
  dueDate: number;
  tags: [];
  solvency: SOLVENCY;
  devices?: IDevice[];
}

export interface IAllyInput {
  id?: number;
  name: string;
  lastName: string;
  rif: string;
  companyName: string;
  address: string;
  city?: string;
  typeEmployee?: string;
  typeVehicle?: string;
  idMac?: string;
  nroPhone: string;
  nroPhone2?: string;
  logo?: any;
  status?: boolean;
  dueDate?: number;
  tags?: { id: number; name: string }[];
  solvency?: SOLVENCY;
  email?: string;
  password?: string;
  confirm?: string;
}

export interface IAdvertiser {
  id: number;
  name: string;
  lastName: string;
  nif: string;
  city: string;
  address: string;
  nroPhone: string;
  nroPhone2: string;
  image?: any;
  contactPerson?: string;
  packageCount?: number;
  totalPasses?: number;
  totalCampaignDays?: number;
  sector?: string;
  status: boolean;
  tags: [];
  solvency: SOLVENCY;
  devices?: IDevice[];
}

export interface IAdvertiserInput {
  id?: number;
  name: string;
  lastName: string;
  nif: string;
  address: string;
  city?: string;
  nroPhone: string;
  nroPhone2?: string;
  image?: any;
  contactPerson?: string;
  sector?: string;
  status?: boolean;
  tags?: { id: number; name: string }[];
  solvency?: SOLVENCY;
  email?: string;
  password?: string;
  confirm?: string;
}

// ********** Interface Customer **********

export interface ICustomer {
  id: number;
  name: string;
  rif: string;
  companyName: string;
  address: string;
  nroPhone: string;
  nroPhone2: string;
  logo: string;
  tags: { id: number; name: string }[];
  status: boolean;
  dueDate: number;
  solvency: SOLVENCY;
}

export interface ICustomerInput {
  id?: number;
  name: string;
  rif: string;
  companyName: string;
  address: string;
  nroPhone: string;
  nroPhone2?: string;
  logo?: any;
  status?: boolean;
  dueDate?: number;
  tags: { id: number; name: string }[];
  solvency?: SOLVENCY;
  email?: string;
  password?: string;
}
// ********** Interface File **********

export interface IFile {
  id: number;
  name: string;
  description: string;
  url: string;
  type: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  advertiserId: number;
  advertiser: { id: number; name: string };
  tags: { id: number; name: string }[];
  delay: number;
  size: number;
}

// ********** Interface Device **********
export interface IDevice {
  id: number;
  code: string;
  ally?: IAlly | null;
  latitude?: number;
  longitude?: number;
  altitude?: number;
  enable: boolean;
  anchored: boolean;
  socketId?: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
}

// ********** Interface Setting **********
export interface ISetting {
  id?: number;
  password: string;
  /*   delayGame: number;
  timeOut: number; */
}

export interface ISettingInput {
  confirmPassword?: any;
  id?: number;
  password: string;
  timeBloqDev: number;
}

// ********** Interface Passes **********

export interface DataItem {
  id: number;
  name?: string;
  count: number;
  countTotal?: number;
  date: string;
  packageId: number;
  deviceId: number;
  fileId: number;
  file: File;
  zoneId: number;
  totalCount?: number;
  fileName?: string;
  createdAt: string;
  updatedAt: string;
}

export interface Activity {
  id: number;
  date: string;
  hours: number;
  deviceId: number;
  totalHours?: number;
  createdAt: string;
  updatedAt: string;
}

// *********** Interface Permission ***********
export interface IPermission {
  id?: number;
  module: MODULES;
  isRead: boolean;
  isCre: boolean;
  isEdit: boolean;
  isDel: boolean;
  userId?: number;
}

export interface INotification {
  id: number;
  read: boolean;
  title: string;
  description: string;
  type: NotificationType;
  recipient: string;
  createdAt?: string;
  user: IUser;
}

export interface ICustomerInput {
  id?: number;
  name: string;
  rif: string;
  companyName: string;
  address: string;
  nroPhone: string;
  nroPhone2?: string;
  logo?: any;
  status?: boolean;
  dueDate?: number;
  tags: { id: number; name: string }[];
  solvency?: SOLVENCY;
  email?: string;
  password?: string;
}

export interface IScheduling {
  id: number;
  name: string;
  type: string;
  description: string;
  date: Date;
  time: string;
  clientId: number;
  status?: boolean;
}

export interface ISchedulingInput {
  name: string;
  description: string;
  date: Date;
  time: string;
  status?: boolean;
  clientId: number | null;
}

export interface IPackageInput {
  id?: number;
  name: string;
  description: string;
  delay?: number;
  /*   filesId: number; */
  advertiserId?: number;
  filesId?: { id: number; order: number }[];
  zonesId?: number[];
  startDate: string;
  endDate: string;
  numPasses?: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface IPackageDisplay {
  id?: number;
  name: string;
  description: string;
  filesId?: {
    id: number;
    order: number;
  }[];
  createdAt?: string;
  updatedAt?: string;
}

export interface IPackage {
  id?: number;
  name: string;
  description: string;
  delay?: number;
  startDate?: string;
  endDate?: string;
  numPasses?: number;
  budget?: number;
  createdAt?: string;
  updatedAt?: string;
  advertiserId?: number;
  status?: number;
  display?: {
    id: number;
    order: number;
  }[];
  broadcast?: {
    zoneId: number;
    packageId: number;
    zone: {
      id: number;
      name: string;
      description: string;
      latitude: number;
      longitude: number;
      range: number;
    };
  }[];
  zone?: {
    id: number;
    name: string;
    description: string;
    latitude: number;
    longitude: number;
    range: number;
  };
  passes?: {
    id: number;
    count: number;
  }[];
}

export interface IZoneInput {
  id?: number;
  name: string;
  description: string;
  latitude: number;
  longitude: number;
  range: number;
  delay?: number;
  broadcasts?: { id: number; order: number }[];
  /*   filesId: number; */
  packagesId?: { id: number; order: number }[];
  createdAt?: string;
  updatedAt?: string;
}

export interface IZone {
  id?: number;
  name: string;
  description: string;
  latitude: number;
  longitude: number;
  range: number;
  delay?: number;
}

// *********** BEGIN API Interfaces ***************

export type ITopicKey =
  | "Home"
  | "Users"
  | "Allies"
  | "Customers"
  | "Devices"
  | "Files"
  | "Zones"
  | "Multimedia"
  | "Notifications"
  | "Packages"
  | "Settings"
  | "Advertiser"
  | "Passes"
  | "Activities";

type Keys = "page" | "limit" | "search" | "zone" | "status";

type KeyType<T extends ITopicKey> = T extends "Allies"
  ? Keys | "tags"
  : T extends "Advertiser"
  ? Keys | "tags"
  : T extends "Customers"
  ? Keys | "tags"
  : T extends "Devices"
  ? Keys | "available"
  : T extends "Files"
  ? Keys | "tags" | "zone" | "advertiser"
  : T extends "Passes"
  ?
      | Keys
      | "from"
      | "to"
      | "ally"
      | "file"
      | "device"
      | "advertiser"
      | "file"
      | "Package"
  : T extends "Activities"
  ? Keys | "from" | "to" | "ally" | "file" | "device" | "advertiser" | "file"
  : T extends "Notifications"
  ? Keys | "startDate" | "endDate" | "role"
  : T extends "Users"
  ? Keys | "role"
  : Keys;

export interface IAPISourceList<T extends ITopicKey> {
  key: KeyType<T>;
  value: string | number;
}

export function getParams<K extends IAPISourceList<ITopicKey>>(
  parametros: K[] | undefined
) {
  if (!parametros) return "";
  const cadenaDeConsulta = parametros
    ?.map(({ key, value }) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
  return `?${cadenaDeConsulta}`;
}

// *********** END API Interfaces ***********
