import { useState, FC } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, message, Form } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IAdvertiserInput } from "src/core/dtos";
import Head from "src/components/Head";
import uploadFile from 'src/services/aws/upload-file';
import AdvertiserForm from "../components/AdvertiserForm";
import { createAdvertiser } from "src/services/advertisers";
import { BUCKET_URL } from "src/services/aws/aws-s3-config";

const CreateAdvertisers: FC = () => {
  const navigate = useNavigate();
  const valuesForm: IAdvertiserInput = {
    name: "",
    lastName: "",
    nif: "",
    sector: "",
    contactPerson: "",
    address: "",
    city: "",
    nroPhone: "",
    nroPhone2: "",
    status: true,
    image: "",
    // tags:[],
    email: "",
    password: "",
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");
  const [form] = Form.useForm();

  const onFinish = async ({ confirm, ...values }: IAdvertiserInput) => {

    setIsLoading(true);

    let image = valuesForm.image;
    
    if (values.image) {
      const result = await uploadFile(values.image.name, values.image);
      image = `${BUCKET_URL}/images/${values.image.name}`;
    }

     
    const payload = {
      advertiser: {
        name: values.name,
        lastName: values.lastName,
        nif: values.nif,
        address: values.address,
        city: values.city,
        contactPerson: values.contactPerson,
        sector: values.sector,
/*         dueDate: Number(values.dueDate), */
        nroPhone: values.nroPhone,
        nroPhone2: values.nroPhone2,
        status: values.status,
        // tags: values.tags||[],
        image: image,
      },
      user: {
        name: values.name,
        email: values.email,
        password: values.password,
      }
    }

    const data = await createAdvertiser(payload).then(({ data })=>data).catch((err)=>err);

    if (data.statusCode!=200) {
      messageApi.error(data.message||'Ha ocurrido un error al crear un rider');
      setIsLoading(false);
      return;
    }

    navigate("/advertisers");
    setIsLoading(false);
  };

  // useEffect(() => {
  //   loads();
  //   if (error) { setTimeout(() => { setError(""); }, 5000); }
  // }, [error]);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head bread={[ "Anunciantes", "Nuevo" ]}>Registrar nuevo anunciante</Head>
              <AdvertiserForm
                onFinish={onFinish}
                values={valuesForm}
                isLoading={isLoading}
                error={error}
                form={form}
              />
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default CreateAdvertisers;
