//Production
export const BACKEND_URL = "https://backend-app-pa84m.ondigitalocean.app";
export const SOCKET_URL = "https://socket-app-pjdu6.ondigitalocean.app";

//Local
// export const BACKEND_URL = "https://9pp9nvqr-4001.use2.devtunnels.ms";
// export const SOCKET_URL = "https://9pp9nvqr-4002.use2.devtunnels.ms";

export const KEY_GOOGLE_MAPS = "AIzaSyCjEoF1voc_06d18zRY7TwZPSdce01uaBs";
export const REACT_APP_AZURE_STORAGE_SAS_TOKEN =
  "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-11-27T22:32:21Z&st=2023-11-27T14:32:21Z&sip=0.0.0.0-255.255.255.255&spr=https,http&sig=9u2L6lPCCjBTiqFHbKJkenaWYG5ucCqs%2FI5GY%2FiCLyo%3D";
export const REACT_APP_AZURE_STORAGE_RESOURCE_NAME = "app4riders";
