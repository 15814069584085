import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form, Input, Typography, message } from "antd";
import SpaceStyled from "../../../components/styled/Space";
import { ILoginResponse } from "../../../core/dtos";
import { LoginFormCard, LoginFormWrapper } from "./LoginFormStyled";
import { LockOutlined } from "@ant-design/icons";
import { updatePassword } from "src/services/auth";
const UpdatePassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const handleUpdate = async (values: ILoginResponse) => {
    setIsLoading(true);
    const response: any = updatePassword(String(token), values.password);
    const { data, status } = await response || { data: undefined, status: undefined };
    if (!status) {
      messageApi.error(data?.message || "Ha ocurrido un error");
      setIsLoading(false);
      return;
    }
    if (data?.statusCode != 200) {
      messageApi.error(data?.message || "Ha ocurrido un error");
      setIsLoading(false);
      return;
    }
    messageApi.success(data?.message);
    navigate("/auth/login");
    setIsLoading(false);
  };
  return (
    <>
      {contextHolder}
      <LoginFormWrapper>
        <SpaceStyled direction="vertical" size="large" align="center">
          <LoginFormCard>
            <Typography.Title level={3}>Nueva contraseña</Typography.Title>
            <SpaceStyled direction="vertical" size="middle" block={true}>
              <Form layout="vertical" onFinish={handleUpdate}>
                <Form.Item
                  name="password"
                  label="Contraseña"
                  rules={[
                    { required: true, message: "Ingrese la contraseña" },
                    {
                      min: 8,
                      message: "La contraseña debe tener al menos 8 caracteres",
                    },
                    {
                      max: 20,
                      message: "La contraseña debe tener máximo 20 caracteres",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Contraseña"
                  />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label="Confirmar contraseña"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Por favor confirme su contraseña",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Las contraseñas no coinciden")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Confirmar contraseña"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    block={true}
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Actualizar contraseña
                  </Button>
                </Form.Item>
                {error && (
                  <Typography.Text
                    style={{ textAlign: "center" }}
                    onMouseEnter={() => setError(null)}
                    type="danger"
                  >
                    {error}
                  </Typography.Text>
                )}
              </Form>
            </SpaceStyled>
          </LoginFormCard>
        </SpaceStyled>
      </LoginFormWrapper>
    </>
  );
};
export default UpdatePassword;
