import { FC, useContext, useEffect } from "react";
import Sider from "antd/es/layout/Sider";
import { topics } from "src/core/topics";
import TopicMenu from "./TopicMenu";
import { AppContext } from "src/context/AppProvider";
import "./../../theme.less";

export interface INavbarProps {
  collapsed?: boolean;
}

const Sidenav: FC<INavbarProps> = ({ collapsed }) => {
  const { selectedMenu, setSelectedMenu } = useContext(AppContext);
  const changeSelectedKey = (menu: string) => setSelectedMenu(menu);

  useEffect(() => {
    if (location?.pathname) {
      if (location?.pathname === "/dashboard") {
        setSelectedMenu("/dashboard");
      } else {
        const selected = topics.find(
          (topic) =>
            location?.pathname.includes(topic.path) &&
            topic.path !== "/dashboard"
        );
        if (selected) {
          setSelectedMenu(selected.path);
        }
      }
    }
  }, [location?.pathname, setSelectedMenu]);

  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedMenu}
      changeSelectedKey={changeSelectedKey}
    />
  );

  return (
    <Sider
      collapsible
      trigger={null}
      breakpoint="lg"
      collapsed={collapsed}
      style={{ background: "#78ab98" }}
    >
      <div style={{ width: "100%", padding: "10px 5px 0px 5px" }}>
        <img
          src={require("./../../assets/images/logo-banner.png")}
          style={{ width: "90%" }}
        />
      </div>
      {Menu}
    </Sider>
  );
};

export default Sidenav;
