import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Select } from "antd";
import { updateAlly } from "src/services/allies";
import { MessageInstance } from "antd/es/message/interface"

export interface IModalChangeSolvency {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  ally: any;
  refresh: () => void;
  messageApi: MessageInstance;
}

const ModalChangeSolvency = ({ isOpen, toggle, ally, refresh, messageApi }: IModalChangeSolvency) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if(ally) form?.setFieldsValue({ solvency: ally.solvency });
  }, [ally])

  const closeModal = () => {
    toggle(false);
    form?.setFieldsValue({ solvency: "" });
    setIsLoading(false);
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    updateAlly(ally?.id, values)
      .then(({ data }) => {
        if (data.statusCode !== 200) {
          return messageApi.error(`No se ha actualizado correctamente`);
        }

        messageApi.success(`Solvencia actualizada correctamente`);
        refresh();
      })
      .catch((error) => {
        messageApi.error(`No se ha actualizado correctamente`);
      })
      .finally(() => {
        setIsLoading(false);
        closeModal();
      });
  };

  return (
    <Modal
      title={`Cambiar solvencia de rider ${ally?.name}`}
      centered
      open={isOpen}
      width={500}
      onCancel={() => toggle(false)}
      footer={<></>}
    >
      <Form
        form={form}
        name="horizontal"
        layout="inline"
        onFinish={onFinish}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Form.Item label="Solvencia" name="solvency" style={{ width: "80%" }}>
            <Select
              defaultValue={ally.solvency}
              options={[
                { value: "SOLVENT", label: "Solvente" },
                { value: "INSOLVENT", label: "Insolvente" },
                { value: "PENDING", label: "Pendiente" },
              ]}
            />
          </Form.Item>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Button
            onClick={() => closeModal()}
            type="default"
            htmlType="button"
            style={{ marginRight: 15 }}
          >
            Cancelar
          </Button>
          <Form.Item shouldUpdate>
            <Button loading={isLoading} type="primary" htmlType="submit">
              {isLoading ? "Un momento" : "Guardar"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalChangeSolvency;
