import {
  Button,
  Col,
  Dropdown,
  List,
  Popconfirm,
  message,
  Row,
  Space,
  Tooltip,
  Checkbox,
} from "antd";
import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EyeFilled,
  LinkOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ModalInfoFile from "./ModalInfoFile";
import { deleteFile } from "src/services/files";
import type { MenuProps } from "antd";
import moment from "moment";
import ModalEditFile from "./ModalEditFile";
import { copyToClipboard, handleDownload } from "src/components/utils";
import image_icon from "./../../../assets/icons/image-file.png";
import video_icon from "./../../../assets/icons/video-file.png";
import { IPermission } from "src/core/dtos";
import { AppContext } from "src/context/AppProvider";

interface IFile {
  id: number;
  name: string;
  description: string;
  url: string;
  type: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  advertiserId: number;
  advertiser: { id: number; name: string };
  tags: { id: number; name: string }[];
  delay: number;
  size: number;
}

const ListFilesPackage = ({
  file,
  refresh,
  isAssign,
}: {
  file: IFile;
  refresh: (first?: boolean) => void;
  isAssign?: boolean;
}) => {
  const { socket } = useContext(AppContext);
  const { permissions } = useSelector((state: any) => state.auth);
  const permFile = permissions.find(
    (item: IPermission) => item.module === "Files"
  );
  const [modalIsOpen, setModalIsOpen] = useState<0 | 1 | 2>(0);
  const [modalEditFileIsOpen, setModalEditFileIsOpen] =
    useState<boolean>(false);
  const [hover, setHover] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onConfirmDelete = async (id: number) => {
    deleteFile(id)
      .then(() => {
        messageApi.success("Archivo eliminado correctamente");
        refresh(true);
      })
      .catch((error) => {
        console.error(error);
        messageApi.error("Error al eliminar el archivo");
      });
  };

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Ver detalles
        </a>
      ),
      icon: <EyeFilled />,
      disabled: false,
      onClick: () => {
        setModalIsOpen(1);
      },
    },
    {
      key: 5,
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Editar archivo
        </a>
      ),
      icon: <EditOutlined />,
      disabled: false,
      onClick: () => {
        setModalEditFileIsOpen(true);
      },
    },
    {
      key: 2,
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Obtener vinculo
        </a>
      ),
      icon: <LinkOutlined />,
      disabled: false,
      onClick: () => {
        copyToClipboard(file.url);
        messageApi.success(`Copiado al portapapeles!`);
      },
    },
    {
      key: 3,
      label: (
        <a
          href="#"
          target={"_blank"}
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Descargar
        </a>
      ),
      icon: <CloudDownloadOutlined />,
      disabled: false,
      onClick: () =>
        handleDownload(
          file.url,
          file.url.split("/")[file.url.split("/").length - 1]
        ),
    },
    { key: 6, type: "divider" },
    {
      key: 4,
      label: (
        <Popconfirm
          title="¿Seguro quieres eliminar este archivo?"
          okButtonProps={{ danger: true }}
          onConfirm={() => onConfirmDelete(file.id)}
        >
          <a
            href="#"
            style={{ color: "#000000" }}
            onClick={(e) => {
              e.preventDefault();
            }}
            rel="noopener noreferrer"
          >
            Eliminar
          </a>
        </Popconfirm>
      ),
      icon: <DeleteOutlined />,
      disabled: false,
    },
  ];

  return (
    <>
      {contextHolder}
      <List.Item
        className="transited"
        onMouseEnter={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          background: hover ? "#f9f9f9" : "#fff",
        }}
      >
        <Row justify={"space-between"} align={"middle"}>
          <Row justify={"start"} align={"middle"}>
            {/*          {isAssign&&
                    <Col style={{ padding: "0px 20px 0px 0px" }} onMouseEnter={() => setHover(true)}>
                        <Checkbox value={file.id}/>
                    </Col>} */}
            {/*          <Col style={{ padding: "0px 20px 0px 0px" }} onMouseEnter={() => setHover(true)}>
                        {file.type.includes("image")&&<img alt="example" src={image_icon} style={{ width: "20px", filter: "saturate(0.5)" }}/>}
                        {file.type.includes("video")&&<img alt="example" src={video_icon} style={{ width: "20px", filter: "saturate(0.5)" }} />}
                    </Col> */}
            <Col onMouseEnter={() => setHover(true)}>
              <div>{file.name || "Untitled"}</div>
              <small>
                <strong style={{ paddingRight: "5px" }}>Tamaño: </strong>
                <i>{file.size} MB</i>
                <span style={{ margin: "15px 0px" }}>┊</span>
                <strong style={{ paddingRight: "5px" }}>Fecha:</strong>
                <i>{moment(file.createdAt).format("DD-MMMM-YYYY")}</i>
              </small>
              <div style={{ overflowX: "auto" }}>
                {file?.tags?.length > 0 &&
                  file?.tags?.map((item) => (
                    <small
                      className="tag"
                      onClick={() =>
                        navigator.clipboard
                          .writeText(String(item.name))
                          .then(() =>
                            messageApi.success("Tag copiado al portapapeles")
                          )
                          .catch((err) =>
                            messageApi.success(
                              "Error al copiar al portapapeles:",
                              err
                            )
                          )
                      }
                    >
                      {item.name}
                    </small>
                  ))}
              </div>
            </Col>
          </Row>
        </Row>
        <div>
          {!isAssign && permFile.isDel && (
            <Tooltip
              className="d-xs-none"
              title={"Eliminar contenido"}
              placement="bottom"
            >
              <Popconfirm
                title="¿Seguro quieres eliminar este archivo?"
                okButtonProps={{ danger: true }}
                onConfirm={() => onConfirmDelete(file.id)}
              >
                <Button
                  style={{ margin: "0px 5px" }}
                  shape="circle"
                  icon={<DeleteOutlined key="remove" />}
                  type="dashed"
                  size="middle"
                />
              </Popconfirm>
            </Tooltip>
          )}
          <Tooltip
            className="d-xs-none"
            title={"Vista previa"}
            placement="bottom"
          >
            <Button
              style={{ margin: "0px 5px" }}
              shape="circle"
              icon={<EyeFilled onClick={() => setModalIsOpen(2)} key="show" />}
              type="dashed"
              size="middle"
            />
          </Tooltip>
          {!isAssign && (
            <Dropdown
              trigger={["click"]}
              placement="topRight"
              menu={{
                items: items.filter((item) => {
                  if (!permFile.isEdit && item?.key === 5) return false;
                  if (!permFile.isDel && (item?.key === 4 || item?.key === 6))
                    return false;
                  return true;
                }),
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <Tooltip title={"Opciones"} placement="bottom">
                    <Button
                      style={{ margin: "0px 5px" }}
                      shape="circle"
                      icon={<EllipsisOutlined key="ellipsis" />}
                      type="dashed"
                      size="middle"
                    />
                  </Tooltip>
                </Space>
              </a>
            </Dropdown>
          )}
        </div>
      </List.Item>
      {modalIsOpen != 0 && (
        <ModalInfoFile
          file={file}
          isOpen={modalIsOpen}
          toggle={setModalIsOpen}
        />
      )}
      {modalEditFileIsOpen && (
        <ModalEditFile
          file={file}
          isOpen={modalEditFileIsOpen}
          toggle={setModalEditFileIsOpen}
          setActiveEvent={refresh}
        />
      )}
    </>
  );
};

export default ListFilesPackage;
