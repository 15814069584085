import React, { useState } from "react";
import { Layout, theme, Grid } from "antd";
import { Helmet } from "react-helmet";
import Sidenav from "src/components/layout/Sidenav";
import Navbar from "src/components/layout/Sidebar";

const { Content } = Layout;

interface IApp {
  children: JSX.Element;
  title: string;
}

const AppLayout: React.FC<IApp> = ({ children, title }) => {
  const { md } = Grid.useBreakpoint();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <Helmet>
        <title>{title} | App4Riders</title>
      </Helmet>
      <Layout style={{ height: "100vh" }}>
        {md && <Sidenav collapsed={collapsed} />}
        <Layout>
          <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />
          <Content
            style={{
              overflowY: "auto",
              paddingRight: "20px",
              paddingLeft: "20px",
              marginTop: "2px",
              paddingTop: "20px",
              background: "#f3f7f6",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default AppLayout;
