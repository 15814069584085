import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AppContext } from "src/context/AppProvider";
import { Modal, Spin } from "antd";
import { getDevice } from "src/services/devices";
import { KEY_GOOGLE_MAPS } from "src/core/GLOBAL_VAR";
import CustomMarker from "src/modules/map/components/CustomMarker";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { VideoCameraOutlined } from "@ant-design/icons";

export interface IModalMapDevice {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  edit: any;
  setEdit: Dispatch<SetStateAction<any>>;
}

interface ICustomMarker {
  id: number;
  code: string;
  latitude: number;
  longitude: number;
  active: boolean;
  enable: boolean;
}
const ModalMapDevice = ({ isOpen, toggle, edit, setEdit }: IModalMapDevice) => {
  const { socket } = useContext(AppContext);
  const zoom = 16;
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState<ICustomMarker>({
    id: 0,
    code: "",
    active: false,
    enable: false,
    latitude: 0,
    longitude: 0,
  });

  const loadDevice = async () => {
    setIsLoading(true);
    const { data, status } = (await getDevice(edit)) || {
      data: {
        data: {
          id: 0,
          code: "",
          active: false,
          enable: false,
          latitude: 0,
          longitude: 0,
        },
        statusCode: 400,
      },
      status: false,
    };
    if (!status) {
      setIsLoading(false);
      return;
    }
    if (data?.statusCode != 200) {
      setIsLoading(false);
      return;
    }
    setData({
      active: data.data.status,
      code: data.data.code,
      enable: data.data.enable,
      id: data.data.id,
      latitude: Number(data.data.latitude),
      longitude: Number(data.data.longitude),
    });
    setIsLoading(false);
  }; 

  useEffect(() => {
    if (isOpen) {
      if (!edit) {
        setIsLoading(false);
        return
      }
      loadDevice();
      socket?.on(`update_map_${edit}`, (location: { latitude: number; longitude: number;}) => {
        console.log(location)
        setData({
          active: true,
          code: data.code,
          enable: true,
          id: edit,
          latitude: location.latitude,
          longitude: location.longitude,
        });
      });
      return () => {
        socket?.close();
      };
    }
  }, [isOpen, edit]);

  const closeModal = () => {
    setEdit(false);
    toggle(false);
    setIsLoading(false);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: KEY_GOOGLE_MAPS,
  });

  return (
    <Modal
      footer={null}
      width={"80%"}
      open={isOpen}
      onCancel={closeModal}
      title={
        <h3 style={{ padding: "20px 0px 0px 20px" }}>
          <VideoCameraOutlined style={{ marginRight: "15px" }} />
          Ubicación del dispositivo
        </h3>
      }
      styles={{
        content: { padding: "0px" },
      }}
    >
      {isLoading ? (
        <Spin
          style={{
            display: "flex",
            height: "69vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ) : isLoaded && data.longitude !== 0 ? (
        <div style={{ height: "70vh" }}>
          <GoogleMap
            zoom={zoom}
            center={{
              lat: data.latitude,
              lng: data.longitude,
            }}
            mapContainerStyle={{
              width: "100%",
              height: "100%",
            }}
          >
            <CustomMarker {...data} />
          </GoogleMap>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "100px",
          }}
        >
          No hay una ubicacion registrada para este dispositivo
        </div>
      )}
    </Modal>
  );
};

export default ModalMapDevice;
