import {
  HomeOutlined,
  TeamOutlined,
  AppstoreOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  SolutionOutlined,
  FolderOutlined,
  ClockCircleOutlined,
  FolderOpenOutlined,
  BellOutlined,
  BranchesOutlined,
} from "@ant-design/icons";

export interface ITopics {
  key?:
    | "Home"
    | "Notifications"
    | "Users"
    | "Allies"
    | "Customers"
    | "Devices"
    | "Files"
    | "Map"
    | "Scheduling"
    | "Zones"
    | "Settings"
    | "Advertisers"
    | "Metrics";
  label: string;
  path: string;
  icon: JSX.Element;
}

export const topics: ITopics[] = [
  {
    key: "Home",
    label: "Inicio",
    path: "/dashboard",
    icon: <HomeOutlined />,
  },
  {
    key: "Notifications",
    label: "Notificaciones",
    path: "/notifications",
    icon: <BellOutlined />,
  },
  {
    key: "Users",
    label: "Usuarios",
    path: "/users",
    icon: <TeamOutlined />,
  },
  {
    key: "Allies",
    label: "Riders",
    path: "/riders",
    icon: <SolutionOutlined />,
  },
  {
    key: "Advertisers",
    label: "Anunciantes",
    path: "/advertisers",
    icon: <SolutionOutlined />,
  },
  {
    key: "Map",
    label: "Mapa",
    path: "/map",
    icon: <BranchesOutlined />,
  },

  {
    key: "Metrics",
    label: "Metricas",
    path: "/Metrics",
    icon: <HomeOutlined />,
  },
  {
    key: "Devices",
    label: "Dispositivos",
    path: "/devices",
    icon: <AppstoreOutlined />,
  },
  {
    key: "Files",
    label: "Multimedia",
    path: "/files",
    icon: <FolderOutlined />,
  },
  {
    key: "Scheduling",
    label: "Campañas",
    path: "/campaign",
    icon: <FolderOpenOutlined />,
  },
  /*   {
    key: "Scheduling",
    label: "Zonas",
    path: "/zones",
    icon: <FolderOpenOutlined />,
  }, */
  {
    key: "Zones",
    label: "Zonas",
    path: "/zones",
    icon: <FolderOpenOutlined />,
  },
  {
    key: "Settings",
    label: "Configuración",
    path: "/settings",
    icon: <SettingOutlined />,
  },
];
