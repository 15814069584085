import { FC, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Alert,
  Checkbox,
  message,
  List,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IPackageFormProps } from "src/core/interfaces";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CardToolsToggle from "src/components/CardToolsToggle";
import {
  CalendarOutlined,
  CheckCircleFilled,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EuroCircleOutlined,
  FileTextOutlined,
  IdcardOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import ListFiles from "src/modules/multimedia/components/ListFiles";
import {
  getFiles,
  getFilesByPackageId,
  getTotalDelay,
} from "src/services/files";
import moment from "moment";
import SortableItem from "./SortableItem";
import { IAdvertiser, IPackageInput } from "src/core/dtos";
import usePageLimit from "src/hooks/usePageLimit";
import { getAdvertisers } from "src/services/advertisers";
import { useSelector } from "react-redux";
import ListFilesPackage from "src/modules/multimedia/components/ListFilesPackage";
import ListZonesPackage from "./ListZonesPackage";

dayjs.extend(customParseFormat);

const PackageOne: FC<IPackageFormProps> = ({
  onFinish,
  values,
  isLoading,
  error,
  form,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state: any) => state.auth);
  const [initialValues, setInitialValues] = useState(values);
  const [files, setFiles] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingAdvertisers, setLoadingAdvertisers] = useState(false);
  const [search, setSearch] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [tags, setTags] = useState<string[]>();
  const [checked, setChecked] = useState<CheckboxValueType[]>([]);
  const [totalDelay, setTotalDelay] = useState<number>(values?.delay || 0);
  const [orderList, setOrderList] = useState(false);
  const [advertisers, setAdvertisers] = useState<IAdvertiser[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [numPasses, setNumPasses] = useState<number | null>(
    values?.numPasses !== undefined ? values?.numPasses : 0
  );
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs(values?.startDate)
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(values?.endDate));
  const [numDays, setNumDays] = useState<number | null>(null);
  const [advertiserId, setAdvertiserId] = useState(user.advertiserId);

  useEffect(() => {
    if (numPasses !== null) {
      form.setFieldsValue({ budget: numPasses * 0.02 });
    }
  }, [numPasses]);

  useEffect(() => {
    if (startDate && endDate) {
      const diffInMs = endDate.diff(startDate, "millisecond");
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      setNumDays(Math.round(diffInDays));
    }
  }, [startDate, endDate]);
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();

  const calculateTotalDelay = (checkedValues: CheckboxValueType[]) => {
    let totalDelay = 0;
    checkedValues.forEach((value) => {
      const file = files.find((item: any) => item.id === value);
      if (file) {
        totalDelay += file.delay;
      }
    });
    return totalDelay;
  };

  // useEffect(() => {
  //   setTotalDelay(calculateTotalDelay(checked));
  // }, [checked]);

  const formattedTotalDelay = moment.utc(totalDelay * 1000).format("mm:ss");

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFiles((prev) => {
        const activeIndex = prev.findIndex((i) => i.id == active.id);
        const overIndex = prev.findIndex((i) => i.id == over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const loadAdvertisers = async () => {
    setLoadingAdvertisers(true);

    const response: any = getAdvertisers([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
    ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setLoadingAdvertisers(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setLoadingAdvertisers(false);
      return;
    }

    setAdvertisers(data?.data);

    setLoadingAdvertisers(false);
  };

  console.log(id);

  const loadFiles = async () => {
    /*     const response: any = id
      ? await getFilesByPackageId(+id, [
          { key: "page", value: page },
          { key: "limit", value: limit },
          { key: "search", value: String(search) },
          { key: "tags", value: tags?.length ? String(tags?.toString()) : "" },
        ])
      : await getFiles([
          { key: "page", value: page },
          { key: "limit", value: limit },
          { key: "search", value: String(search) },
          { key: "tags", value: tags?.length ? String(tags?.toString()) : "" },
          { key: "advertiser", value: advertiserId || "" },
        ]); */

    const response: any = id
      ? await getFilesByPackageId(+id, [
          { key: "page", value: page },
          { key: "limit", value: limit },
          { key: "search", value: String(search) },
          { key: "tags", value: tags?.length ? String(tags?.toString()) : "" },
        ])
      : await getFiles([
          { key: "page", value: page },
          { key: "limit", value: limit },
          { key: "search", value: String(search) },
          { key: "tags", value: tags?.length ? String(tags?.toString()) : "" },
          { key: "advertiser", value: advertiserId || "" },
        ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    // Ordena los archivos aquí
    let sortedData = [...data?.data];
    if (data) {
      sortedData.sort((a, b) => {
        let orderA = a.displays && a.displays[0] ? a.displays[0].order : 0;
        let orderB = b.displays && b.displays[0] ? b.displays[0].order : 0;
        return orderA - orderB;
      });
    }

    const filterCheck = sortedData
      .filter((item: any) => allSelected || item.selected)
      .map((otem: any) => otem.id);
    setFiles([...sortedData]);
    setTotal(data?.total);
    setChecked([...checked, ...filterCheck]);
  };

  const loads = async () => {
    setLoading(true);
    await loadFiles();
    await loadAdvertisers();
    setLoading(false);
  };

  useEffect(() => {
    loads();
  }, [page, search, tags, advertiserId]);

  const loadTotalDelay = async () => {
    const response: any = await getTotalDelay();

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    setTotalDelay(data?.data);
  };

  return (
    <>
      <Card>
        {error && <Alert type="error" message={error} showIcon />}
        <Card>
          <Row>
            <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
              <h2>Nombre</h2>
              <p>{initialValues.name}</p>
            </Col>
            <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
              <h2>Descripción</h2>
              <p>{initialValues.description}</p>
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
              <h2>Fecha de inicio</h2>
              <p>
                {dayjs(initialValues.startDate)
                  .add(1, "d")
                  .format("YYYY-MM-DD")}
              </p>
            </Col>
            <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
              <h2>Fecha de fin</h2>
              <p>
                {dayjs(initialValues.endDate).add(1, "d").format("YYYY-MM-DD")}
              </p>
            </Col>
          </Row>
          <Row>
            {user.role === "ADMIN" && (
              <Col xs={24} md={24} lg={8} style={{ paddingRight: "7px" }}>
                <h2>Anunciante</h2>
                <p>
                  {
                    advertisers.find(
                      (item) => item.id === initialValues.advertiserId
                    )?.name
                  }
                </p>
              </Col>
            )}
          </Row>
        </Card>
        <Card
          title="Resumen de la campaña"
          bordered={false}
          style={{ height: "100%", marginTop: "20px" }}
        >
          <Row>
            <Col xs={24} md={24} lg={24} style={{ paddingRight: "7px" }}>
              <Row>
                <Col
                  xs={24}
                  md={24}
                  lg={8}
                  style={{
                    paddingRight: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <label>Número de pases</label>
                    <h1>
                      <FileTextOutlined /> {numPasses}
                    </h1>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={24}
                  lg={8}
                  style={{
                    paddingRight: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <label>Pases reproducidos</label>
                    <h1>
                      <FileTextOutlined />{" "}
                      {initialValues?.passes
                        ? initialValues.passes.reduce(
                            (total, pass) => total + pass.count,
                            0
                          )
                        : 0}
                    </h1>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={24}
                  lg={8}
                  style={{
                    paddingRight: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <label>Días de reproducción</label>
                    <h1>
                      <CalendarOutlined /> {numDays ? numDays : 0}
                    </h1>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={24}
                  md={24}
                  lg={8}
                  style={{
                    paddingRight: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <label>Duración por pase</label>
                    <h1>
                      <ClockCircleOutlined /> 10 seg
                    </h1>
                  </div>
                </Col>

                <Col
                  xs={24}
                  md={24}
                  lg={8}
                  style={{
                    paddingRight: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <label>Monto por pase</label>
                    <h1>
                      <EuroCircleOutlined /> {0.02}
                    </h1>
                  </div>
                </Col>
                <Col
                  xs={24}
                  md={24}
                  lg={8}
                  style={{
                    paddingRight: "7px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <label>Monto de presupuesto</label>
                    <h1>
                      <EuroCircleOutlined />{" "}
                      {values?.budget !== undefined ? values?.budget.toFixed(2) : 0}
                    </h1>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>

        <Card title="Multimedia" style={{ marginTop: "20px" }}>
          <List
            loading={loading}
            dataSource={files.filter((file) => checked.includes(file.id))}
            renderItem={(item) => (
              <ListFilesPackage file={item} refresh={loads} isAssign />
            )}
          />
          {files?.length < total && (
            <Col
              style={{
                marginTop: "1em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                loading={loading}
                onClick={() => handlePageChange(page + 1)}
              >
                Cargar más
              </Button>
            </Col>
          )}
        </Card>
        <Card
          title="Zona o zonas seleccionadas"
          bordered={false}
          style={{ height: "100%", marginTop: "20px" }}
        >
          <List
            loading={loading}
            dataSource={
              initialValues.broadcast?.map((item, index) => ({
                ...item,
                key: index,
                name: item.zone.name || "",
                description: item.zone.description || "",
              })) || []
            }
            renderItem={(item, index) => (
              <ListZonesPackage file={item} refresh={loads} isAssign />
            )}
          />
        </Card>
      </Card>
    </>
  );
};
export default PackageOne;
