import { FC, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, message, Form, Spin } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IPackageInput } from "src/core/dtos";
import { getPackage, updatePackage } from "src/services/campaign";
import Head from "src/components/Head";
import { AppContext } from "src/context/AppProvider";
import PackageForm from "../components/PackageForm";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const EditPackage: FC = () => {
  const { id } = useParams();
  const { socket } = useContext(AppContext);
  const { user } = useSelector((state: any) => state.auth);
  const [valuesForm, setValuesForm] = useState<IPackageInput>({
    name: "",
    description: "",
    delay: 0,
    startDate: "",
    endDate: "",
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");

  const loadPackage = async () => {
    const response = await getPackage(Number(id));
    const { data, status } = response;

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (status !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    /*   setValuesForm(data?.data); */
    setValuesForm(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    loadPackage();
  }, []);

  const onFinish = async (values: IPackageInput) => {
    //
    setIsLoadingForm(true);

    const response = await updatePackage(Number(id), values);
    const { data, message, statusCode } = response;

    if (!statusCode) {
      messageApi.error(message || "No pudo actualizar la campaña");
      setIsLoadingForm(false);
      return;
    }

    if (statusCode === 500) {
      messageApi.error("Error de servidor 500");
      setIsLoadingForm(false);
      return;
    }

    if (statusCode !== 200) {
      messageApi.error(message || "No pudo actualizar la campaña");
      setIsLoadingForm(false);
      return;
    }
    navigate("/campaign");
    setIsLoadingForm(false);
  };

  useEffect(() => {
    if (error)
      setTimeout(() => {
        setError("");
      }, 5000);
  }, [error]);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head
                bread={[
                  {
                    title: "Campañas",
                    path: "/campaign",
                  },
                  {
                    title: "Editar",
                  },
                ]}
              >
                Editar campaña
              </Head>
              <Spin spinning={isLoading}>
                {!isLoading && (
                  <PackageForm
                    onFinish={onFinish}
                    values={valuesForm}
                    isLoading={isLoadingForm}
                    error={error}
                    form={form}
                  />
                )}
              </Spin>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default EditPackage;
