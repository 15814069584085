import { IPageProps } from "src/core/interfaces";
import { Helmet } from "react-helmet";
import { FC } from "react";

const Page: FC<IPageProps> = ({ title, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </>
  );
};

export default Page;