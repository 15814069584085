import { useState, useEffect } from "react";
import {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  TagsOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  Popconfirm,
  Table,
  Tooltip,
  Tag,
  Checkbox,
  Modal,
  Card,
  Divider,
} from "antd";
import { ColumnsType } from "antd/es/table";
import SpaceStyled from "src/components/styled/Space";
import { IAdvertiser, IPermission } from "src/core/dtos";
import { ITableProps, IUsersTableProps } from "src/core/interfaces";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FC, useMemo } from "react";
import usePageLimit from "src/hooks/usePageLimit";
import { getAllies, deleteAlly } from "src/services/allies";
import { SOLVENCY } from "src/core/enums";
import { ColumnGroupType } from "antd/lib/table";
import { ColumnType } from "antd/es/list";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { deleteAdvertiser, getAdvertisers } from "src/services/advertisers";

const AdvertisersTable: FC<ITableProps> = ({ search, tags }) => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state: any) => state.auth);
  const permAdv = permissions.find(
    (item: IPermission) => item.module === "Advertisers"
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [advertisers, setAdvertisers] = useState<IAdvertiser[]>([]);
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQR, setIsOpenQR] = useState(false);
  const [advertiser, setAdvertiser] = useState<IAdvertiser>({
    id: 0,
    name: "",
    lastName: "",
    nif: "",
    sector: "",
    contactPerson: "",
    city: "",
    address: "",
    nroPhone: "",
    nroPhone2: "",
    image: "",
    tags: [],
    status: true,
    solvency: SOLVENCY.SOLVENT,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const loadAdvertisers = async () => {
    setIsLoading(true);

    const response: any = getAdvertisers([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
      { key: "tags", value: String(tags?.toString()) },
    ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    setAdvertisers(data?.data);
    setTotal(data?.total);

    setIsLoading(false);
  };

  useEffect(() => {
    loadAdvertisers();
  }, [page, limit, search, tags]);

  const alliesTableColumns: ColumnsType<IAdvertiser> = [
    {
      title: "Nombres",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, advertiser) =>
        advertiser.tags.length ? (
          <Tooltip
            title={advertiser.tags.map((item: { name: string }) => (
              <span
                onClick={() =>
                  navigator.clipboard
                    .writeText(String(item.name))
                    .then(() =>
                      messageApi.success("Tag copiado al portapapeles")
                    )
                    .catch((err) =>
                      messageApi.success(
                        "Error al copiar al portapapeles:",
                        err
                      )
                    )
                }
                className="tag"
                style={{ color: "#000" }}
              >
                <TagsOutlined
                  style={{ fontSize: "10px", marginRight: "3px" }}
                />
                {item.name}
              </span>
            ))}
          >
            <span style={{ cursor: "pointer" }}>
              <TagsOutlined style={{ fontSize: "10px", marginRight: "3px" }} />
              {advertiser.name}
            </span>
          </Tooltip>
        ) : (
          <span style={{ cursor: "pointer" }}>{advertiser.name}</span>
        ),
    },
    {
      title: "Apellidos",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.name.localeCompare(b.lastName),
      sortDirections: ["descend", "ascend"],
      render: (_: any, advertiser) =>
        advertiser.tags.length ? (
          <Tooltip
            title={advertiser.tags.map((item: { lastName: string }) => (
              <span
                onClick={() =>
                  navigator.clipboard
                    .writeText(String(item.lastName))
                    .then(() =>
                      messageApi.success("Tag copiado al portapapeles")
                    )
                    .catch((err) =>
                      messageApi.success(
                        "Error al copiar al portapapeles:",
                        err
                      )
                    )
                }
                className="tag"
                style={{ color: "#000" }}
              >
                <TagsOutlined
                  style={{ fontSize: "10px", marginRight: "3px" }}
                />
                {item.lastName}
              </span>
            ))}
          >
            <span style={{ cursor: "pointer" }}>
              <TagsOutlined style={{ fontSize: "10px", marginRight: "3px" }} />
              {advertiser.lastName}
            </span>
          </Tooltip>
        ) : (
          <span style={{ cursor: "pointer" }}>{advertiser.lastName}</span>
        ),
    },

    {
      title: "Teléfono",
      dataIndex: "nroPhone",
      key: "nroPhone",
      sorter: (a, b) => a.nroPhone.localeCompare(b.nroPhone),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Ciudad",
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.city.localeCompare(b.city),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Sector",
      dataIndex: "sector",
      key: "sector",
      sorter: (a, b) => (a.sector || "").localeCompare(b.sector || ""),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Persona de contacto",
      dataIndex: "contactPerson",
      key: "contactPerson",
      sorter: (a, b) =>
        (a.contactPerson || "").localeCompare(b.contactPerson || ""),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => Number(b.status) - Number(a.status),
      sortDirections: ["descend", "ascend"],
      render: (status) =>
        status ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Activo
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="default">
            Inactivo
          </Tag>
        ),
    },
    {
      title: "Opciones",
      dataIndex: "actions",
      key: "actions",
      width: "20px",
      align: "center",
      render: (_: any, advertiser) => (
        <SpaceStyled justify="center" block={true}>
          {permAdv.isEdit && (
            <>
              <Tooltip title="Editar anunciante">
                <Link to={`/advertisers/${advertiser.id}/edit`}>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    type="primary"
                    size="middle"
                  />
                </Link>
              </Tooltip>
            </>
          )}
          {permAdv.isDel && (
            <Tooltip title="Eliminar anunciante">
              <Popconfirm
                title="¿Está seguro de eliminar el anunciante?"
                okButtonProps={{ danger: true }}
                onConfirm={() => onConfirmDelete(advertiser)}
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  type="primary"
                  danger
                  size="middle"
                />
              </Popconfirm>
            </Tooltip>
          )}
        </SpaceStyled>
      ),
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState([
    "name",
    "lastName",
    "nroPhone",
    "status",
    "actions",
  ]);

  const filteredColumns = alliesTableColumns.filter((column) =>
    selectedColumns.includes(String(column.key))
  );

  const onConfirmDelete = async (advertiser: IAdvertiser) => {
    const response: any = deleteAdvertiser(advertiser.id);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al intentar eliminar aliado"
      );
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al intentar eliminar aliado"
      );
      return;
    }
    messageApi.success(data?.message || "Aliado eliminado exitosamente");
    handlePageChange(1);
    loadAdvertisers();
  };

  const handleColumnChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedColumns(checkedValues.map((value) => value.toString()));
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Seleccione los campos a mostrar"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Card>
          <Checkbox.Group
            style={{
              marginBottom: "20px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
            }}
            value={selectedColumns}
            onChange={handleColumnChange}
          >
            {alliesTableColumns.map((col, index) => (
              <Checkbox
                key={index}
                value={
                  typeof col.key === "bigint"
                    ? col.key.toString()
                    : col.key ?? ""
                }
              >
                {col.title as string}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Card>
      </Modal>
      <Divider />
      <Button
        style={{ marginBottom: "15px", float: "right" }}
        type="primary"
        icon={<CheckSquareOutlined />}
        onClick={showModal}
      >
        Seleccionar campos a mostrar
      </Button>
      <Table
        columns={filteredColumns}
        dataSource={advertisers}
        loading={isLoading}
        rowClassName="bg-white"
        pagination={{
          defaultCurrent: 1,
          current: page,
          pageSize: limit,
          defaultPageSize: 10,
          onChange(page, pageSize) {
            handlePageChange(page);
            handleLimitChange(pageSize);
          },
          showSizeChanger: true,
          total: total,
          pageSizeOptions: ["15", "25", "50", "100"],
        }}
        scroll={{ x: "calc(400px + 50%)" }}
      />
    </>
  );
};

export default AdvertisersTable;
