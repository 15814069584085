import { FC, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, message, Form, Spin } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IAdvertiserInput } from "src/core/dtos";
import Head from "src/components/Head";
import uploadFile from 'src/services/aws/upload-file';
import AdvertiserForm from "../components/AdvertiserForm";
import { getAdvertiser, updateAdvertiser } from "src/services/advertisers";
import { BUCKET_URL } from "src/services/aws/aws-s3-config";

const EditAdvertisers: FC = () => {
  const { id } = useParams();
  const [valuesForm, setValuesForm] = useState<IAdvertiserInput>({
    name: "",
    lastName: "",
    nif: "",
    sector: "",
    contactPerson: "",
    address: "",
    city: "",
    nroPhone: "",
    nroPhone2: "",
    image: "",
    email: "",
    // tags: [],
    password: "",
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");

  const loadAlly = async () => {

    const response: any = getAdvertiser(Number(id));

    const { data, status } = await response || { data: undefined, status: undefined };

    if (!status) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      setIsLoading(false);
      return;
    }

    setValuesForm({
      name: data.data.name,
      lastName: data.data.lastName,
      nif: data.data.nif,
      contactPerson: data.data.contactPerson,
      address: data.data.address,
      city: data.data.city,
      sector: data.data.sector,
      nroPhone: data.data.nroPhone,
      nroPhone2: data.data.nroPhone2,
      status: data.data.status,
      image: data.data.image,
      email: data.data.email,
      tags: data.data.tags,
    });

    setIsLoading(false);
  }

  useEffect(() => {
    loadAlly();
  }, [])

  const onFinish = async ({ confirm, ...values }: IAdvertiserInput) => {
    setIsLoadingForm(true)
    let image = valuesForm.image;

    if (values.image) {
      const result = await uploadFile(values.image.name, values.image);
      image = `${BUCKET_URL}/images/${values.image.name}`;
    }

    const payload = {
      name: values.name,
      lastName: values.lastName,
      nif: values.nif,
      sector: values.sector,
      address: values.address,
      city: values.city,
      contactPerson: values.contactPerson,
      nroPhone: values.nroPhone,
      nroPhone2: values.nroPhone2,
      status: values.status,
      // tags: values.tags||[],
      image: image,
    }

    const response: any = updateAdvertiser(Number(id), payload);

    const { data, status } = await response || { data: undefined, status: undefined };

    if (!status) {
      messageApi.error(data?.message || "Ha ocurrido un error al actualizar el aliado");
      setIsLoadingForm(false);
      return;
    }

    if (data.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al actualizar el aliado");
      setIsLoadingForm(false);
      return;
    }

    navigate("/advertisers");
    setIsLoadingForm(false);
  };

  useEffect(() => {
    if (error)
      setTimeout(() => {
        setError("");
      }, 5000);
  }, [error]);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head
                bread={[
                  {
                    title: "Anunciantes",
                    path: "/advertisers",
                  },
                  {
                    title: "Editar",
                  }
                ]}
              >Editar anunciante</Head>
              <Spin spinning={isLoading}>
                {!isLoading && (
                  <AdvertiserForm
                    onFinish={onFinish}
                    values={valuesForm}
                    isLoading={isLoadingForm}
                    error={error}
                    form={form}
                  />
                )}
              </Spin>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default EditAdvertisers;
