import instance from "./_http";
import { IAPISourceList, IFile, getParams } from "src/core/dtos";

export const getAdvertisers = (params?: IAPISourceList<"Advertiser">[]) => instance.get(`/advertiser${getParams(params)}`).catch(({ response })=>response);
export const getAdvertiser = (id: number) => instance.get(`/advertiser/${id}`).catch(({ response })=>response);
export const createAdvertiser = (data: any) => instance.post("/advertiser", data).catch(({ response })=>response);
export const updateAdvertiser = (id: number, data: any) => instance.put(`/advertiser/${id}`, data, { headers: { "Content-Type": "application/json" } } ).catch(({ response })=>response);
export const deleteAdvertiser = (id: number) => instance.delete(`/advertiser/${id}`).catch(({ response })=>response);
export const updateSolvency = (id: number, isSolvent: boolean) => instance.put(`/advertiser/solvency/${id}`, { isSolvent }).catch(({ response })=>response);
export const countAllies = () => instance.get(`/advertiser/count/all`).catch(({ response })=>response);
export const updateQR = (id: number, data: { url: string, floatButton: boolean }) => instance.put(`/advertiser/qr/${id}`, data, { headers: { "Content-Type": "application/json" }}).catch(({ response })=>response);

//******* DEVICES *******
export const asignDevices = (id: number, data: { id: number }[]) => 
  instance.put(`/advertiser/${id}`,{ devices: data },{ headers: { "Content-Type": "application/json" }})
  .catch(({ response })=>response);

//****** FILES *******
export const updateFile = (allyId: number, file: IFile) => instance.post('/files', { file, allyId }).catch(({ response })=>response);