import React, { useState } from "react";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib";
import { UserType } from "src/core/interfaces";

interface IFilterUserType {
  type: UserType;
  setType: (type: UserType) => void;
}

const FilterUserType: React.FC<IFilterUserType> = ({ type, setType }) => {
  const handleChange = (e: RadioChangeEvent) => {
    const newValue = e.target.value as UserType;
    setType(newValue);
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      <Radio.Group value={type} onChange={handleChange}>
        <Radio.Button value="ADVERTISER">Anunciantes</Radio.Button>
        <Radio.Button value="ALLY">Riders</Radio.Button>
        <Radio.Button value="ADMIN">Administradores</Radio.Button>
        <Radio.Button value={undefined}>Todos</Radio.Button>
      </Radio.Group>
    </div>
  );
};

export default FilterUserType;
