import { FC, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, message, Form, Spin } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { ISettingInput } from "src/core/dtos";
import { getSetting, updateSetting } from "src/services/settings";
import Head from "src/components/Head";
import SettingForm from "src/modules/settings/components/SettingsForm";
import { AppContext } from "src/context/AppProvider";

const EditSetting: FC = () => {
  const { socket } = useContext(AppContext);
  const [values, setValues] = useState<ISettingInput>({
    password: "",
    timeBloqDev: 0,
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");

  const loadSetting = async () => {
    const response: any = getSetting(1);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    setValues(data?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    loadSetting();
  }, []);

  const onFinish = async (values: ISettingInput) => {
    setIsLoadingForm(true);
    const { password, timeBloqDev } = values;

    const data: ISettingInput = {
      password,
      timeBloqDev: Number(timeBloqDev),
    };

    const response: any = updateSetting(1, data);

    const { data: dataResponse = undefined, status = undefined } =
      await response;

    if (!status) {
      setError(
        dataResponse.message ||
          "Ha ocurrido un error al actualizar la configuración"
      );
      setIsLoadingForm(false);
      return;
    }

    if (dataResponse.statusCode !== 200) {
      setError(
        dataResponse.message ||
          "Ha ocurrido un error al actualizar la configuración"
      );
      setIsLoadingForm(false);
      return;
    }
    setTimeout(() => {
      messageApi.success(
        dataResponse.message ||
          "Se ha actualizado exitosamente la configuración"
      );
      setIsLoadingForm(false);
    }, 1000);
  };
  useEffect(() => {
    if (error)
      setTimeout(() => {
        setError("");
      }, 5000);
  }, [error]);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head
                bread={[
                  {
                    title: "Configuración",
                    path: "/settings",
                  },
                  {
                    title: "Editar configuración",
                  },
                ]}
              >
                Editar configuración
              </Head>
              <Spin spinning={isLoading}>
                {!isLoading && (
                  <SettingForm
                    onFinish={onFinish}
                    form={form}
                    values={values}
                    isLoading={isLoadingForm}
                    error={error}
                  />
                )}
              </Spin>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default EditSetting;
