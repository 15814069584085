import { Layout, Grid } from "antd";
import {
  AuthWrapperRowStyled,
  AuthFooterStyled,
} from "src/components/layout/authLayout/AuthWrapperStyled";
import { IAuthWrapperProps } from "src/core/interfaces";
import { FC } from "react";

const AuthLayout: FC<IAuthWrapperProps> = ({ children }) => {
  const { md } = Grid.useBreakpoint();

  return (
    <Layout>
      <AuthWrapperRowStyled ismobile={!md}>{children}</AuthWrapperRowStyled>
      <AuthFooterStyled>©2023 Grupo App4Riders. Todos los derechos reservados.</AuthFooterStyled>
    </Layout>
  );
};

export default AuthLayout;
