import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Result, message } from "antd";
import { confirmUser } from "src/services/auth";



const ActiveUser = () => {

    const { token } = useParams();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const LoadConfirmUser = async () => {
        setIsLoading(true);
        const response: any = confirmUser(String(token));

        const { data, status } = await response || { data: undefined, status: undefined };

        if (!status) {
            messageApi.error(data?.message || "Ha ocurrido un error");
            setIsLoading(false);
            return;
        }

        if (data?.statusCode !== 200) {
            messageApi.error(data?.message || "Ha ocurrido un error");
            setIsLoading(false);
            return;
        }

        setIsLoading(false);
    };

    useEffect(() => {
        setTimeout(() => {
            LoadConfirmUser();
        }, 3000);
    }, []);



    return (
        <>
            {isLoading ? (
                <Result
                    status="info"
                    title="Cargando..."
                    subTitle="Estamos activando tu cuenta, por favor espera un momento."

                />
            ) : (
                <Result
                    status="success"
                    title="Correo verificado"
                    subTitle="Tu correo ha sido verificado con éxito, ahora puedes iniciar sesión."
                    extra={[
                        <Button type="primary" key="console" onClick={() => navigate("/auth/login")}>
                            Iniciar sesión
                        </Button>,
                    ]}
                />
            )}
        </>
    );

};

export default ActiveUser;
