import { useState } from "react";

const usePageLimit = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [of, setOf] = useState(0);

  const handlePageChange = (page: number) => setPage(page);
  const handleLimitChange = (limit: number) => setLimit(limit);

  return { page, limit, of, setOf, handlePageChange, handleLimitChange };
};

export default usePageLimit;
