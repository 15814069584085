import { useState, useEffect } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Table, Tooltip } from "antd";
import { deleteZone, getZones } from "src/services/zones";
import SpaceStyled from "src/components/styled/Space";
import { Link, useNavigate } from "react-router-dom";
import { IPermission, IZone } from "src/core/dtos";
import { ITableProps } from "src/core/interfaces";
import usePageLimit from "src/hooks/usePageLimit";
import { ColumnsType } from "antd/es/table";
import { useSelector } from "react-redux";
import { FC } from "react";
import dayjs from "dayjs";

const ZoneTable: FC<ITableProps> = ({ search }) => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state: any) => state.auth);
  const permZone = permissions.find(
    (item: IPermission) => item.module === "Zones"
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [zones, setZones] = useState<IZone[]>([]);
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQR, setIsOpenQR] = useState(false);

  const loadZones = async () => {
    setIsLoading(true);

    const response: any = getZones([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
    ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    setZones(data.data);
    setTotal(data.total);
    setIsLoading(false);
  };

  useEffect(() => {
    loadZones();
  }, [page, limit, search]);

  const formatTime = (value: string) => {
    const time = JSON.parse(value);
    return time.hora
      ? dayjs().hour(time?.hora).minute(time?.minuto).format("h:mm a")
      : "Sin especificar";
  };

  const zonesTableColumns: ColumnsType<IZone> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, zones) => (
        <span style={{ cursor: "pointer" }}>{zones.name}</span>
      ),
    },

    {
      title: "Descripción",
      dataIndex: "description",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, zones) => (
        <span style={{ cursor: "pointer" }}>{zones.description}</span>
      ),
    },

    {
      title: "Opciones",
      dataIndex: "actions",
      key: "actions",
      width: "20px",
      align: "center",
      render: (_: any, zones) => (
        <SpaceStyled justify="center" block={true}>
          {permZone.isEdit && (
            <>
              {/*      <Tooltip placement="left" title="Añadir contenido multimedia">
              <Link to={`/zones/${zones.id}/files`} state={zones}>
                <Button
                  shape="circle"
                  icon={<PictureOutlined />}
                  ghost={true}
                  type="primary"
                  size="middle"
                />
              </Link>
            </Tooltip> */}
              <Tooltip title="Editar zona">
                <Link to={`/zones/${zones.id}/edit`}>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    type="primary"
                    size="middle"
                  />
                </Link>
              </Tooltip>
            </>
          )}
          {permZone.isDel && (
            <Tooltip title="Eliminar zona">
              <Popconfirm
                title="¿Está seguro de eliminar la zona?"
                okButtonProps={{ danger: true }}
                onConfirm={() => onConfirmDelete(zones)}
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  type="primary"
                  danger
                  size="middle"
                />
              </Popconfirm>
            </Tooltip>
          )}
          {/*    {permSche.isDel && (
            <Tooltip title="Eliminar programación">
              <Popconfirm
                title="¿Está seguro de eliminar la programación?"
                okButtonProps={{ danger: true }}
                onConfirm={() => onConfirmDelete(zones)}
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  type="primary"
                  danger
                  size="middle"
                />
                 </Popconfirm>
            </Tooltip
          >)} */}
        </SpaceStyled>
      ),
    },
  ];

  const onConfirmDelete = async (zones: IZone) => {
    const response: any = deleteZone(zones.id ?? 0);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al intentar eliminar la zona"
      );
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al intentar eliminar la zona"
      );
      return;
    }
    messageApi.success(data?.message || "Se ha eliminado exitosamente la zona");
    loadZones();
  };

  return (
    <>
      {contextHolder}
      <Table
        columns={zonesTableColumns}
        dataSource={zones}
        loading={isLoading}
        rowClassName="bg-white"
        pagination={{
          defaultCurrent: 1,
          current: page,
          pageSize: limit,
          defaultPageSize: 10,
          onChange(page, pageSize) {
            handlePageChange(page);
            handleLimitChange(pageSize);
          },
          showSizeChanger: true,
          total: total,
          pageSizeOptions: ["15", "25", "50", "100"],
        }}
        scroll={{ x: "calc(400px + 50%)" }}
      />
    </>
  );
};

export default ZoneTable;
