import { useState, FC } from "react";
import { useNavigate } from "react-router-dom";
import AllyForm from "src/modules/allies/components/AllyForm";
import { Row, Col, message, Form } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IAllyInput } from "src/core/dtos";
import { createAlly } from "src/services/allies";
import Head from "src/components/Head";
import uploadFile from 'src/services/aws/upload-file';
import { BUCKET_URL } from "src/services/aws/aws-s3-config";

const CreateAlly: FC = () => {
  const navigate = useNavigate();
  const valuesForm: IAllyInput = {
    name: "",
    lastName: "",
    rif: "",
    typeEmployee: "",
    typeVehicle: "",
    idMac: "",
    companyName: "",
    address: "",
    city: "",
    nroPhone: "",
    nroPhone2: "",
    status: true,
    logo: "",
    // tags:[],
    dueDate: 1,
    email: "",
    password: "",
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");
  const [form] = Form.useForm();

  const onFinish = async ({ confirm, ...values }: IAllyInput) => {

    setIsLoading(true);

    let logo = valuesForm.logo;
    
    if (values.logo) {
      const result = await uploadFile(values.logo.name, values.logo);
      logo = `${BUCKET_URL}/images/${values.logo.name}`;
    }

     
    const payload = {
      ally: {
        name: values.name,
        lastName: values.lastName,
        rif: values.rif,
        companyName: values.companyName,
        address: values.address,
        city: values.city,
        typeEmployee: values.typeEmployee,
        typeVehicle: values.typeVehicle,
        idMac: values.idMac,
        dueDate: Number(values.dueDate),
        nroPhone: values.nroPhone,
        nroPhone2: values.nroPhone2,
        status: values.status,
        // tags: values.tags||[],
        logo: logo,
      },
      user: {
        name: values.name,
        email: values.email,
        password: values.password,
      }
    }

    const data = await createAlly(payload).then(({ data })=>data).catch((err)=>err);

    if (data.statusCode!=200) {
      messageApi.error(data.message||'Ha ocurrido un error al crear un rider');
      setIsLoading(false);
      return;
    }

    navigate("/riders");
    setIsLoading(false);
  };

  // useEffect(() => {
  //   loads();
  //   if (error) { setTimeout(() => { setError(""); }, 5000); }
  // }, [error]);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head bread={[ "Riders", "Nuevo" ]}>Registrar nuevo rider</Head>
              <AllyForm
                onFinish={onFinish}
                values={valuesForm}
                isLoading={isLoading}
                error={error}
                form={form}
              />
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default CreateAlly;
