import {
  Card,
  Col,
  Row,
  List,
  message,
  Button,
  Divider,
  Input,
  Space,
  Select,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Head from "src/components/Head";
import SpaceStyled from "src/components/styled/Space";
import {
  ApartmentOutlined,
  CloudUploadOutlined,
  InsertRowLeftOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import ListFiles from "../components/ListFiles";
import CardFiles from "../components/CardFiles";
import CardToolsToggle from "src/components/CardToolsToggle";
import ModalUploadFiles from "../components/ModalUploadFiles";
import { getFiles } from "src/services/files";
import { IAPISourceList, IFile, IPermission, IZone } from "src/core/dtos";
import usePageLimit from "src/hooks/usePageLimit";
import { Message, Or, PreloadState } from "src/components/PreloadState";
import SelectZone from "../components/SelectZone";
import { getZones } from "src/services/zones";

const CustomListMediaItems = React.memo(
  ({
    files,
    loadMoreData,
    orderByList,
    loading,
  }: {
    files: IFile[];
    orderByList: true | false;
    loading: true | false;
    loadMoreData: (first?: boolean) => Promise<void>;
  }) => {
    return (
      <List
        grid={
          orderByList
            ? undefined
            : { xxl: 4, xl: 4, lg: 3, md: 2, sm: 2, xs: 1 }
        }
        loading={loading}
        dataSource={files}
        renderItem={(item) =>
          orderByList ? (
            <ListFiles file={item} refresh={loadMoreData} />
          ) : (
            <CardFiles file={item} refresh={loadMoreData} />
          )
        }
      />
    );
  }
);

const Files = () => {
  const { user, permissions } = useSelector((state: any) => state.auth);
  const permFile = permissions.find(
    (item: IPermission) => item.module === "Files"
  );
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const [_, setActiveEvent] = useState<Boolean>(false);
  const [orderByList, setOrderByList] = useState(false);
  const [advertiserId, setAdvertiserId] = useState(user.advertiserId);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [files, setFiles] = useState<IFile[]>([]);
  const [filesCreate, setFilesCreate] = useState<any>([]);
  const [openModalDetails, setOpenModalDetails] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [tags, setTags] = useState<string[]>([]);
  const [selectedZone, setSelectedZone] = useState<number | false>(false);
  const { page, limit, of, setOf, handlePageChange } = usePageLimit();

  const loadMoreData = async (first?: boolean) => {
    if (loading) {
      return;
    }
    if (first && page > 1) return handlePageChange(1);

    let parameters: IAPISourceList<"Files">[] = [
      { key: "page", value: page },
      { key: "limit", value: limit },

      { key: "advertiser", value: advertiserId || "" },
    ];

    Boolean(search) &&
      parameters.push({ key: "search", value: String(search) });
    Boolean(tags?.length) &&
      parameters.push({ key: "tags", value: String(tags?.toString()) });
    Boolean(selectedZone) &&
      parameters.push({ key: "zone", value: String(selectedZone) });

    setLoading(true);

    const {
      data: { data: newFiles, of, statusCode },
    } = await getFiles(parameters);

    if (statusCode !== 200) {
      messageApi.error("Ha ocurrido un error al cargar los datos");
      setLoading(false);
      return;
    }

    let tempFiles = [];

    if (page == 1) {
      tempFiles = newFiles;
    } else {
      tempFiles = [...files, ...newFiles];
    }

    setOf(of);
    setFiles(tempFiles);
    setLoading(false);
  };

  const loads = async () => {
    await loadMoreData();
  };

  useEffect(() => {
    loads();
  }, [page, limit, search, tags, selectedZone]);

  const changeInputFile: React.ChangeEventHandler<HTMLInputElement> = () => {
    if (Boolean(inputFileRef.current?.files?.length)) {
      setFilesCreate(inputFileRef?.current?.files);
      setOpenModalDetails(true);
    } else {
      setFilesCreate(new FileList());
      setOpenModalDetails(false);
    }
  };

  return (
    <SpaceStyled block={true} direction="vertical">
      {contextHolder}
      <Row justify="center">
        <Col xs={24} md={22}>
          <SpaceStyled block={true} direction="vertical" size="large">
            <Head bread={["Multimedia"]}>Gestión de contenido multimedia</Head>
            <Card>
              <input
                hidden
                type={"file"}
                id="inputFileRef"
                ref={inputFileRef}
                onChange={changeInputFile}
                accept="video/mp4, image/png, image/jpeg"
                style={{ display: "none" }}
              />
              <CardToolsToggle
                style={{ margin: "0px" }}
                placeholder="Buscar archivo..."
                setTags={setTags}
                setSearch={setSearch}
                selectedZone={selectedZone}
                setSelectedZone={setSelectedZone}
                tools={[
                  {
                    type: "dashed",
                    title: "",
                    tooltip: "Subir archivos",
                    icon: <CloudUploadOutlined />,
                    visibility: permFile.isCre || user.role === "ADVERTISER",
                    onClick: () => {
                      inputFileRef.current?.click();
                    },
                  },
                  {
                    type: "dashed",
                    tooltip: orderByList ? "Ver en bloques" : "Ver en lista",
                    icon: !orderByList ? (
                      <UnorderedListOutlined />
                    ) : (
                      <InsertRowLeftOutlined />
                    ),
                    onClick: () => setOrderByList(!orderByList),
                  },
                ]}
              />
            </Card>
            <Card style={{ overflowY: "auto" }}>
              <PreloadState cond={Boolean(files[0])} loading={loading}>
                <Or value={true}>
                  <CustomListMediaItems
                    files={files}
                    key={"filelist"}
                    loading={loading}
                    orderByList={orderByList}
                    loadMoreData={loadMoreData}
                  />
                </Or>
                <Message value={false} message="No nay multimedia" />
              </PreloadState>
              {files?.length < of && (
                <Col
                  style={{
                    marginTop: "1em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => handlePageChange(page + 1)}
                  >
                    Cargar más
                  </Button>
                </Col>
              )}
            </Card>
          </SpaceStyled>
        </Col>
      </Row>
      <ModalUploadFiles
        files={filesCreate}
        setFiles={setFilesCreate}
        isOpen={openModalDetails}
        toggle={setOpenModalDetails}
        setActiveEvent={loadMoreData}
      />
    </SpaceStyled>
  );
};

export default Files;
