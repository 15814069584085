import { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, message, Form, Spin, Typography, Card } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IUserDetail } from "src/core/dtos";
import { getUser } from "src/services/users";
import Head from "src/components/Head";
import ImageProfile from "src/components/ImageProfile";

const DetailUser: FC = () => {
  const { id } = useParams();
  const [values, setValues] = useState<IUserDetail | undefined>(undefined);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");

  const getUserDetail = () => {
    getUser(Number(id), true)
      .then(({ data }) => {
        if (data.data) {
          setValues(data?.data);
        }
      })
      .catch((error) => {
        messageApi.error("No se encontró el aliado");
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head bread={["Usuarios", "Detalle de usuario"]}>Detalle de usuario</Head>
              <Card>
                <Spin spinning={isLoading}>
                  <Row>
                    {(values?.allyId || values?.advertiserId) && (
                      <Col
                        xs={24}
                        lg={6}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ImageProfile
                          id={Number(values.allyId)}
                          initialValue={values?.ally?.logo || values?.advertiser?.image}
                        />
                      </Col>
                    )}
                    <Col xs={24} lg={(values?.allyId || values?.advertiserId) ? 18 : 24}>
                      <Row>
                        <Col xs={24} md={24} lg={12}>
                          <Form.Item label="Nombres" name="name">
                            <Typography.Text style={{ margin: "0px" }}>{values?.name || ""}</Typography.Text>
                          </Form.Item>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                          <Form.Item label="Correo" name="email" hasFeedback>
                            <Typography.Text style={{ margin: "0px" }}>{values?.email}</Typography.Text>
                          </Form.Item>
                        </Col>
                      </Row>
                      {(values?.allyId || values?.advertiserId) && (
                        <>
                          <Row>
                            <Col xs={24} md={24} lg={12}>
                              <Form.Item label="Apellidos" name="lastName">
                                <Typography.Text style={{ margin: "0px" }}>{values?.ally?.lastName || values?.advertiser?.lastName || ""}</Typography.Text>
                              </Form.Item>
                            </Col>
                            {values.ally ?
                              <Col xs={24} md={24} lg={12}>
                                <Form.Item label="ID de mochila" name="idMac">
                                  <Typography.Text style={{ margin: "0px" }}>{values?.ally?.devices?.length ? values.ally.devices[0].code : "Sin asignar"}</Typography.Text>
                                </Form.Item>
                              </Col> :
                              <Col xs={24} md={24} lg={12}>
                                <Form.Item label="Persona de contacto" name="contactPerson">
                                  <Typography.Text style={{ margin: "0px" }}>{values?.advertiser?.contactPerson || ""}</Typography.Text>
                                </Form.Item>
                              </Col>
                            }
                          </Row>
                          <Row>
                            <Col xs={24} md={24} lg={12}>
                              <Form.Item label="NIF" name="rif">
                                <Typography.Text style={{ margin: "0px" }}>{values?.ally?.rif || values?.advertiser?.nif || ""}</Typography.Text>
                              </Form.Item>
                            </Col>
                            {values.ally ?
                              <Col xs={24} md={24} lg={12}>
                                <Form.Item label="Tipo de vehículo" name="typeVehicle">
                                  <Typography.Text style={{ margin: "0px" }}>{values?.ally?.typeVehicle || ""}</Typography.Text>
                                </Form.Item>
                              </Col>
                              : <Col xs={24} md={24} lg={12}>
                                <Form.Item label="Sector" name="sector">
                                  <Typography.Text style={{ margin: "0px" }}>{values?.advertiser?.sector || ""}</Typography.Text>
                                </Form.Item>
                              </Col>
                            }
                          </Row>
                          <Row>
                            <Col xs={24} md={24} lg={12}>
                              <Form.Item label="Teléfono" name="nroPhone">
                                <Typography.Text style={{ margin: "0px" }}>{values?.ally?.nroPhone || values?.advertiser?.nroPhone || ""}</Typography.Text>
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={24} lg={12}>
                              <Form.Item label={`Estado del ${values.ally ? "rider" : "anunciante"}`} name="status">
                                <Typography.Text style={{ margin: "0px" }}>{(values?.ally?.status || values?.advertiser?.status) ? 'Activo' : 'Inactivo'}</Typography.Text>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={24} md={24} lg={12}>
                              <Form.Item label="Ciudad" name="city">
                                <Typography.Text style={{ margin: "0px" }}>{values?.ally?.city || values?.advertiser?.city || ""}</Typography.Text>
                              </Form.Item>
                            </Col>
                            {values.ally ?
                              <Col xs={24} md={24} lg={12}>
                                <Form.Item label="Tipo de empleado" name="typeEmployee">
                                  <Typography.Text style={{ margin: "0px" }}>{values?.ally?.typeEmployee || ""}</Typography.Text>
                                </Form.Item>
                              </Col>
                              : <Col xs={24} md={24} lg={12}>
                                <Form.Item label="Dirección" name="address">
                                  <Typography.Text style={{ margin: "0px" }}>{values?.advertiser?.address || ""}</Typography.Text>
                                </Form.Item>
                              </Col>
                            }
                          </Row>
                          {values.ally &&
                            <Row>
                              <Col xs={24} md={24} lg={24}>
                                <Form.Item label="Dirección" name="address">
                                  <Typography.Text style={{ margin: "0px" }}>{values?.ally?.address || ""}</Typography.Text>
                                </Form.Item>
                              </Col>
                              {values.ally?.typeEmployee === "Cuenta ajena" && (
                                <Col xs={24} md={24} lg={12}>
                                  <Form.Item label="Empresa" name="conmpanyName">
                                    <Typography.Text style={{ margin: "0px" }}>{values?.ally?.companyName || ""}</Typography.Text>
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          }
                        </>
                      )}
                    </Col>
                  </Row>
                </Spin>
              </Card>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled >
    </>
  );
};

export default DetailUser;
