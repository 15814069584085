import { useEffect, useState, useContext } from "react";
import { Tag } from "antd";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { AppContext } from "src/context/AppProvider";

export interface IDeviceStatus {
  initialStatus: string;
}

const PackageStatus = ({ initialStatus }: IDeviceStatus) => {
  const { socket } = useContext(AppContext);
  const [status, setStatus] = useState(initialStatus);

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  switch (status) {
    case "PENDING":
      return (
        <Tag icon={<ClockCircleOutlined />} color="warning">
          Pendiente
        </Tag>
      );
    case "APPROVED":
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Aprobado
        </Tag>
      );
    default:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Rechazado
        </Tag>
      );
  }
};

export default PackageStatus;
