import {
  Button,
  Col,
  Dropdown,
  List,
  Popconfirm,
  message,
  Row,
  Space,
  Tooltip,
  Checkbox,
} from "antd";
import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  EyeFilled,
  LinkOutlined,
  EditOutlined,
} from "@ant-design/icons";
import ModalInfoFile from "./ModalInfoFile";
import { deleteFile } from "src/services/files";
import type { MenuProps } from "antd";
import moment from "moment";
import { copyToClipboard, handleDownload } from "src/components/utils";
import box_icon from "./../../../assets/icons/box-file.png";
import { IPackage, IPermission } from "src/core/dtos";
import { AppContext } from "src/context/AppProvider";

const ListPackages = ({
  Package,
  refresh,
}: {
  Package: IPackage;
  refresh: () => void;
}) => {
  const { socket } = useContext(AppContext);
  const { permissions } = useSelector((state: any) => state.auth);
  const permZone = permissions.find(
    (item: IPermission) => item.module === "Zones"
  );
  const [hover, setHover] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <List.Item
        className="transited"
        onMouseEnter={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          background: hover ? "#f9f9f9" : "#fff",
        }}
      >
        <Row justify={"space-between"} align={"middle"}>
          <Row justify={"start"} align={"middle"}>
            <Col
              style={{ padding: "0px 20px 0px 0px" }}
              onMouseEnter={() => setHover(true)}
            >
              <Checkbox value={Package.id} />
            </Col>
            <Col onMouseEnter={() => setHover(true)}>
              <div>{Package.name || "Untitled"}</div>
              <small>
                <strong style={{ marginRight: "15px" }}>Fecha:</strong>
                <i>{moment(Package.createdAt).format("DD-MMMM-YYYY")}</i>
                <span style={{ margin: "15px 0px" }}>┊</span>
                <span>{Package.description}</span>
              </small>
              {/* <div style={{ overflowX: "auto" }}>
              {file?.tags?.length > 0 && file?.tags?.map(item =>
                <small className='tag' onClick={() => navigator.clipboard.writeText(String(item.name)).then(() => messageApi.success("Tag copiado al portapapeles")).catch(err => messageApi.success('Error al copiar al portapapeles:', err))}>
                  {item.name}
                </small>)}
            </div> */}
            </Col>
          </Row>
        </Row>
      </List.Item>
    </>
  );
};

export default ListPackages;
