import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Alert,
  Upload,
  Select,
  message,
} from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IAdvertiserFormProps } from "src/core/interfaces";
import { InboxOutlined } from "@ant-design/icons";
import { TagsInput } from "react-tag-input-component";
import ImageProfile from "../../../components/ImageProfile";
import { cities } from "src/core/constants";
import { getDevices } from "src/services/devices";
import usePageLimit from "src/hooks/usePageLimit";
import { IDevice } from "src/core/dtos";

const AdvertiserForm: FC<IAdvertiserFormProps> = ({
  onFinish,
  values,
  isLoading,
  error,
  form,
}) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [initialValues, setInitialValues] = useState(values);
  const [file, setFile] = useState<any>(null);
  const [currentItems, setCurrentItems] = useState<IDevice[]>([]);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [isLoadingDevices, setIsLoadingDevices] = useState(true);

  const loadDevices = () => {
    /*   setIsLoading(true); */
    getDevices([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
      { key: "available", value: "true" },
    ])
      .then(({ data }) => {
        if (data.statusCode === 200) {
          setCurrentItems(
            data.data.map((item: IDevice) => {
              return { ...item, key: item.id };
            })
          );
          setTotal(data.data.length ? data.total : 0);
        }
      })
      .catch((error) => {
        messageApi.error("Ha ocurrido un error al cargar los datos");
      })
      .finally(() => setIsLoadingDevices(false));
  };

  useEffect(() => {
    loadDevices();
  }, []);

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => onFinish({ ...values, image: file })}
      >
        <SpaceStyled block={true} direction="vertical" size="middle">
          <Card>
            {error && <Alert type="error" message={error} showIcon />}
            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Nombres"
                  name="name"
                  initialValue={initialValues.name}
                  rules={[
                    { required: true, message: "El nombre es requerido" },
                  ]}
                  hasFeedback
                >
                  <Input placeholder="Ingrese el nombre" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Apellidos"
                  name="lastName"
                  initialValue={initialValues.lastName}
                  rules={[
                    { required: true, message: "El Apellido es requerido" },
                  ]}
                  hasFeedback
                >
                  <Input placeholder="Ingrese el Apellido" size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="NIF"
                  name="nif"
                  initialValue={initialValues.nif}
                  rules={[{ required: true, message: "El NIF es requerido" }]}
                  hasFeedback
                >
                  <Input placeholder="Ingrese el NIF" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Teléfono"
                  name="nroPhone"
                  initialValue={initialValues.nroPhone}
                  hasFeedback
                >
                  <Input
                    placeholder="Ingrese el número de teléfono"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Estado del anunciante"
                  name="status"
                  initialValue={initialValues.status}
                  rules={[
                    { required: true, message: "El estado es requerido" },
                  ]}
                  hasFeedback
                >
                  <Select
                    defaultValue={initialValues.status}
                    placeholder="Ingrese la ciudad"
                    options={[
                      { value: true, label: "Activo" },
                      { value: false, label: "Inactivo" },
                    ]}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Ciudad"
                  name="city"
                  initialValue={initialValues.city}
                  rules={[
                    { required: true, message: "La ciudad es requerida" },
                  ]}
                  hasFeedback
                >
                  <Select
                    placeholder="Ingrese la ciudad"
                    options={cities || []}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Sector"
                  name="sector"
                  initialValue={initialValues.sector}
                  rules={[
                    { required: true, message: "El sector es requerido" },
                  ]}
                  hasFeedback
                >
                  <Input placeholder="Ingrese el sector" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Persona de contacto"
                  name="contactPerson"
                  initialValue={initialValues.contactPerson}
                  rules={[
                    {
                      required: true,
                      message: "La persona de contacto es requerida",
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder="Ingrese la persona de contacto"
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={24} lg={24} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Dirección"
                  name="address"
                  initialValue={initialValues.address}
                  rules={[
                    { required: true, message: "La dirección es requerida" },
                  ]}
                  hasFeedback
                >
                  <Input placeholder="Ingrese la dirección" size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {/* <Col xs={24} md={24} lg={12} style={{ paddingLeft: "7px" }}>
                <Form.Item
                  label="Día de corte"
                  name="dueDate"
                  initialValue={initialValues.dueDate}
                  hasFeedback
                >
                  <InputNumber
                    type="number"
                    min={1}
                    max={31}
                    defaultValue={1}
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col> */}

              {!values.name && (
                <>
                  <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                    <Form.Item
                      label="Correo electrónico"
                      name="email"
                      initialValue={initialValues.email}
                      rules={[
                        {
                          type: "email",
                          message: "No es un correo válido",
                        },
                        {
                          required: true,
                          message: "El correo electrónico es requerido",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        placeholder="Ingrese el correo electrónico"
                        size="large"
                        autoComplete="email"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={12} style={{ paddingLeft: "7px" }}>
                    <Form.Item
                      label="Contraseña"
                      name="password"
                      rules={[
                        { required: values.email ? false : true, message: "La contraseña es requerida" },
                        {
                          min: 8,
                          message: "La contraseña debe tener al menos 8 caracteres",
                        },
                        {
                          max: 20,
                          message: "La contraseña debe tener máximo 20 caracteres",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        placeholder="Ingresar contraseña"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                    <Form.Item
                      name="confirm"
                      label="Confirmar contraseña"
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        { required: values.email ? false : true, message: "Por favor confirme su contraseña" },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error("Las contraseñas no coinciden")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        placeholder="Confirmar contraseña"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>

            {/* <Row>
              <Col xs={24} md={24} lg={24} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Etiquetas"
                  name="tags"
                  initialValue={initialValues.tags.map((item)=>item.name)}
                  hasFeedback
                >
                  <TagsInput 
                    separators={[",","#"," ","Enter"]}
                    placeHolder={"Tags"} 
                    value={initialValues.tags.map((item)=>item.name)}
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Row>
              <Col xs={24} md={24} lg={24}>
                <Form.Item label="Imagen">
                  <ImageProfile
                    initialValue={initialValues.image}
                    setValue={setFile}
                  />
                  {/* <Form.Item
                    name="logo"
                    valuePropName="fileList"
                    getValueFromEvent={onDrop}
                    noStyle
                  >
                    <Upload.Dragger
                      name="files"
                      maxCount={1}
                      listType="picture"
                      showUploadList={{ showRemoveIcon: false }}
                      accept="image/png, image/jpeg, image/jpg"
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Arrastra aquí el archivo o haz click para seleccionar
                      </p>
                    </Upload.Dragger>
                  </Form.Item> */}
                </Form.Item>
              </Col>
            </Row>
            <Row align={"middle"} justify={"end"}>
              <Form.Item style={{ marginRight: "7px", minWidth: "140px" }}>
                <Button
                  type="default"
                  block
                  size="large"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Atrás
                </Button>
              </Form.Item>
              <Form.Item style={{ marginLeft: "7px", minWidth: "140px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={isLoading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Card>
        </SpaceStyled>
      </Form>
    </>
  );
};
export default AdvertiserForm;
