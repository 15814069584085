import {
  ApartmentOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  FilterOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import React, { FC, useState } from "react";
import { ICardToolsToggle } from "src/core/interfaces";
import { TagsInput } from "react-tag-input-component";
import SelectZone from "src/modules/multimedia/components/SelectZone";
import SelectStatus from "src/modules/campaign/components/SelectStatus";

const CardToolsToggle: FC<ICardToolsToggle> = ({
  placeholder,
  setSearch,
  tools,
  style,
  selectedZone,
  setSelectedZone,
  setStatus,
  setTags,
  bottomDecoration,
  title,
}) => {
  const [tempSearch, setTempSearch] = useState("");
  const [tempTags, setTempTags] = useState<string[]>([]);
  const [activeTags, setActiveTags] = useState<boolean>(false);
  const [openZoneFilter, setOpenZoneFilter] = useState<boolean>(false);
  const [openStatusFilter, setOpenStatusFilter] = useState<boolean>(false);

  return (
    <>
      <Row
        gutter={[10, 10]}
        justify={{ xs: "center", sm: "center", md: "space-between" }}
        style={{ marginBottom: "20px", ...style }}
      >
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          style={{
            display: "flex",
            width: "auto",
            alignItems: "center",
          }}
        >
          {title && (
            <Typography.Title level={5} style={{ margin: "0" }}>
              {title}
            </Typography.Title>
          )}
          {setSearch && (
            <Input.Search
              placeholder={placeholder || "Buscar..."}
              size="middle"
              value={tempSearch}
              onChange={(e) => {
                setTempSearch(e.target.value);
                if (e.target.value == "") return setSearch("");
              }}
              onSearch={() => setSearch(tempSearch)}
              enterButton={<SearchOutlined />}
            />
          )}
        </Col>

        <Col
          style={{
            display: "flex",
            width: "auto",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          {tools.map((tool: any, i: number) =>
            tool.visibility === false ? (
              <></>
            ) : tool.element == "input" ? (
              <Tooltip key={i} title={tool.tooltip}>
                <Input
                  size={tool.size || "middle"}
                  type={tool.type || "primary"}
                  prefix={tool.title ? tool.icon || undefined : undefined}
                  suffix={tool.sufix || undefined}
                  value={tool.value}
                  onChange={tool.onChange || (() => {})}
                  key={i}
                  placeholder={
                    tool.title ? tool.title : tool.icon ? tool.icon : "input"
                  }
                  disabled={!!tool.disabled}
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                />
              </Tooltip>
            ) : tool.element == "select" ? (
              <Select
                options={tool.options || []}
                value={tool.value}
                onChange={tool.onChange || (() => {})}
                key={i}
                size={tool.size || "middle"}
                disabled={!!tool.disabled}
                loading={!!tool.loading}
                allowClear={!!tool.allowClear}
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                  minWidth: "110px",
                  maxWidth: "200px",
                }}
              />
            ) : (
              <Tooltip key={i} title={tool.tooltip}>
                <Button
                  block
                  size={tool.size || "middle"}
                  type={tool.type || "primary"}
                  icon={tool.title ? tool.icon || undefined : undefined}
                  onClick={tool.onClick || (() => {})}
                  key={i}
                  disabled={!!tool.disabled}
                  ghost={tool.ghost}
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                >
                  {tool.title ? tool.title : tool.icon ? tool.icon : "button"}
                </Button>
              </Tooltip>
            )
          )}
          {setTags && (
            <Tooltip key={"Filter"} title={"Filtrar por tags"}>
              <Button
                style={{ marginLeft: "5px", marginRight: "5px" }}
                type={activeTags ? "primary" : "dashed"}
                onClick={() => setActiveTags(!activeTags)}
              >
                <FilterOutlined />
              </Button>
            </Tooltip>
          )}
          {setSelectedZone && (
            <Tooltip key={"openZone"} title={"Ver por zona"}>
              <Button
                block
                size={"middle"}
                type={openZoneFilter ? "primary" : "dashed"}
                onClick={() => setOpenZoneFilter(!openZoneFilter)}
                style={{ marginLeft: "5px", marginRight: "5px" }}
              >
                <ApartmentOutlined />
              </Button>
            </Tooltip>
          )}
          {setStatus && (
            <Tooltip key={"openStatus"} title={"Filtrar por estado"}>
              <Button
                block
                size={"middle"}
                type={openStatusFilter ? "primary" : "dashed"}
                onClick={() => setOpenStatusFilter(!openStatusFilter)}
                style={{ marginLeft: "5px", marginRight: "5px" }}
              >
                <SyncOutlined />
              </Button>
            </Tooltip>
          )}
        </Col>
      </Row>
      {bottomDecoration && (
        <Row>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
            {bottomDecoration}
          </Col>
        </Row>
      )}
      {setTags && activeTags && (
        <>
          <Divider />
          <Row gutter={[10, 10]} justify={"center"}>
            <Col xs={24} md={24} lg={24} style={{ display: "flex" }}>
              <Space.Compact direction="horizontal" block>
                <Select
                  mode="tags"
                  options={[]}
                  value={tempTags}
                  placeholder="Escriba los tags para buscar"
                  tokenSeparators={["#", ",", " ", "Enter"]}
                  style={{ minWidth: "46%" }}
                  onChange={setTempTags}
                />
                <Space.Compact direction="horizontal">
                  <Tooltip placement="right" title="Buscar resultados">
                    <Button
                      size={Boolean(tempTags?.length) ? "middle" : "large"}
                      type="primary"
                      style={{ background: "#46b98d", color: "white" }}
                      onClick={() => setTags(tempTags)}
                      disabled={!Boolean(tempTags.length)}
                    >
                      <FilterOutlined />
                    </Button>
                  </Tooltip>
                  {Boolean(tempTags?.length) && (
                    <Tooltip placement="right" title="Limpiar seleccion">
                      <Button
                        size="middle"
                        type="primary"
                        style={{ background: "#ff7740" }}
                        onClick={() => {
                          setTempTags([]);
                          setTags([]);
                        }}
                        disabled={!Boolean(tempTags.length)}
                      >
                        <CloseOutlined />
                      </Button>
                    </Tooltip>
                  )}
                </Space.Compact>
              </Space.Compact>
            </Col>
          </Row>
        </>
      )}
      {setSelectedZone && openZoneFilter && (
        <>
          <Divider />
          <Row>
            <Col md={12} sm={24} xs={24}>
              <SelectZone
                selected={selectedZone}
                setSelected={(value) => {
                  setSelectedZone(value);
                }}
              />
            </Col>
          </Row>
        </>
      )}
      {setStatus && openStatusFilter && (
        <>
          <Divider />
          <Row>
            <Col md={12} sm={24} xs={24}>
              <SelectStatus
                selected={selectedZone}
                setSelected={(value) => {
                  setStatus(value);
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CardToolsToggle;
