import instance from "./_http";
import { IAPISourceList, IFile, getParams } from "src/core/dtos";

export const getZones = (params?: IAPISourceList<"Zones">[]) => instance.get(`/zones${getParams(params)}`).catch(({ response })=>response);
export const getZone = (id: number) => instance.get(`/zones/${id}`).catch(({ response })=>response);
export const ZoneCreate = (data: any) => instance.post("/zones", data, { headers: { "Content-Type": "application/json" } } ).catch(({ response })=>response);
export const updateZone = (id: number, data: any) => instance.put(`/zones/${id}`, data, { headers: { "Content-Type": "application/json" } } ).catch(({ response })=>response);
export const deleteZone = (id: number) => instance.delete(`/zones/${id}`).catch(({ response })=>response);
export const getFilesZone = (id: number, params?: IAPISourceList<"Zones">[]) => instance.get(`/files/filesall/${id}${getParams(params)}`).catch(({ response })=>response);
export const createFilesZone = (data: any) => instance.post("/zones", data, { headers: { "Content-Type": "application/json" } }).catch(({ response })=>response);
