import { Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import {
  Modal,
  message,
  Form,
  Button,
  Input,
  Divider,
  Row,
  Col,
  Space,
  Select,
} from "antd";
import { createFile } from "src/services/files";
import { TagsInput } from "react-tag-input-component";
import PreviewMultimedia from "./PreviewMultimedia";
import { getAdvertisers } from "src/services/advertisers";
import usePageLimit from "src/hooks/usePageLimit";
import { IAdvertiser } from "src/core/dtos";
import { useSelector } from "react-redux";
import { BUCKET_URL } from "src/services/aws/aws-s3-config";
import uploadFile from "src/services/aws/upload-file";

export interface IModalRegisterDevice {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  files: FileList;
  setFiles: Dispatch<SetStateAction<FileList | null>>;
  setActiveEvent?: (first?: boolean) => void;
}

const ModalUploadFiles = ({
  isOpen,
  toggle,
  setActiveEvent,
  files,
  setFiles,
}: IModalRegisterDevice) => {
  const [form] = Form.useForm();
  const { user } = useSelector((state: any) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<File>();
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [search, setSearch] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [extension, setExtension] = useState("");
  const [tags, setTags] = useState<string[]>();
  const video_ref = useRef<HTMLVideoElement>(null);
  const [delay, setDelay] = useState<number | undefined>();
  const [advertisers, setAdvertisers] = useState<IAdvertiser[]>([]);
  const [total, setTotal] = useState(0);
  const loadFiles = async () => {
    setData(files[0]);
    setExtension(files[0]?.name ? `.${files[0]?.name.split(".").pop()}` : "");
    form.setFieldValue("name", getName(String(files[0]?.name)));
    form.setFieldValue("delay", "10");
    form.setFieldValue("description", "");
  };

  const loadAdvertisers = async () => {
    setIsLoading(true);

    const response: any = getAdvertisers([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
    ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    setAdvertisers(data?.data);
    setTotal(data?.total);

    setIsLoading(false);
  };

  const loads = async () => {
    setIsLoading(true);
    await loadAdvertisers();
    await loadFiles();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      loads();
    } else {
      setFiles(null);
      setTags([]);
      setExtension("");
      setDelay(undefined);
      setIsLoading(false);
      form.resetFields();
      video_ref?.current?.pause();
    }
  }, [files]);

  const getName = (data: string) => {
    const lastIndex = data.lastIndexOf(".");
    return data.substring(0, lastIndex);
  };

  const uploadFiles = async (values: any) => {
    if (data == undefined) return;

    const { description, delay, name, advertiserId } = values;

    /*  const _URL = `https://guao-staging-bucket.nyc3.digitaloceanspaces.com/Guao/${identifyFiles(data)}/${data.name}`; */
    const _URL = `${BUCKET_URL}/advertising/${data.name}`;

    const initial: any = {
      name: String(name).toLocaleLowerCase() + extension,
      url: _URL,
      type: data.type,
      description: description,
      advertiserId: user.role === "ADMIN" ? advertiserId : user.advertiserId,
      size: Number((data.size / (1024 * 1024)).toFixed(2)),
      delay: Number(delay),
      tags: tags,
    };

    setIsLoading(true);

    const result = await uploadFile(data.name, data, 'advertising');
    console.log(result)
    if (!result) {
      messageApi.error(`No se pudo guardar el archivo`);
      return;
    }

    const response = await createFile(initial);

    if (response.data.statusCode != 200) {
      messageApi.error(
        response.data.message || "Ha ocurrido un error al guardar el archivo"
      );
      setIsLoading(false);
      return;
    }
    messageApi.success("Se ha guardado el archivo exitosamente");
    closeModal();
    setIsLoading(false);
  };

  const closeModal = () => {
    form.resetFields();
    setDelay(undefined);
    setActiveEvent && setActiveEvent(true);
    toggle(false);
  };

  return (
    <Modal
      title={`Archivos a subir`}
      open={isOpen}
      width={720}
      onCancel={closeModal}
      footer={<></>}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Form form={form} layout="vertical" onFinish={uploadFiles}>
        {files && files?.length > 0 && (
          <Row gutter={15}>
            <Col xs={12}>
              {data && (
                <PreviewMultimedia
                  form={form}
                  file={data}
                  video_ref={video_ref}
                />
              )}
            </Col>
            <Col xs={12}>
              <Form.Item
                name="name"
                label="Nombre"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese un nombre",
                  },
                ]}
              >
                <Input suffix={extension} defaultValue={""} />
              </Form.Item>
              <Form.Item
                name="description"
                label="Descripción"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingrese una descripción",
                  },
                ]}
              >
                <Input defaultValue={""} placeholder="Descripción" />
              </Form.Item>
              {user.role === "ADMIN" && (
                <Form.Item name="advertiserId" label="Anunciante">
                  <Select
                    showSearch
                    placeholder="Seleccione un anunciante"
                    options={advertisers.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </Form.Item>
              )}
              <Form.Item
                name="delay"
                label="Duración (segundos)"
                rules={[
                  { required: true, message: "Por favor ingrese la duración" },
                ]}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    value={delay}
                    placeholder="Duración"
                    onKeyDown={(e) => {
                      if (
                        ![
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "0",
                          "Backspace",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const current = Number(e.target.value);
                      setDelay(Number(current < 500 ? current : 500));
                      form.setFieldValue(
                        "delay",
                        Number(current < 500 ? current : 500)
                      );
                    }}
                  />
                  <Button
                    type="dashed"
                    disabled={!data}
                    onClick={() => {
                      if (!video_ref?.current) return;
                      setDelay(Number(video_ref?.current?.duration));
                      form.setFieldValue(
                        "delay",
                        Number(video_ref?.current?.duration)
                      );
                    }}
                  >
                    Completo
                  </Button>
                </Space.Compact>
              </Form.Item>
              <Form.Item name="tags" label="Tags del archivo">
                <TagsInput
                  separators={[",", "#", " ", "Enter"]}
                  value={tags}
                  onChange={setTags}
                  placeHolder="Tags"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Divider style={{ marginTop: "10px" }} />
        <Row justify={"end"} align={"middle"}>
          <Button
            onClick={closeModal}
            type="default"
            htmlType="button"
            style={{ marginRight: 15 }}
          >
            Volver
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {isLoading ? "Un momento" : "Subir"}
          </Button>
        </Row>
      </Form>

      {contextHolder}
    </Modal>
  );
};

export default ModalUploadFiles;
