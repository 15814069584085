import {
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useEffect,
  useContext,
} from "react";

// import SidebarStyled from "../styled/Sidebar";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  UnorderedListOutlined,
  LogoutOutlined,
  UserOutlined,
  CheckCircleOutlined,
  BellOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Button,
  theme,
  Grid,
  Row,
  Tooltip,
  Dropdown,
  Menu,
  Typography,
  Badge,
  Empty,
  Col,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuProps } from "antd/lib";
import { getUserNotifications } from "src/services/notifications";
import { cleanAll } from "src/redux/slices/auth.slice";
import DrawerStyled from "../styled/DrawerStyled";
import TopicMenu from "./TopicMenu";
import { topics } from "src/core/topics";
import { AppContext } from "src/context/AppProvider";

export interface INavbarProps {
  setCollapsed: Dispatch<SetStateAction<boolean>>;
  collapsed?: boolean;
  lonely?: boolean;
}

const Navbar: FC<INavbarProps> = ({ collapsed, setCollapsed, lonely }) => {
  const dispatch = useDispatch();
  const { Header, Content } = Layout;
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.auth);
  const [visible, setVisible] = useState(false);
  const [allNotificationsRead, setAllNotificationsRead] = useState(false);
  const [ListNotifications, setListNotifications] = useState([]);
  const { md } = Grid.useBreakpoint();

  const items: MenuProps["items"] = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: <Typography.Text>Perfil</Typography.Text>,
      onClick: () => navigate("/profile"),
    },
    {
      key: "2",
      icon: <LogoutOutlined />,
      label: <Typography.Text>Cerrar sesión</Typography.Text>,
      onClick: () => {
        dispatch(cleanAll());
        navigate("/auth/login");
      },
    },
  ];

  const fetchNotifications = async () => {
    try {
      const response = await getUserNotifications(user.id, [
        { key: "page", value: 1 },
        { key: "limit", value: 10 },
        { key: "role", value: String(user.role) },
      ]);
      if (response.status === 200) {
        setListNotifications(response.data.data);
        const allRead = response.data.data.every(
          (notification: any) => notification.read
        );
        setAllNotificationsRead(allRead);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const { selectedMenu, setSelectedMenu } = useContext(AppContext);
  const changeSelectedKey = (menu: string) => setSelectedMenu(menu);

  useEffect(() => {
    if (location?.pathname) {
      if (location?.pathname === "/dashboard") {
        setSelectedMenu("/dashboard");
      } else {
        const selected = topics.find(
          (topic) =>
            location?.pathname.includes(topic.path) &&
            topic.path !== "/dashboard"
        );
        if (selected) {
          setSelectedMenu(selected.path);
        }
      }
    }
  }, [location?.pathname, setSelectedMenu]);

  const menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedMenu}
      changeSelectedKey={changeSelectedKey}
    />
  );

  return (
    <Header style={{ padding: 0, background: colorBgContainer }}>
      <Row
        style={{ height: "100%", padding: "0px 10px" }}
        justify={"space-between"}
        align={"middle"}
      >
        <DrawerStyled
          title="Menu"
          placement="left"
          onClose={() => setVisible(false)}
          open={visible}
          ismobile={md}
        >
          {menu}
        </DrawerStyled>
        {lonely ? (
          <Button
            size="large"
            icon={<ArrowLeftOutlined />}
            onClick={() => history.back()}
          />
        ) : (
          <Button
            size="large"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
        <Col style={{ display: "flex", gap: "10px" }}>
          {ListNotifications && ListNotifications.length > 0 ? (
            <Dropdown
              trigger={["click"]}
              arrow={{ pointAtCenter: true }}
              overlay={
                <Menu>
                  {ListNotifications &&
                    ListNotifications.slice(0, 3).map((item: any) => (
                      <Menu.Item key={item.id}>
                        <div>
                          {item.read ? (
                            <CheckCircleOutlined
                              style={{ marginRight: "8px" }}
                            />
                          ) : (
                            <BellOutlined style={{ marginRight: "8px" }} />
                          )}
                          <Typography.Text
                            style={{ color: item.read ? "#777" : "#000" }}
                          >
                            {item.title}
                          </Typography.Text>
                        </div>
                      </Menu.Item>
                    ))}
                  {ListNotifications && ListNotifications?.length > 3 && (
                    <Menu.Item key="see-all" className="see-all-notifications">
                      <Link to="/notifications">
                        <UnorderedListOutlined style={{ marginRight: "8px" }} />
                        <Typography.Text strong>
                          Ver todas las notificaciones
                        </Typography.Text>
                      </Link>
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <Badge dot={allNotificationsRead && ListNotifications.length > 0}>
                <Button
                  type="text"
                  icon={<BellOutlined />}
                  shape="circle"
                  style={{ paddingTop: "7px" }}
                />
              </Badge>
            </Dropdown>
          ) : (
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu>
                  <Menu.Item key="empty">
                    <Empty description="No tienes notificaciones" />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button size="large" icon={<BellOutlined />}></Button>
            </Dropdown>
          )}
          <Tooltip title="Ir a inicio" placement="left">
            <Button
              size="large"
              icon={<HomeOutlined />}
              onClick={() => navigate("/dashboard")}
            ></Button>
          </Tooltip>
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            arrow={{ pointAtCenter: true }}
          >
            <Button size="large" icon={<UserOutlined />}></Button>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default Navbar;
