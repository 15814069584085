import { FC, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Card,
  Row,
  Col,
  Alert,
  Checkbox,
  message,
  List,
  DatePicker,
  Select,
} from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IPackageFormProps } from "src/core/interfaces";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CardToolsToggle from "src/components/CardToolsToggle";
import {
  CalendarOutlined,
  CheckCircleFilled,
  ClockCircleOutlined,
  CloseCircleOutlined,
  EuroCircleOutlined,
  OrderedListOutlined,
  PlayCircleOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import ListFiles from "src/modules/multimedia/components/ListFiles";
import {
  getFiles,
  getFilesByPackageId,
  getTotalDelay,
} from "src/services/files";
import moment, { Moment } from "moment";
import SortableItem from "./SortableItem";
import { IAdvertiser, IPackageInput, IZone } from "src/core/dtos";
import usePageLimit from "src/hooks/usePageLimit";
import { getAdvertisers } from "src/services/advertisers";
import { useSelector } from "react-redux";
import { getZones } from "src/services/zones";
import dayjs, { Dayjs } from "dayjs";
// import ListZonesPackage from "./ListZonesPackage";
const { RangePicker } = DatePicker;

import ListZonesPackageSelect from "./ListZonesPackageSelect";

dayjs.extend(customParseFormat);

const PackageForm: FC<IPackageFormProps> = ({
  onFinish,
  values: initialValues,
  isLoading,
  error,
  form,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state: any) => state.auth);
  // const [initialValues, setInitialValues] = useState(values);
  const [files, setFiles] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [totalZones, setTotalZones] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isLoadingZones, setIsLoadingZones] = useState(false);
  const [loadingAdvertisers, setLoadingAdvertisers] = useState(false);
  const [search, setSearch] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [tags, setTags] = useState<string[]>();
  const [checked, setChecked] = useState<CheckboxValueType[]>([]);
  const [checkedZones, setCheckedZones] = useState<CheckboxValueType[]>(
    initialValues.broadcast
      ? initialValues.broadcast.map((item) => item.zoneId)
      : []
  );
  const [totalDelay, setTotalDelay] = useState<number>(
    initialValues?.delay || 0
  );
  const [orderList, setOrderList] = useState(false);
  const [advertisers, setAdvertisers] = useState<IAdvertiser[]>([]);
  const [zones, setZones] = useState<IZone[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [allSelectedZones, setAllSelectedZones] = useState(false);
  const [numPasses, setNumPasses] = useState<number | null>(
    initialValues?.numPasses !== undefined ? initialValues?.numPasses : 0
  );
  const [budget, setBudget] = useState<number | null>(
    initialValues?.budget !== undefined ? initialValues?.budget : 0
  );
  const [numDays, setNumDays] = useState<number | null>(null);
  const [advertiserId, setAdvertiserId] = useState(user.advertiserId);
  const [selectedZones, setSelectedZones] = useState<number[]>([]);
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);

  useEffect(() => {
    if (dates[0] !== null && dates[1] !== null) return;
    if (
      initialValues &&
      !window.document.location.href.includes("campaign/new")
    ) {
      setDates([dayjs(initialValues.startDate), dayjs(initialValues.endDate)]);
      form.setFieldValue("dates", [
        dayjs(initialValues.startDate).add(1, "d"),
        dayjs(initialValues.endDate).add(1, "d"),
      ]);
    }
  }, [dates, initialValues]);

  useEffect(() => {
    if (numPasses !== null) {
      form.setFieldsValue({ budget: numPasses * 0.02 });
    }
  }, [numPasses]);

  useEffect(() => {
    if (dates[0] && dates[1]) {
      const diffInMs = dates[1].diff(dates[0], "millisecond");
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      setNumDays(Math.round(diffInDays));
    }
  }, [dates]);

  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();

  const calculateTotalDelay = (checkedValues: CheckboxValueType[]) => {
    let totalDelay = 0;
    checkedValues.forEach((value) => {
      const file = files.find((item: any) => item.id === value);
      if (file) {
        totalDelay += file.delay;
      }
    });
    return totalDelay;
  };

  const formattedTotalDelay = moment.utc(totalDelay * 1000).format("mm:ss");

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setFiles((prev) => {
        const activeIndex = prev.findIndex((i) => i.id == active.id);
        const overIndex = prev.findIndex((i) => i.id == over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const loadAdvertisers = async () => {
    setLoadingAdvertisers(true);

    const response: any = getAdvertisers([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
    ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setLoadingAdvertisers(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setLoadingAdvertisers(false);
      return;
    }

    setAdvertisers(data?.data);

    setLoadingAdvertisers(false);
  };

  const loadZones = async () => {
    setIsLoadingZones(true);

    const response: any = getZones([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
    ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoadingZones(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoadingZones(false);
      return;
    }

    let sortedData = [...data?.data];

    const filterCheck = sortedData
      .filter((item: any) => allSelectedZones || item.selected)
      .map((otem: any) => otem.id);

    setZones([...sortedData]);
    setTotalZones(data.total);
    setIsLoadingZones(false);
    setCheckedZones([...checkedZones, ...filterCheck]);
  };

  useEffect(() => {
    loadZones();
  }, [page, limit, search]);

  const loadFiles = async () => {
    const response: any = id
      ? await getFilesByPackageId(+id, [
          { key: "page", value: page },
          { key: "limit", value: limit },
          { key: "search", value: String(search) },
          { key: "tags", value: tags?.length ? String(tags?.toString()) : "" },
        ])
      : await getFiles([
          { key: "page", value: page },
          { key: "limit", value: limit },
          { key: "search", value: String(search) },
          { key: "tags", value: tags?.length ? String(tags?.toString()) : "" },
          { key: "advertiser", value: advertiserId || "" },
        ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    let sortedData = [...data?.data];
    if (data) {
      sortedData.sort((a, b) => {
        let orderA = a.displays && a.displays[0] ? a.displays[0].order : 0;
        let orderB = b.displays && b.displays[0] ? b.displays[0].order : 0;
        return orderA - orderB;
      });
    }

    const filterCheck = sortedData
      .filter((item: any) => allSelected || item.selected)
      .map((otem: any) => otem.id);
    setFiles([...sortedData]);
    setTotal(data?.total);
    setChecked([...checked, ...filterCheck]);
  };

  const loads = async () => {
    setLoading(true);
    await loadFiles();
    await loadAdvertisers();
    setLoading(false);
  };

  useEffect(() => {
    loads();
  }, [page, search, tags, advertiserId]);

  const loadTotalDelay = async () => {
    const response: any = await getTotalDelay();

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      return;
    }

    setTotalDelay(data?.data);
  };

  const disabledDate = (current: Dayjs) => {
    return current && current < moment().startOf("day");
  };

  const validateDates = (_: any, value: [Dayjs, Dayjs]) => {
    if (!value || value.length !== 2) {
      return Promise.reject(
        new Error("Por favor selecciona un rango de fechas")
      );
    }
    const [start, end] = value;
    if (end.isBefore(start)) {
      return Promise.reject(
        new Error("La fecha de salida no puede ser menor a la de entrada")
      );
    }
    return Promise.resolve();
  };

  const onSaveAssigns = async (
    values: IPackageInput & { dates: [Dayjs, Dayjs] }
  ) => {
    //
    const checkAll = allSelected ? [{ id: 0, order: 0 }] : [];
    const tempChecked: { id: number; order: number }[] = checkAll.concat(
      checked
        .sort((a, b) => {
          const indexa = files.findIndex((i: any) => i.id == a);
          const indexb = files.findIndex((i: any) => i.id == b);
          return indexa - indexb;
        })
        .map((item, i) => {
          return { id: +item, order: i + 1 };
        })
    );

    const currentDate = moment().format("YYYY-MM-DD");

    if (!dates[0] || !dates[1]) return;

    // console.log("dates: ", { values: values.dates, dates: dates });

    const new_startDate = values.dates[0].format("YYYY-MM-DD");
    const new_endDate = values.dates[1].format("YYYY-MM-DD");

    const tempStatus =
      user.role === "ADMIN"
        ? values.startDate === currentDate
          ? "ACTIVE"
          : "APPROVED"
        : "PENDING";
    const tempAdvertiserId =
      user.role === "ADMIN" ? values.advertiserId : user.advertiserId;

    const payload = {
      name: values.name,
      description: values.description,
      startDate: new_startDate,
      endDate: new_endDate,
      budget: Number(budget) || 0,
      advertiserId: tempAdvertiserId,
      status: tempStatus,
      numPasses: budget ? Number((budget / 0.02).toFixed(0)) : 0,
      filesId: tempChecked,
      zonesId: checkedZones.map(Number),
    };

    console.log(payload);
    onFinish(payload);
  };

  const updateDates = (dates: [Dayjs | null, Dayjs | null]) => {
    if (dates && dates[0] && dates[1]) {
      setDates(dates);
      form.setFieldsValue({ dates });
    }
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onSaveAssigns}>
        <SpaceStyled block={true} direction="vertical" size="middle">
          <Card>
            {error && <Alert type="error" message={error} showIcon />}
            <Row>
              <Col xs={24} md={24} lg={8} style={{ paddingRight: "7px" }}>
                <Form.Item
                  hasFeedback
                  name="name"
                  label="Nombre"
                  initialValue={initialValues.name}
                  rules={[
                    { required: true, message: "El nombre es requerido" },
                  ]}
                >
                  <Input placeholder="Ingrese el nombre" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={8} style={{ paddingRight: "7px" }}>
                <Form.Item
                  hasFeedback
                  name="description"
                  label="Descripción"
                  initialValue={initialValues.description}
                  rules={[
                    { required: true, message: "La descripción es requerida" },
                  ]}
                >
                  <Input placeholder="Ingrese la descripción" size="large" />
                </Form.Item>
              </Col>
              {user.role === "ADMIN" && (
                <Col xs={24} md={24} lg={8} style={{ paddingRight: "7px" }}>
                  <Form.Item
                    name="advertiserId"
                    label="Anunciante"
                    initialValue={initialValues.advertiserId}
                  >
                    <Select
                      size="large"
                      showSearch
                      placeholder="Seleccione un anunciante"
                      options={advertisers.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      onChange={(value) => setAdvertiserId(value)}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  name="dates"
                  label="Fecha de inicio - Fecha de finalizacion"
                  rules={[
                    {
                      required: true,
                      message: "Por favor selecciona un rango de fechas",
                    },
                    { validator: validateDates },
                  ]}
                >
                  {window.document.location.href.includes("campaign/new") ? (
                    <RangePicker
                      size="large"
                      name="dates"
                      format={"YYYY-MM-DD"}
                      style={{ width: "100%" }}
                      onChange={updateDates as any}
                      disabledDate={disabledDate}
                    />
                  ) : (
                    dates[0] != null &&
                    dates[1] != null && (
                      <RangePicker
                        size="large"
                        name="dates"
                        format={"YYYY-MM-DD"}
                        style={{ width: "100%" }}
                        onChange={updateDates as any}
                        disabledDate={disabledDate}
                      />
                    )
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={12} style={{ paddingRight: "7px" }}>
                <Form.Item
                  hasFeedback
                  name="budget"
                  label="Presupuesto"
                  initialValue={initialValues.budget || 0}
                  rules={[
                    {
                      required: true,
                      message: "Presupuesto es requerido",
                    },
                  ]}
                >
                  <Input
                    defaultValue={initialValues.budget || 0}
                    size="large"
                    placeholder="Ingrese el presupuesto"
                    suffix={<EuroCircleOutlined />}
                    onChange={(e: any) => setBudget(e.target.value)}
                    onKeyPress={(e: any) => {
                      if (!"1234567890.".includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />

                  {/* <InputNumber
                            min={0}
                            onChange={(value) => setNumPasses(value)}
                            size="large"
                            style={{ width: "100%" }}
                            onKeyPress={(e) => {
                              const charCode = e.which ? e.which : e.keyCode;
                              if (
                                charCode > 31 &&
                                (charCode < 48 || charCode > 57)
                              ) {
                                e.preventDefault();
                              }
                            }}
                          /> */}
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Card>
            <Row>
              <Col xs={24} md={24} lg={24} style={{ paddingRight: "7px" }}>
                <Card
                  title="Cantidad de pases en base al presupuesto"
                  bordered={false}
                  style={{ height: "100%" }}
                >
                  <Row>
                    <Col
                      xs={24}
                      md={12}
                      lg={6}
                      style={{
                        paddingRight: "7px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Form.Item label="Días de reproducción" hasFeedback>
                        <h1 style={{ marginRight: "10px" }}>
                          <CalendarOutlined /> {numDays ? numDays : 0}
                        </h1>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      lg={6}
                      style={{
                        paddingRight: "7px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Form.Item label="Duración por pase" hasFeedback>
                        <h1 style={{ marginRight: "10px" }}>
                          <ClockCircleOutlined /> 10 seg
                        </h1>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      lg={6}
                      style={{
                        paddingRight: "7px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Form.Item label="Monto por pase" hasFeedback>
                        <h1 style={{ marginRight: "10px" }}>
                          <EuroCircleOutlined /> {0.02}
                        </h1>
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      md={12}
                      lg={6}
                      style={{
                        paddingRight: "7px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Form.Item label="Total de pases" hasFeedback>
                        <h1 style={{ marginRight: "10px" }}>
                          <PlayCircleOutlined />{" "}
                          {budget ? (budget / 0.02).toFixed(0) : 0}
                        </h1>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <CardToolsToggle
              title="Seleccionar multimedia o multimedias"
              style={{ margin: "0px" }}
              placeholder="Buscar archivo..."
              /*   setTags={setTags}
              setSearch={setSearch} */
              tools={[
                {
                  type: "default",
                  tooltip: allSelected
                    ? "Quitar seleccion de los archivos"
                    : "Seleccionar todos los archivos",
                  icon: allSelected ? (
                    <CloseCircleOutlined />
                  ) : (
                    <CheckCircleFilled />
                  ),
                  title: allSelected ? `Quitar seleccion` : `Seleccionar todo`,
                  size: "large",
                  onClick: () => {
                    if (allSelected) {
                      setChecked([]);
                      setTotalDelay(calculateTotalDelay([]));
                    } else {
                      loadTotalDelay();
                      setChecked(
                        files.map((item: any) => {
                          return item.id;
                        })
                      );
                    }
                    setAllSelected(!allSelected);
                  },
                },
                {
                  type: "default",
                  tooltip: "Tiempo total de reproducción",
                  icon: <ClockCircleOutlined />,
                  title: `${formattedTotalDelay}`,
                  size: "large",
                },
                {
                  type: "dashed",
                  size: "large",
                  title: "",
                  tooltip: orderList
                    ? "Seleccionar archivos"
                    : "Ordenar archivos seleccionados",
                  icon: orderList ? (
                    <UnorderedListOutlined />
                  ) : (
                    <OrderedListOutlined />
                  ),
                  onClick: () => setOrderList(!orderList),
                },
              ]}
            />
            <div style={{ marginTop: "20px" }}>
              {orderList ? (
                <DndContext onDragEnd={onDragEnd}>
                  <Card style={{ overflowY: "auto", width: "100%" }}>
                    <SortableContext
                      items={files.map((i) => i.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      <List
                        loading={loading}
                        dataSource={files.filter((item) =>
                          checked.includes(item.id)
                        )}
                        renderItem={(item) => (
                          <SortableItem file={item} refresh={loads} isAssign />
                        )}
                      />
                    </SortableContext>
                  </Card>
                </DndContext>
              ) : (
                <Checkbox.Group
                  value={checked}
                  onChange={(checkedValues: CheckboxValueType[]) => {
                    setChecked(checkedValues);
                    setTotalDelay(calculateTotalDelay(checkedValues));
                  }}
                  style={{ width: "100%" }}
                >
                  <Card style={{ overflowY: "auto", width: "100%" }}>
                    <List
                      loading={loading}
                      dataSource={files}
                      renderItem={(item) => (
                        <ListFiles file={item} refresh={loads} isAssign />
                      )}
                    />
                    {files?.length < total && (
                      <Col
                        style={{
                          marginTop: "1em",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          type="primary"
                          loading={loading}
                          onClick={() => handlePageChange(page + 1)}
                        >
                          Cargar más
                        </Button>
                      </Col>
                    )}
                  </Card>
                </Checkbox.Group>
              )}
            </div>
          </Card>
          <Card style={{ marginTop: "20px" }}>
            <CardToolsToggle
              title="Seleccionar zona o zonas"
              style={{ margin: "0px" }}
              placeholder="Buscar zona..."
              tools={[
                {
                  type: "default",
                  tooltip: allSelectedZones
                    ? "Quitar seleccion de las zonas"
                    : "Seleccionar todos las zonas",
                  icon: allSelectedZones ? (
                    <CloseCircleOutlined />
                  ) : (
                    <CheckCircleFilled />
                  ),
                  title: allSelectedZones
                    ? `Quitar seleccion`
                    : `Seleccionar todo`,
                  size: "large",
                  onClick: () => {
                    if (allSelectedZones) {
                      setCheckedZones([]);
                    } else {
                      setCheckedZones(
                        zones.map((item: any) => {
                          return item.id;
                        })
                      );
                    }
                    setAllSelectedZones(!allSelectedZones);
                  },
                },
              ]}
            />
            <div style={{ marginTop: "20px" }}>
              <Checkbox.Group
                value={checkedZones}
                onChange={(checkedValues: CheckboxValueType[]) => {
                  setCheckedZones(checkedValues);
                }}
                style={{ width: "100%" }}
              >
                <Card style={{ overflowY: "auto", width: "100%" }}>
                  <List
                    loading={loading}
                    dataSource={zones}
                    renderItem={(item, index) => (
                      <ListZonesPackageSelect
                        key={index}
                        file={item}
                        refresh={loads}
                        isAssign
                      />
                    )}
                  />
                  {zones?.length < totalZones && (
                    <Col
                      style={{
                        marginTop: "1em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        loading={loading}
                        onClick={() => handlePageChange(page + 1)}
                      >
                        Cargar más
                      </Button>
                    </Col>
                  )}
                </Card>
              </Checkbox.Group>
            </div>
            <Row align={"middle"} justify={"end"}>
              <Form.Item
                style={{
                  marginRight: "7px",
                  minWidth: "140px",
                  marginTop: "15px",
                }}
              >
                <Button
                  type="default"
                  block
                  size="large"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Atrás
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  marginLeft: "7px",
                  minWidth: "140px",
                  marginTop: "15px",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={isLoading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Card>
        </SpaceStyled>
      </Form>
    </>
  );
};
export default PackageForm;
