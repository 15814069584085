import { Marker } from "@react-google-maps/api";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/context/AppProvider";

interface ICustomMarker {
  id: number;
  code: string;
  latitude: number;
  longitude: number;
  active: boolean;
  enable: boolean;
}

interface ILocation {
  latitude: number;
  longitude: number;
}

const CustomMarker = ({
  id,
  code,
  latitude,
  longitude,
  active,
  enable,
}: ICustomMarker) => {
  const { socket } = useContext(AppContext);
  const [status, setStatus] = useState({
    active,
    enable,
  });
  const [coordinates, setCoordinates] = useState<ILocation>({
    latitude,
    longitude,
  });

  useEffect(() => {
    if (!id) return;
    socket?.on(`update_map_${id}`, (location: ILocation) => {
      setStatus({ enable: true, active: true });
      setCoordinates({
        latitude: location.latitude,
        longitude: location.longitude,
      });
    });
    return () => {
      socket?.close();
    };
  }, [id]);

  return coordinates.latitude && coordinates.longitude ? (
    <Marker
      key={code}
      position={{
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      }}
      animation={
        status?.enable
          ? google.maps.Animation.BOUNCE
          : google.maps.Animation.DROP
      }
      title={`Mac: ${code}`}
      icon={{
        url: `http://maps.google.com/mapfiles/ms/icons/${
          !status?.enable ? "red" : !status?.active ? "blue" : "green"
        }-dot.png`,
        anchor: new google.maps.Point(16, 16),
        scaledSize: new google.maps.Size(32, 32),
        labelOrigin: new google.maps.Point(16, 32),
      }}
    />
  ) : (
    <></>
  );
};

export default CustomMarker;
