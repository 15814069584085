import { Spin } from 'antd';
import React, { ReactNode } from 'react';
import { Empty } from 'antd';


interface IConditional { 
    cond?:any; 
    children: ReactNode|any;
    value?:any;
}

interface IConditionalFather { 
    loading: boolean;
    cond?: any;
    children: ReactNode|any;
    loadingClass?: string;
    message?: string;
    value?:any;
}

export const isIterableArray=(array:any)=>Array.isArray(array)&&!!array.length;

export const PreloadState:React.FC<IConditionalFather> = ({ loading, cond, children, loadingClass, message }) =>
    loading?
        <div style={{display:"grid", gap:"12px", justifyContent:"center", alignContent:"center", width:"100%", height:"60vh" }}>
            <Spin className={`${loadingClass && loadingClass} ${!!message?'mb-3':''}`}/>
            {message&&<h6 className='text-center'>{message}</h6>}
        </div>
        :
        <>{children&&isIterableArray(children)?children.map((child:any)=>(child.props.value===cond||child.props.value===undefined)&&child):children}</>

export const Or = ({ value, cond, children }:IConditional) => children&&isIterableArray(children) ? children.map((child:any) => (child.props.value===cond||child.props.value===undefined) && child) : children;

export const If = ({ cond, children }:IConditional) => children&&(isIterableArray(children)?children.map((child:any)=>(cond.indexOf(child.props.value)!==-1)&&child):cond.indexOf(children.props.value)!==-1&&children);

export const Message: React.FC<{ image?: string; value?: any; message:string; children?:ReactNode }>=({value, message, image, children })=>
<div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%", height:"60vh" }}>
    <Empty image={image?image:undefined} key={value} description={<span>{message||"No hay datos para mostrar"}</span>}>{children&&children}</Empty>
</div>