import { Button, Col, List, message, Row, Tooltip } from "antd";
import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { EyeFilled, LinkOutlined, EditOutlined } from "@ant-design/icons";
import ModalInfoFile from "./ModalInfoFile";
import { deleteFile } from "src/services/files";
import type { MenuProps } from "antd";
import { copyToClipboard } from "src/components/utils";
import { IPackage, IPermission } from "src/core/dtos";
import { AppContext } from "src/context/AppProvider";

interface IFile {
  id: number;
  name: string;
  description: string;
  url: string;
  type: string;
  status: boolean;
  createdAt: string;
  updatedAt: string;
  advertiserId: number;
  advertiser: { id: number; name: string };
  broadcast?: {
    zoneId: number;
    packageId: number;
    zone: {
      id: number;
      name: string;
      description: string;
    };
  }[];
  tags: { id: number; name: string }[];
  delay: number;
  size: number;
}

const ListZonesPackage = ({
  file,
  refresh,
  isAssign,
}: {
  file: IPackage;
  refresh: (first?: boolean) => void;
  isAssign?: boolean;
}) => {
  const { socket } = useContext(AppContext);
  const { permissions } = useSelector((state: any) => state.auth);
  const permCamp = permissions.find(
    (item: IPermission) => item.module === "Packages"
  );
  const [modalIsOpen, setModalIsOpen] = useState<0 | 1 | 2>(0);
  const [modalEditFileIsOpen, setModalEditFileIsOpen] =
    useState<boolean>(false);
  const [hover, setHover] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onConfirmDelete = async (id: number) => {
    deleteFile(id)
      .then(() => {
        messageApi.success("Archivo eliminado correctamente");
        refresh(true);
      })
      .catch((error) => {
        messageApi.error("Error al eliminar el archivo");
      });
  };

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Ver detalles
        </a>
      ),
      icon: <EyeFilled />,
      disabled: false,
      onClick: () => {
        setModalIsOpen(1);
      },
    },
    {
      key: 5,
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Editar archivo
        </a>
      ),
      icon: <EditOutlined />,
      disabled: permCamp.isEdit,
      onClick: () => {
        setModalEditFileIsOpen(true);
      },
    },

    {
      key: 2,
      label: (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
          }}
          rel="noopener noreferrer"
        >
          Obtener vinculo
        </a>
      ),
      icon: <LinkOutlined />,
      disabled: false,
      onClick: () => {
        copyToClipboard(file.name);
        messageApi.success(`Copiado al portapapeles!`);
      },
    },

    { key: 6, type: "divider" },
  ];

  return (
    <>
      {contextHolder}
      <List.Item
        className="transited"
        onMouseEnter={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          background: hover ? "#f9f9f9" : "#fff",
        }}
      >
        <Row justify={"space-between"} align={"middle"}>
          <Row justify={"start"} align={"middle"}>
            {/*          {isAssign&&
                    <Col style={{ padding: "0px 20px 0px 0px" }} onMouseEnter={() => setHover(true)}>
                        <Checkbox value={file.id}/>
                    </Col>} */}
            {/*          <Col style={{ padding: "0px 20px 0px 0px" }} onMouseEnter={() => setHover(true)}>
                        {file.type.includes("image")&&<img alt="example" src={image_icon} style={{ width: "20px", filter: "saturate(0.5)" }}/>}
                        {file.type.includes("video")&&<img alt="example" src={video_icon} style={{ width: "20px", filter: "saturate(0.5)" }} />}
                    </Col> */}
            <Col onMouseEnter={() => setHover(true)}>
              <div>{file.name || "Untitled"}</div>
              <small>
                <strong style={{ paddingRight: "5px" }}>Descripción: </strong>
                <i>{file.description}</i>
              </small>
            </Col>
          </Row>
        </Row>
        <div>
          <Tooltip
            className="d-xs-none"
            title={"Vista previa"}
            placement="bottom"
          >
            <Button
              style={{ margin: "0px 5px" }}
              shape="circle"
              icon={<EyeFilled onClick={() => setModalIsOpen(2)} key="show" />}
              type="dashed"
              size="middle"
            />
          </Tooltip>
        </div>
      </List.Item>
      {modalIsOpen != 0 && (
        <ModalInfoFile
          file={file}
          isOpen={modalIsOpen}
          toggle={setModalIsOpen}
        />
      )}
    </>
  );
};

export default ListZonesPackage;
