import { useEffect, useState, useContext } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LockOutlined,
  UnlockOutlined,
  CloseOutlined,
  PushpinOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import { Button, message, Popconfirm, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import SpaceStyled from "src/components/styled/Space";
import { IDeviceTableProps } from "src/core/interfaces";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FC } from "react";
import usePageLimit from "src/hooks/usePageLimit";
import DeviceStatus from "./DeviceStatus";
import {
  deleteDevice,
  getDevices,
  getDevicesAlly,
  updateDevice,
  deleteAllyDevice,
} from "src/services/devices";
import { IDevice, IPermission } from "src/core/dtos";
import { AppContext } from "src/context/AppProvider";

const DevicesTable: FC<IDeviceTableProps> = ({
  search,
  activeEvent,
  showStatus,
  id,
  setDevicesToSocket,
  setEdit,
  setOpenModal,
  setOpenMap,
  setCountDevices,
}) => {
  const { socket } = useContext(AppContext);
  const { user, permissions } = useSelector((state: any) => state.auth);
  const permDev = permissions.find(
    (item: IPermission) => item.module === "Devices"
  );
  const permFile = permissions.find(
    (item: IPermission) => item.module === "Files"
  );
  const { state } = useLocation();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(true);
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [currentItems, setCurrentItems] = useState<IDevice[]>([]);
  const [total, setTotal] = useState(0);
  const [ally, setAlly] = useState<IDevice["ally"]>(state?.ally);
  const [filteredDevices, setFilteredDevices] = useState<IDevice[]>([]);

  const loadDevices = async () => {
    setIsLoading(true);
    setCurrentItems([]);
    const response: any = id
      ? getDevicesAlly(id, [
          { key: "page", value: page },
          { key: "limit", value: 2 },
          { key: "search", value: String(search) },
        ])
      : getDevices([
          { key: "page", value: page },
          { key: "limit", value: limit },
          { key: "search", value: String(search) },
        ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error("Ha ocurrido un error al cargar los datos");
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    let temp_devices: IDevice[] = data?.data.map(
      (item: IDevice, i: number) => ({
        ...item,
        key: i,
        ally: item.ally ? item.ally : false,
      })
    );

    if (showStatus === 1) {
      temp_devices = temp_devices.filter(
        (item: IDevice) => item.status === true
      );
    }
    if (showStatus === 2) {
      temp_devices = temp_devices.filter(
        (item: IDevice) => item.status === false
      );
    }
    if (showStatus === 3) {
      temp_devices = temp_devices.filter(
        (item: IDevice) => item.enable === false
      );
    }
    if (showStatus === 4) {
      temp_devices = temp_devices.filter(
        (item: IDevice) => item.enable === true
      );
    }

    setCurrentItems(temp_devices);
    setTotal(data?.total);
    setDevicesToSocket(
      temp_devices.filter((item) => item.socketId).map((item) => item.socketId)
    );

    setIsLoading(false);
  };

  useEffect(() => {
    loadDevices();
  }, [ally, activeEvent, page, limit, search, showStatus]);

  useEffect(() => {
    handlePageChange(1);
  }, [limit, search]);

  const tableColumns: ColumnsType<IDevice> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "80px",
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Rider",
      dataIndex: "ally",
      key: "ally",
      render: (_: any, item) => {
        if (!item.ally) return <span>Sin asignar</span>;
        return (
          <span datatype={`/riders/${item.ally?.id}`}>{item.ally?.name}</span>
        );
      },
      sorter: (a, b) => (a.ally?.name || "").localeCompare(b.ally?.name || ""),
      sortDirections: ["descend", "ascend"],
    },

    {
      title: "Ciudad",
      dataIndex: "city",
      key: "city",
      render: (_: any, item) => {
        if (!item.ally) return <span>Sin asignar</span>;
        return (
          <span datatype={`/riders/${item.ally?.id}`}>
            <strong>{item.ally?.city}</strong>
          </span>
        );
      },
      sorter: (a, b) => (a.ally?.city || "").localeCompare(b.ally?.city || ""),
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "Solvencia",
    //   dataIndex: "ally",
    //   key: "ally",
    //   render: (_: any, item) => {
    //     if (!item.ally) return <span>N/A</span>;
    //     if (item.ally?.solvency === SOLVENCY.INSOLVENT)
    //       return <span>Insolvente</span>;
    //     if (item.ally?.solvency === SOLVENCY.SOLVENT)
    //       return <span>Solvente</span>;
    //     if (item.ally?.solvency === SOLVENCY.PENDING)
    //       return <span>Pendiente</span>;
    //   },
    //   sortDirections: ["descend", "ascend"],
    // },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      sortDirections: ["descend", "ascend"],
      render: (_: any, item) => (
        <DeviceStatus initialStatus={item.status} code={item.code} />
      ),
    },
    {
      title: <UnlockOutlined />,
      dataIndex: "enable",
      key: "enable",
      width: "50px",
      sortDirections: ["descend", "ascend"],
      render: (_: any, item) => {
        if (item.enable)
          return (
            <Tooltip placement="left" title="Desbloqueado">
              <Tag color="success">
                <UnlockOutlined />
              </Tag>
            </Tooltip>
          );
        else
          return (
            <Tooltip placement="top" title="Bloqueado">
              <Popconfirm
                placement="left"
                okButtonProps={{ danger: true }}
                title={`¿Está seguro de desbloquear el dispositivo?`}
                onConfirm={() => onChangeEnable(item)}
              >
                <Tag style={{ cursor: "pointer" }} color="error">
                  <LockOutlined />
                </Tag>
              </Popconfirm>
            </Tooltip>
          );
      },
    },
    {
      title: "Opciones",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: "25%",
      align: "center",
      render: (_: any, item) => (
        <SpaceStyled justify="center" block={true}>
          {/* {id && permFile.isEdit && (
            <Tooltip placement="left" title="Añadir contenido multimedia">
              <Link to={`/devices/${item.id}/files`} state={item}>
                <Button
                  shape="circle"
                  icon={<PictureOutlined />}
                  ghost={true}
                  type="primary"
                  size="middle"
                />
              </Link>
            </Tooltip>
          )} */}
          <Tooltip placement="left" title="Ubicar dispositivo">
            <Button
              shape="circle"
              icon={<PushpinOutlined />}
              type="primary"
              size="middle"
              ghost={true}
              onClick={() => {
                setEdit(item.id);
                setOpenMap(true);
              }}
            />
          </Tooltip>
          {!id && permDev.isEdit && (
            <Tooltip placement="left" title="Editar dispositivo">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                type="primary"
                size="middle"
                onClick={() => {
                  setEdit(item.id);
                  setOpenModal(true);
                }}
              />
            </Tooltip>
          )}
          {item.ally && permDev.isEdit ? (
            <Tooltip placement="bottom" title="Retirar dispositivo del aliado">
              <Popconfirm
                title="¿Está seguro de retirar el dispositivo al aliado?"
                okButtonProps={{ danger: true }}
                onConfirm={() => onConfirmDelAsign(item)}
              >
                <Button
                  shape="circle"
                  icon={<CloseOutlined />}
                  type="dashed"
                  danger
                  size="middle"
                />
              </Popconfirm>
            </Tooltip>
          ) : (
            permDev.isDel && (
              <Tooltip placement="bottom" title="Eliminar dispositivo">
                <Popconfirm
                  title="¿Está seguro de eliminar el dispositivo?"
                  okButtonProps={{ danger: true }}
                  onConfirm={() => onConfirmDelete(item)}
                >
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                    type="primary"
                    danger
                    size="middle"
                  />
                </Popconfirm>
              </Tooltip>
            )
          )}
          {permDev.isEdit && (
            <Tooltip
              placement="bottom"
              title={
                item.enable ? "Bloquear dispositivo" : "Desbloquear dispositivo"
              }
            >
              <Popconfirm
                placement="left"
                okButtonProps={{ danger: true }}
                title={`¿Está seguro de ${
                  item.enable ? "bloquear" : "desbloquear"
                } el dispositivo? `}
                onConfirm={() => onChangeEnable(item)}
              >
                <Button
                  shape="circle"
                  icon={item.enable ? <LockOutlined /> : <UnlockOutlined />}
                  type={item.enable ? "primary" : "dashed"}
                  danger
                  size="middle"
                />
              </Popconfirm>
            </Tooltip>
          )}
          {item.anchored && permDev.isEdit && (
            <Tooltip placement="bottom" title="Desanclar dispositivo">
              <Popconfirm
                placement="left"
                okButtonProps={{ danger: true }}
                title="¿Está seguro de desanclar el dispositivo?"
                onConfirm={() => onChangeAnchored(item)}
              >
                <Button
                  shape="circle"
                  icon={<ApiOutlined />}
                  type="primary"
                  ghost
                  size="middle"
                />
              </Popconfirm>
            </Tooltip>
          )}
        </SpaceStyled>
      ),
      // render: (_: any, item) => (<>
      //   <Dropdown trigger={["click"]} placement="bottomRight" menu={{items:[
      //     ...id?[
      //       {
      //         key: "1",
      //         label: <Link to={`/devices/${item.id}/files`} state={item}>Añadir contenido multimedia</Link>,
      //         disabled: !(id&&permFile.isEdit),
      //         icon: <PictureOutlined/>
      //       }
      //     ]:[],
      //     {
      //       key: "2",
      //       label: <span>Ubicar dispositivo</span>,
      //       disabled: false,
      //       icon: <PushpinOutlined/>,
      //       onClick: () => {
      //         setEdit(item.id);
      //         setOpenMap(true);
      //       }
      //     },
      //     {
      //       key: "3",
      //       label: <span>Editar dispositivo</span>,
      //       disabled: !(!id&&permDev.isEdit),
      //       icon: <EditOutlined/>,
      //       onClick: () => {
      //         setEdit(item.id);
      //         setOpenModal(true);
      //       }
      //     },
      //     ...item.ally?[
      //       {
      //         key: "4",
      //         label: (
      //             <Popconfirm placement="left" title="¿Está seguro de retirar el dispositivo al aliado?" okButtonProps={{ danger: true }} onConfirm={() => onConfirmDelAsign(item)}>
      //               <span>Retirar dispositivo del aliado</span>
      //             </Popconfirm>
      //           ),
      //         disabled: !(permDev.isEdit),
      //         icon: <CloseOutlined/>
      //       }
      //     ]:[],
      //     {
      //       key: "5",
      //       label: (
      //           <Popconfirm
      //             placement="left"
      //             title={`¿Está seguro de ${item.enable ? "bloquear" : "desbloquear"} el dispositivo?`}
      //             okButtonProps={{ danger: true }}
      //             onConfirm={() => onChangeEnable(item)}
      //           >
      //             <span>{item.enable ? "Bloquear dispositivo" : "Desbloquear dispositivo"}</span>
      //           </Popconfirm>
      //         ),
      //       danger:item.enable,
      //       disabled: !permDev.isEdit,
      //       icon: item.enable ? <LockOutlined /> : <UnlockOutlined />,
      //     },
      //     {
      //       key: "6",
      //       label: (
      //           <Popconfirm
      //             placement="left"
      //             disabled={!permDev.isDel}
      //             title="¿Está seguro de eliminar el dispositivo?"
      //             okButtonProps={{ danger: true }}
      //             onConfirm={() => onConfirmDelete(item)}
      //           >
      //             <span>Eliminar dispositivo</span>
      //           </Popconfirm>
      //         ),
      //       danger:true,
      //       disabled: !permDev.isDel,
      //       icon: <DeleteOutlined/>,
      //     },
      //   ]}}
      //   >
      //     <Button
      //       size="large"
      //       type="dashed"
      //       shape="default"
      //       icon={<EllipsisOutlined key="ellipsis" />}
      //       style={{ boxShadow: "2px 2px 4px 0px #13091b57" }}
      //     />
      //   </Dropdown>
      //   </>
      // ),
    },
  ];

  const tableColumnsAlly: ColumnsType<IDevice> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      sortDirections: ["descend", "ascend"],
      render: (_: any, item) => (
        <DeviceStatus initialStatus={item.status} code={item.code} />
      ),
    },
    {
      title: "Opciones",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      render: (_: any, item) => (
        <SpaceStyled justify="center" block={true}>
          <Tooltip placement="left" title="Ubicar dispositivo">
            <Button
              shape="circle"
              icon={<PushpinOutlined />}
              type="dashed"
              size="middle"
              onClick={() => {
                setEdit(item.id);
                setOpenMap(true);
              }}
            />
          </Tooltip>
        </SpaceStyled>
      ),
    },
  ];

  const onConfirmDelete = async (item: IDevice) => {
    setIsLoading(true);

    const { data, status } = (await deleteDevice(item.id)) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error("Ha ocurrido un error al eliminar el dispositivo");
      setIsLoading(false);
      return;
    }

    if (data?.statusCode != 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al eliminar el dispositivo"
      );
      setIsLoading(false);
      return;
    }

    messageApi.success(
      data?.message || "Se ha eliminado exitosamente el dispositivo"
    );
    handlePageChange(1);
    loadDevices();
  };

  const onConfirmDelAsign = async (item: IDevice) => {
    setIsLoading(true);

    const { data, status } = (await deleteAllyDevice(item.id, {
      allyId: null,
    })) || { data: undefined, status: undefined };

    if (!status) {
      messageApi.error(
        "Ha ocurrido un error al remover aliado del dispositivo"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode != 200) {
      messageApi.error(
        "Ha ocurrido un error al remover aliado del dispositivo"
      );
      setIsLoading(false);
      return;
    }

    messageApi.success("Se ha actualizado el dispositivo correctamente");
    loadDevices();
  };

  const onChangeEnable = async (item: IDevice) => {
    setIsLoading(true);
    const enable = !Boolean(item.enable);
    const { data, status } = (await updateDevice(item.id, {
      enable: enable,
    })) || { data: undefined, status: undefined };

    if (!status) {
      messageApi.error(
        `Ha ocurrido un error al ${
          enable ? "desbloquear" : "bloquear"
        } dispositivo`
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode != 200) {
      messageApi.error(
        data?.message ||
          `Ha ocurrido un error al ${
            enable ? "desbloquear" : "bloquear"
          } dispositivo`
      );
      setIsLoading(false);
      return;
    }

    socket?.emit("update_status", {
      id: item.id,
      socketId: data.data.socketId,
      enable: enable,
    });

    messageApi.success(
      `Dispositivo ${enable ? "desbloqueado" : "bloqueado"} correctamente`
    );
    loadDevices();
  };

  const onChangeAnchored = async (item: IDevice) => {
    setIsLoading(true);
    const anchored = false;
    const { data, status } = (await updateDevice(item.id, {
      anchored,
      status: false,
    })) || { data: undefined, status: undefined };

    if (!status) {
      messageApi.error("Ha ocurrido un error al desanclar el dispositivo");
      setIsLoading(false);
      return;
    }

    if (data?.statusCode != 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al desanclar el dispositivo"
      );
      setIsLoading(false);
      return;
    }

    socket?.emit("logout_device", {
      id: item.id,
      socketId: data.data.socketId,
    });

    messageApi.success("Dispositivo desanclado correctamente");
    loadDevices();
  };

  return (
    <>
      {contextHolder}
      <Table
        columns={
          user?.role === "ALLY" || user?.role === "CUSTOMER"
            ? tableColumnsAlly
            : tableColumns
        }
        dataSource={currentItems}
        loading={isLoading}
        rowClassName="bg-white"
        pagination={{
          defaultCurrent: 1,
          current: page,
          pageSize: limit,
          defaultPageSize: 10,
          onChange(page, pageSize) {
            handlePageChange(page);
            handleLimitChange(pageSize);
          },
          showSizeChanger: true,
          total: total,
          pageSizeOptions: ["15", "25", "50", "100"],
        }}
        scroll={{ x: "calc(400px + 50%)" }}
      />
    </>
  );
};

export default DevicesTable;
