import NotFound from "src/components/404";
import { useSelector } from "react-redux";
import AdminDashboard from "./pages/AdminDash";
import CustomerDashboard from "./pages/CustomerDash";
import MetricsPage from "../metrics/pages/Metrics";

const PageDash = () => {
  const { user } = useSelector((state: any) => state.auth);

  if (user.role === "ADMIN") return (<AdminDashboard />)
  if (user.role === "CUSTOMER") return (<CustomerDashboard />)
  if (user.role === "ALLY" || user.role === "ADVERTISER") return (<MetricsPage />)
  else return (<NotFound text="Página en construcción"/>)
}
export default PageDash;