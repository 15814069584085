import { FilterOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Tooltip, Card, Typography, DatePicker, Select } from 'antd';
import React, { FC, useState } from 'react';
import locale from "antd/es/date-picker/locale/es_ES";

const SelectOptions = [{value: 'date', label: 'Rango de fechas'}, {value: 'month', label: 'Mes'}]

const CardToolsToggle: FC<{ title?: string, text?: string, filter?: boolean, setRangeDate?: (values: string[]) => any; }> = ({ title, text, filter, setRangeDate }) => {

  const [tempDate, setTempDate] = useState<string[]>([]);
  const [activeFilter, setActiveFilter] = useState<boolean>(false);
  const [tyRange, setTyRange] = useState('month');

  const handleFilter = () => {
    if (setRangeDate) {
      if (tempDate.length === 1) { 
        if (!tempDate[0].length) return setRangeDate(['','']); 
        const date = new Date(tempDate[0])
        const first = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1).toISOString()
        const last = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0, 23, 59, 59).toISOString()
        setRangeDate([first, last])
      }
      else setRangeDate(tempDate);
    }
  }

  return (
    <Card>
      <Row gutter={[10, 10]} justify={{ xs: "center", sm: "center", md: "space-between" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          {title && <Typography.Title level={4} style={{ margin: "0px" }}>{title}</Typography.Title>}
          {text && <Typography.Text type="secondary" style={{ margin: "0px" }}>{text}</Typography.Text>}
        </Col>
        <Col style={{ display: "flex", width: "auto", justifyContent: "end", alignItems: "center" }}>
            {filter && <Tooltip key={"Filter"} title={"Filtrar"}>
              <Button style={{ marginLeft: "5px", marginRight: "5px" }} type={activeFilter ? "primary" : "dashed"} onClick={() => setActiveFilter(!activeFilter)}>
                <FilterOutlined />
              </Button>
            </Tooltip>}
        </Col>
      </Row>
      {setRangeDate && activeFilter &&
        <Row gutter={[10, 10]} justify={"center"} style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col xs={24} md={24} lg={24}>
          <Select defaultValue="month" style={{ width: 170, marginRight: "5px" }} onChange={(value) => setTyRange(value)} options={SelectOptions} />
            {tyRange === 'date' ?
              <DatePicker.RangePicker locale={locale} showTime={{ format: "HH:mm" }} onChange={(value, dateString) => setTempDate(dateString)}/>
              : <DatePicker locale={locale} onChange={(value, dateString) => setTempDate([dateString])} picker="month" />
            }
            <Button type="primary" icon={<FilterOutlined />} style={{ marginLeft: "5px" }} onClick={handleFilter}>Filtrar</Button>
          </Col>
        </Row>}
    </Card>
  );
}

export default CardToolsToggle;