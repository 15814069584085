import { useState, useEffect } from "react";
import {
  AppstoreOutlined,
  DeleteOutlined,
  EditOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  TagsOutlined,
  CheckSquareOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  Popconfirm,
  Table,
  Tooltip,
  Tag,
  Checkbox,
  Modal,
  Card,
  Divider,
} from "antd";
import { ColumnsType } from "antd/es/table";
import SpaceStyled from "src/components/styled/Space";
import { IAlly, IPermission } from "src/core/dtos";
import { ITableProps } from "src/core/interfaces";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FC } from "react";
import usePageLimit from "src/hooks/usePageLimit";
import { getAllies, deleteAlly } from "src/services/allies";
import ModalChangeSolvency from "./ModalChangeSolvency";
import ModalChangeQR from "./ModalChangeQR";
import { SOLVENCY } from "src/core/enums";
import { CheckboxValueType } from "antd/es/checkbox/Group";

const AlliesTable: FC<ITableProps> = ({ search, tags }) => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state: any) => state.auth);
  const permAlly = permissions.find(
    (item: IPermission) => item.module === "Allies"
  );
  const permDevice = permissions.find(
    (item: IPermission) => item.module === "Devices"
  );
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [allies, setAllies] = useState<IAlly[]>([]);
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenQR, setIsOpenQR] = useState(false);
  const [ally, setAlly] = useState<IAlly>({
    id: 0,
    name: "",
    lastName: "",
    rif: "",
    typeEmployee: "",
    typeVehicle: "",
    idMac: "",
    city: "",
    companyName: "",
    address: "",
    nroPhone: "",
    nroPhone2: "",
    logo: "",
    tags: [],
    status: true,
    dueDate: 1,
    solvency: SOLVENCY.SOLVENT,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const loadAllies = async () => {
    setIsLoading(true);

    const response: any = getAllies([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
      { key: "tags", value: String(tags?.toString()) },
    ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    setAllies(data?.data);
    setTotal(data?.total);

    setIsLoading(false);
  };

  useEffect(() => {
    loadAllies();
  }, [page, limit, search, tags]);

  const alliesTableColumns: ColumnsType<IAlly> = [
    {
      title: "Nombres",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      render: (_: any, ally) =>
        ally.tags.length ? (
          <Tooltip
            title={ally.tags.map((item: { name: string }) => (
              <span
                onClick={() =>
                  navigator.clipboard
                    .writeText(String(item.name))
                    .then(() =>
                      messageApi.success("Tag copiado al portapapeles")
                    )
                    .catch((err) =>
                      messageApi.success(
                        "Error al copiar al portapapeles:",
                        err
                      )
                    )
                }
                className="tag"
                style={{ color: "#000" }}
              >
                <TagsOutlined
                  style={{ fontSize: "10px", marginRight: "3px" }}
                />
                {item.name}
              </span>
            ))}
          >
            <span style={{ cursor: "pointer" }}>
              <TagsOutlined style={{ fontSize: "10px", marginRight: "3px" }} />
              {ally.name}
            </span>
          </Tooltip>
        ) : (
          <span style={{ cursor: "pointer" }}>{ally.name}</span>
        ),
    },
    {
      title: "Apellidos",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => a.name.localeCompare(b.lastName),
      sortDirections: ["descend", "ascend"],
      render: (_: any, ally) =>
        ally.tags.length ? (
          <Tooltip
            title={ally.tags.map((item: { lastName: string }) => (
              <span
                onClick={() =>
                  navigator.clipboard
                    .writeText(String(item.lastName))
                    .then(() =>
                      messageApi.success("Tag copiado al portapapeles")
                    )
                    .catch((err) =>
                      messageApi.success(
                        "Error al copiar al portapapeles:",
                        err
                      )
                    )
                }
                className="tag"
                style={{ color: "#000" }}
              >
                <TagsOutlined
                  style={{ fontSize: "10px", marginRight: "3px" }}
                />
                {item.lastName}
              </span>
            ))}
          >
            <span style={{ cursor: "pointer" }}>
              <TagsOutlined style={{ fontSize: "10px", marginRight: "3px" }} />
              {ally.lastName}
            </span>
          </Tooltip>
        ) : (
          <span style={{ cursor: "pointer" }}>{ally.lastName}</span>
        ),
    },
    {
      title: "Dispositivo",
      dataIndex: "device",
      key: "device",
      sorter: (a, b) => a.nroPhone.localeCompare(b.nroPhone),
      sortDirections: ["descend", "ascend"],
      render: (_: any, ally) => (
        <span style={{ cursor: "pointer" }}>
          {ally.devices?.length ? ally.devices[0]?.code : "Sin asignar"}
        </span>
      ),
    },
    {
      title: "Teléfono",
      dataIndex: "nroPhone",
      key: "nroPhone",
      sorter: (a, b) => a.nroPhone.localeCompare(b.nroPhone),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Ciudad",
      dataIndex: "city",
      key: "city",
      sorter: (a, b) => a.nroPhone.localeCompare(b.nroPhone),
      sortDirections: ["descend", "ascend"],
    },

    // {
    //   title: "Solvencia",
    //   dataIndex: "solvency",
    //   key: "solvency",
    //   sorter: (a, b) => Number(b.solvency) - Number(a.solvency),
    //   sortDirections: ["descend", "ascend"],
    //   render: (solvency) =>
    //     solvency === "SOLVENT" ? (
    //       <Tag icon={<CheckCircleOutlined />} color="success">
    //         Solvente
    //       </Tag>
    //     ) : solvency === "PENDING" ? (
    //       <Tag icon={<MinusCircleOutlined />} color="default">
    //         Pendiente
    //       </Tag>
    //     ) : (
    //       <Tag icon={<MinusCircleOutlined />} color="error">
    //         Insolvente
    //       </Tag>
    //     ),
    // },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => Number(b.status) - Number(a.status),
      sortDirections: ["descend", "ascend"],
      render: (status) =>
        status ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Activo
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="default">
            Inactivo
          </Tag>
        ),
    },
    {
      title: "Opciones",
      dataIndex: "actions",
      key: "actions",
      width: "20px",
      align: "center",
      render: (_: any, ally) => (
        <SpaceStyled justify="center" block={true}>
          {permAlly.isEdit && (
            <>
              <Tooltip title="Editar rider">
                <Link to={`/riders/${ally.id}/edit`}>
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    type="primary"
                    size="middle"
                  />
                </Link>
              </Tooltip>
              {/* <Tooltip title="Actualizar solvencia">
                <Button
                  shape="circle"
                  icon={<DollarOutlined />}
                  type="primary"
                  size="middle"
                  onClick={() => {
                    setAlly(ally);
                    setIsOpen(true);
                  }}
                />
              </Tooltip> */}
              {/* <Tooltip title="Configurar código QR">
                <Button
                  shape="circle"
                  icon={<QrcodeOutlined />}
                  type="primary"
                  size="middle"
                  onClick={() => {
                    setAlly(ally);
                    setIsOpenQR(true);
                  }}
                />
              </Tooltip> */}
            </>
          )}
          {permDevice.isRead && (
            <Tooltip title="Métricas">
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`${ally.id}/metrics`, { state: ally });
                }}
              >
                <Button
                  shape="circle"
                  icon={<BarChartOutlined />}
                  type="dashed"
                  size="middle"
                />
              </Link>
            </Tooltip>
          )}
          {permDevice.isRead && (
            <Tooltip title="Dispositivos">
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`${ally.id}/devices`, { state: ally });
                }}
              >
                <Button
                  shape="circle"
                  icon={<AppstoreOutlined />}
                  type="dashed"
                  size="middle"
                />
              </Link>
            </Tooltip>
          )}
          {permAlly.isDel && (
            <Tooltip title="Eliminar rider">
              <Popconfirm
                title="¿Está seguro de eliminar el rider?"
                okButtonProps={{ danger: true }}
                onConfirm={() => onConfirmDelete(ally)}
              >
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  type="primary"
                  danger
                  size="middle"
                />
              </Popconfirm>
            </Tooltip>
          )}
        </SpaceStyled>
      ),
      //   render: (_: any, ally)=>(
      //     <Dropdown trigger={["click"]} placement="bottomRight" menu={{items:[
      //       {
      //         key: "1",
      //         label: <Link to={`/allies/${ally.id}/edit`}>Editar rider</Link>,
      //         disabled: !permAlly.isEdit,
      //         icon: <EditOutlined/>
      //       },
      //       {
      //         key: "2",
      //         label: <span>Actualizar solvencia</span>,
      //         disabled: !permAlly.isEdit,
      //         icon: <DollarOutlined/>,
      //         onClick: () => {
      //           setAlly(ally);
      //           setIsOpen(true);
      //         }
      //       },
      //       {
      //         key: "3",
      //         label: <span>Configurar código QR</span>,
      //         disabled: !permAlly.isEdit,
      //         icon: <QrcodeOutlined/>,
      //         onClick:() => {
      //           setAlly(ally);
      //           setIsOpenQR(true);
      //         }
      //       },
      //       {
      //         key: "4",
      //         label: <span>Dispositivos</span>,
      //         disabled: !permAlly.isRead,
      //         icon: <AppstoreOutlined/>,
      //         onClick:(e:any) => {
      //           navigate(`${ally.id}/devices`, { state: ally });
      //         }
      //       },
      //       {
      //         key: "5",
      //         label: (
      //           <Popconfirm disabled={!permAlly.isDel} placement="left" style={{zIndex:"10001"}} title="¿Está seguro de eliminar el rider?" okButtonProps={{ danger: true }} onConfirm={() => onConfirmDelete(ally)}>
      //             <span>Eliminar rider</span>
      //           </Popconfirm>
      //         ),
      //         disabled: !permAlly.isDel,
      //         icon: <DeleteOutlined/>,
      //         danger:true,
      //       }
      //     ]}}>
      //       <Button
      //         size="large"
      //         type="dashed"
      //         shape="default"
      //         icon={<EllipsisOutlined key="ellipsis" />}
      //         style={{ boxShadow: "2px 2px 4px 0px #13091b57" }}
      //       />
      //     </Dropdown>
      // )
    },
  ];

  const [selectedColumns, setSelectedColumns] = useState([
    "name",
    "lastName",
    "nroPhone",
    "status",
    "actions",
  ]);

  const filteredColumns = alliesTableColumns.filter((column) =>
    selectedColumns.includes(String(column.key))
  );

  const onConfirmDelete = async (ally: IAlly) => {
    const response: any = deleteAlly(ally.id);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al intentar eliminar aliado"
      );
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al intentar eliminar aliado"
      );
      return;
    }
    messageApi.success(data?.message || "Aliado eliminado exitosamente");
    handlePageChange(1);
    loadAllies();
  };

  const handleColumnChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedColumns(checkedValues.map((value) => value.toString()));
  };

  return (
    <>
      {contextHolder}

      <Modal
        title="Seleccione los campos a mostrar"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Card>
          <Checkbox.Group
            style={{
              marginBottom: "20px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "10px",
            }}
            value={selectedColumns}
            onChange={handleColumnChange}
          >
            {alliesTableColumns.map((col, index) => (
              <Checkbox
                key={index}
                value={
                  typeof col.key === "bigint"
                    ? col.key.toString()
                    : col.key ?? ""
                }
              >
                {col.title as string}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Card>
      </Modal>
      <Divider />
      <Button
        style={{ marginBottom: "15px", float: "right" }}
        type="primary"
        icon={<CheckSquareOutlined />}
        onClick={showModal}
      >
        Seleccionar campos a mostrar
      </Button>
      <Table
        columns={filteredColumns}
        dataSource={allies}
        loading={isLoading}
        rowClassName="bg-white"
        pagination={{
          defaultCurrent: 1,
          current: page,
          pageSize: limit,
          defaultPageSize: 10,
          onChange(page, pageSize) {
            handlePageChange(page);
            handleLimitChange(pageSize);
          },
          showSizeChanger: true,
          total: total,
          pageSizeOptions: ["15", "25", "50", "100"],
        }}
        scroll={{ x: "calc(400px + 50%)" }}
      />
      {isOpen && (
        <ModalChangeSolvency
          isOpen={isOpen}
          toggle={setIsOpen}
          ally={ally}
          refresh={loadAllies}
          messageApi={messageApi}
        />
      )}
      {isOpenQR && (
        <ModalChangeQR
          isOpen={isOpenQR}
          toggle={setIsOpenQR}
          ally={ally}
          refresh={loadAllies}
          messageApi={messageApi}
        />
      )}
    </>
  );
};

export default AlliesTable;
