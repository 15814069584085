import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { KEY_GOOGLE_MAPS } from "src/core/GLOBAL_VAR";
import { Spin, message } from "antd";
import { getDevices } from "src/services/devices";
import { IDevice } from "src/core/interfaces";
import CustomMarker from "./CustomMarker";

interface DeviceMapProps {
  devices: string[];
}

interface IDeviceMarker {
  [key: string]: {
    id: number;
    code: string;
    active: boolean;
    enable: boolean;
    latitude: number;
    longitude: number;
  };
}

const DeviceMap: React.FC<DeviceMapProps> = ({ devices }) => {
  // Mapa que lista los dispositivos y donde se encuentran en tiempo real
  const [messageApi, contextHolder] = message.useMessage();
  const [deviceData, setDeviceData] = useState<IDeviceMarker>({});
  const [loading, setLoading] = useState<boolean>(false);

  const loadDevices = async () => {
    setLoading(true);

    const response: any = getDevices([
      { key: "page", value: 1 },
      { key: "limit", value: 5000 },
    ]);

    const {
      data: { data, statusCode, message },
    } = (await response) || {
      data: [],
      statusCode: 402,
    };

    if (statusCode !== 200) {
      messageApi.error(message || "Ha ocurrido un error al cargar los datos");
      setLoading(false);
      return;
    }

    let tempDevices: IDeviceMarker = {};

    data?.map((device: IDevice) => {
      if (!device?.id) return;
      tempDevices[device.id] = {
        id: device.id,
        code: device.code,
        latitude: device.latitude,
        longitude: device.longitude,
        active: device.status,
        enable: device.enable,
      };
    });

    setDeviceData(tempDevices);
    setLoading(false);
  };

  const loads = async () => {
    setLoading(true);
    await loadDevices();
    setLoading(false);
  };

  useEffect(() => {
    loads();
  }, [devices]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: KEY_GOOGLE_MAPS,
  });

  return isLoaded && !loading ? (
    <>
      {contextHolder}
      <GoogleMap
        zoom={2}
        center={{
          lat: 40.4165,
          lng: -3.70256,
        }}
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
      >
        {Object.keys(deviceData)?.map((element: string, i: number) => (
          <CustomMarker
            key={i}
            code={deviceData[element].code}
            id={deviceData[element].id}
            active={deviceData[element].active}
            enable={deviceData[element].enable}
            latitude={deviceData[element].latitude}
            longitude={deviceData[element].longitude}
          />
        ))}
      </GoogleMap>
    </>
  ) : (
    <Spin
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
};

export default DeviceMap;
