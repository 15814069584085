import { PlusOutlined, MergeCellsOutlined } from "@ant-design/icons";
import { Col, Row, message } from "antd";
import SpaceStyled from "src/components/styled/Space";
import UsersTable from "src/modules/users/components/UsersTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Head from "src/components/Head";
import Card from "src/components/Card";
import CardToolsToggle from "src/components/CardToolsToggle";
import ModalClonePermissions from "src/modules/users/components/ModalClonePermissions";
import { IPermission } from "src/core/dtos";
import FilterUserType from "../components/FilterUserType";
import { UserType } from "src/core/interfaces";

const UsersPage = () => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state: any) => state.auth);
  const permUser = permissions.find(
    (item: IPermission) => item.module === "Users"
  );
  const [search, setSearch] = useState("");
  const [isOpenClone, setIsOpenClone] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const handleNewUser = () => navigate("new");
  const [type, setType] = useState<UserType>(undefined);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head bread={["Usuarios"]}>Gestión de usuarios</Head>
              <Card>
                <CardToolsToggle
                  placeholder="Buscar usuario..."
                  setSearch={setSearch}
                  bottomDecoration={
                    <FilterUserType type={type} setType={setType} />
                  }
                  tools={[
                    {
                      type: "primary",
                      tooltip: "Nuevo usuario",
                      title: "Nuevo usuario",
                      icon: <PlusOutlined />,
                      visibility: permUser.isCre,
                      onClick: handleNewUser,
                    },
                  ]}
                />
                <UsersTable search={search} type={type} />
              </Card>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
      {isOpenClone && (
        <ModalClonePermissions
          isOpen={isOpenClone}
          toggle={setIsOpenClone}
          messageApi={messageApi}
        />
      )}
    </>
  );
};

export default UsersPage;
