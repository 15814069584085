import { Drawer } from "antd";
import { IDrawerStyledProps } from "src/core/interfaces";
import styled from "styled-components";

const DrawerStyled = styled(Drawer)<IDrawerStyledProps>`
  background-color: #fff !important;

  .ant-drawer-title {
    color: rgba(77, 38, 111, 1); !important;
  }

  .ant-drawer-close {
    color: rgba(77, 38, 111, 1); !important;
  }

  .ant-drawer-content-wrapper {
    ${({ ismobile }) =>
      ismobile &&
      `
    width: 100% !important;
  `}
  }
  
`;

export default DrawerStyled;
