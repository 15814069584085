import { useEffect, useState, useContext } from "react";
import { Tag } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { AppContext } from "src/context/AppProvider";

export interface IDeviceStatus {
  initialStatus: boolean;
  code: string | number;
}

const DeviceStatus = ({ initialStatus, code }: IDeviceStatus) => {
  const { socket } = useContext(AppContext);
  const [status, setStatus] = useState(initialStatus);

  useEffect(() => {
    setStatus(initialStatus);
    socket?.on('is_anyone_active', () => {
      console.log("is_anyone_active");
      setStatus(false)
    });
    socket?.on(`${code}_is_active`, (data: any) => {
      console.log("is_active", data);
      setStatus(data.status)
    });
    return () => {
      socket?.off('is_anyone_active');
      socket?.off(`${code}_is_active`);
    };
  }, [code]);

  if (status)
    return (
      <Tag icon={<PlusCircleOutlined />} color="success">
        Activo
      </Tag>
    );
  return (
    <Tag icon={<MinusCircleOutlined />} color="default">
      Inactivo
    </Tag>
  );
};

export default DeviceStatus;
