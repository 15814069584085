import { useState } from "react";
import { Modal, Card } from "antd";
import { IPackage } from "src/core/dtos";
import SimpleMap from "./maps";

interface IModalInfo {
  isOpen: 0 | 1 | 2;
  toggle: (value: 0 | 1 | 2) => any;
  file: IPackage;
}

const ModalInfoFile = ({ isOpen, toggle, file }: IModalInfo) => {
  const [isLoading, setIsLoading] = useState(true);

  const closeModal = () => {
    toggle(0);
    setIsLoading(false);
  };

  const handleMarkerPosition = (lat: number, lng: number) => {
    setLatitud(lat);
    setLongitud(lng);
  };

  const [latitud, setLatitud] = useState<number>(
    file.zone ? file.zone.latitude : 0
  );
  const [longitud, setLongitud] = useState<number>(
    file.zone ? file.zone.longitude : 0
  );
  const [range, setRange] = useState<number>(file.zone ? file.zone.range : 0);

  const PagePreview = () => {
    return (
      <div style={{ marginTop: "30px" }}>
        <SimpleMap
          position={{ latitud, longitud }}
          onMarkerPosition={handleMarkerPosition}
          radius={range ?? 0}
        />
      </div>
    );
  };

  return (
    <Modal
      style={{ padding: "0px" }}
      getContainer={"div"}
      bodyStyle={{ background: isOpen == 1 ? "#fff" : "unset" }}
      title={isOpen == 1 ? `Informacion del archivo` : ""}
      open={isOpen != 0}
      width={isOpen == 1 ? 400 : undefined}
      onCancel={closeModal}
      footer={<></>}
    >
      <PagePreview />
    </Modal>
  );
};

export default ModalInfoFile;
