import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Tooltip, Typography } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IPermission } from "src/core/dtos"; 
import { useEffect, useState } from "react";
import Head from "src/components/Head";
import Card from "src/components/Card";
import CardToolsToggle from "src/components/CardToolsToggle";
import ZoneTable from "../components/ZoneTable";

const ZonePage = () => {
  const { permissions } = useSelector((state: any) => state.auth);
  const permZone = permissions.find((item: IPermission) => item.module === "Zones");
  const navigate = useNavigate();
  const [tempSearch, setTempSearch] = useState("");
  const [search, setSearch] = useState("");
  
  useEffect(() => {
    // cleaning search when input is empty
    if (!tempSearch) setSearch("");
  }, [tempSearch]);

  return (
    <SpaceStyled block={true} direction="vertical">
      <Row justify="center">
        <Col xs={24} md={22}>
          <SpaceStyled block={true} direction="vertical" size="large">
            <Head bread={[ "Zonas" ]}>Gestión de zonas</Head>
            <Card>
              <CardToolsToggle
                placeholder="Buscar zonas..."
                setSearch={setSearch}
                tools={[
                  {
                    type: "primary",
                    tooltip: "Crear una nueva zona",
                    title: "Crear zona",
                    icon: <PlusOutlined />,
                    visibility: permZone.isCre,
                    onClick: ()=>navigate("new"),
                  },
                ]}
              />
              <ZoneTable search={search} />
            </Card>
          </SpaceStyled>
        </Col>
      </Row>
    </SpaceStyled>
  );
};

export default ZonePage;
