import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { cleanAll } from 'src/redux/slices/auth.slice';

const InactivityHandler = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lastActivity, setLastActivity] = useState(Date.now());

  useEffect(() => {
    const handleActivity = () => setLastActivity(Date.now());

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    const interval = setInterval(() => {
      // if (Date.now() - lastActivity > 60000) {
      if (Date.now() - lastActivity > 3600000) {
        dispatch(cleanAll());
        navigate('/auth/login');
        
      }
    }, 60000);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearInterval(interval);
    };
  }, [lastActivity]);

  return null;
};

export default InactivityHandler;
