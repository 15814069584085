import axios from "axios";
import { BACKEND_URL } from "src/core/GLOBAL_VAR";

const instance = axios.create({
  baseURL: `${BACKEND_URL}/api/v1`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    // 'authorization': `Bearer ${JSON.parse(JSON.parse(localStorage.getItem('persist:root')||"").auth)?.token||""}`,
  },
});
export default instance;
