import React, { useEffect, useMemo, useState } from "react";
import { Modal, Table, Form, Select } from "antd";
import { getUsers } from "src/services/users";
import { clonePermissions } from "src/services/users";
import CardToolsToggle from "src/components/CardToolsToggle";
import usePageLimit from "src/hooks/usePageLimit";
import { ColumnsType } from "antd/es/table";
import { IUser } from "src/core/dtos";
import type { MessageInstance } from "antd/es/message/interface";

export interface IModalClonePermissions {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  messageApi: MessageInstance;
}

export interface IUserTable extends IUser {
  key: React.Key;
  checked: boolean;
}

const ModalClonePermissions = ({ isOpen, toggle, messageApi }: IModalClonePermissions) => {
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [search, setSearch] = useState("");
  const [currentItems, setCurrentItems] = useState<IUserTable[]>([]);
  const [users, setUser] = useState<IUserTable[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<IUserTable[]>([]);
  const [selectUser, setSelectUser] = useState(0);
  const [form] = Form.useForm();

  const loadUsers = async () => {
    setIsLoading(true);
    const { data } = await getUsers([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
      { key: "role", value: "ADMIN" },
    ]) || { data: undefined };

    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      setIsLoading(false);
      return;
    }
    setCurrentItems(data?.data?.map((item: IUser) => {
      return { ...item, key: item.id }
    }));
    setTotal(data?.total);
    setIsLoading(false)
  };

  const closeModal = () => {
    toggle(false);
    setCurrentItems([]);
    setSelectedRows([]);
    setIsLoading(false);
    setSelectUser(0);
    form.setFieldValue("user", 0)
  };

  const save = async () => {
    setIsLoading(true);
    const { data } = await clonePermissions(selectUser, {
      usersId: selectedRows.map((item) => { return item.id })
    }) || { data: undefined };

    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al guardar la información");
      setIsLoading(false);
      return;
    }

    messageApi.success(data?.message || "Se han clonado los permisos exitosamente");
    closeModal();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) loadUsers();
  }, [page, limit, search, isOpen]);

  const tableColumns: ColumnsType<IUserTable> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <>
      <Modal
        title={`Clonar permisos del usuario`}
        open={isOpen}
        confirmLoading={isLoading}
        width={720}
        onCancel={closeModal}
        onOk={save}
        okText="Guardar"
        cancelText="Cancelar"
        okButtonProps={{
          disabled:
            (selectedRows.length && selectUser !== 0) ? false : true
        }}
      >
        <Form.Item
          name="user"
          label="Usuario a clonar"
          hasFeedback
          rules={[{ required: true, message: "Debe seleccionar el usuario" }]}
        >
          <Select
            placeholder="Seleccione un usuario"
            options={currentItems.map(item => {
              return { value: item.id, label: item.name }
            })}
            onSelect={(value) => {
              setSelectUser(value)
              setSelectedRows(selectedRows.filter(item => item.id !== value))
            }}
          />
        </Form.Item>
        <CardToolsToggle
          placeholder="Buscar dispositivo..."
          setSearch={setSearch}
          tools={[]}
        />
        {isOpen && (
          <Table
            columns={tableColumns}
            dataSource={currentItems}
            loading={isLoading}
            rowSelection={{
              onChange: (
                selectedRowKeys: React.Key[],
                selectedRows: IUserTable[]
              ) => {
                setSelectedRows(selectedRows);
              },
              getCheckboxProps: (record: IUserTable) => ({
                disabled: record.id === selectUser
              })
            }}
            rowClassName="bg-white"
            pagination={{
              defaultCurrent: 1,
              current: page,
              pageSize: limit,
              defaultPageSize: 10,
              onChange(page, pageSize) {
                handlePageChange(page);
                handleLimitChange(pageSize);
              },
              showSizeChanger: true,
              total: total,
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default ModalClonePermissions;
