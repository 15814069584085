export enum THEME {
  LIGHT,
  DARK,
}

export enum LANGUAGE {
  SPANISH,
  ENGLISH,
}

export enum LAYOUT {
  DESKTOP,
  MOBILE,
}

export enum SOLVENCY {
  SOLVENT = "SOLVENT",
  INSOLVENT = "INSOLVENT",
  PENDING = "PENDING",
}

export enum MODULES {
  Users = "Users",
  Allies = "Allies",
  Advertisers = "Advertisers",
  Devices = "Devices",
  Files = "Files",
  Packages = "Packages",
  Zones = "Zones",
  Scheduling = "Scheduling",
  Settings = "Settings",
  Notifications = "Notifications",
}

export enum NotificationType {
  DEVICE_STATUS_CHANGED = "device_enabled_changed",
}