import { FormInstance } from "antd";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Message, Or, PreloadState } from "src/components/PreloadState";
import { IFile } from "src/core/dtos";

interface IPreviewMultimedia {
  file: File | IFile;
  video_ref: React.RefObject<HTMLVideoElement>;
  form: FormInstance<any>;
}

const PreviewMultimedia = ({ file, video_ref, form }: IPreviewMultimedia) => {
  // Componente que rendeiza el preview de los archivos al subirlos
  const [loading, setLoading] = useState<boolean>(true);
  const [source, setSource] = useState<string>();

  useEffect(() => {
    if (!file) return;
    if ((file as File).arrayBuffer) {
      setSource(URL.createObjectURL(file as File));
    } else {
      setSource((file as IFile).url);
    }
  }, [file]);

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1234);
  }, []);

  return (
    <PreloadState loading={loading} cond={Boolean(source)}>
      <Or value={true} cond={String(file.type).includes("image")}>
        <Or value={true}>
          <div style={{ height: "100%" }}>
            <img
              src={source}
              width="100%"
              height="100%"
              alt="Un momento..."
              style={{ border: "none" }}
            />
          </div>
        </Or>
        <Or value={false}>
          <div
            style={{
              display: "flex",
              marginTop: "30px",
              justifyContent: "center",
            }}
          >
            <video
              loop
              width="100%"
              src={source}
              ref={video_ref}
              height={"420px"}
              className="transited"
              style={{ border: "none", background: "#000000" }}
              onPlay={() => {
                if (video_ref.current) {
                  video_ref.current.volume = 0.1;
                }
              }}
              onMouseOut={() => {
                video_ref.current && video_ref.current.pause();
              }}
              onMouseOver={() => {
                video_ref.current && video_ref.current.play();
              }}
              onEnded={() => {
                video_ref.current && video_ref.current.play();
              }}
            />
          </div>
        </Or>
      </Or>
      <Message value={false} message="No se pudo mostrar el archivo" />
    </PreloadState>
  );

  if (String(file.type).includes("image")) {
    return (
      <div style={{ height: "100%" }}>
        <img
          src={source}
          width="100%"
          height="100%"
          alt="Un momento..."
          style={{ border: "none" }}
        />
      </div>
    );
  }
  if (String(file.type).includes("video")) {
    return (
      <div
        style={{
          marginTop: "30px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <video
          ref={video_ref}
          src={source}
          loop
          width="100%"
          height={"420px"}
          style={{ border: "none", background: "#000000" }}
          className="transited"
          onPlay={() => {
            if (video_ref.current) {
              video_ref.current.volume = 0.1;
            }
          }}
          onMouseOut={() => {
            if (video_ref.current) video_ref.current.pause();
          }}
          onMouseOver={() => {
            if (video_ref.current) video_ref.current.play();
          }}
          onEnded={() => {
            if (video_ref.current) video_ref.current.play();
          }}
        />
      </div>
    );
  }
  return <></>;
};

export default React.memo(PreviewMultimedia);
