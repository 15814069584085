import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Input, Switch } from "antd";
import { updateQR } from "src/services/allies";
import { MessageInstance } from "antd/es/message/interface"

export interface IModalChangeQR {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  ally: any;
  refresh: () => void;
  messageApi: MessageInstance;
}

const ModalChangeQR = ({ isOpen, toggle, ally, refresh, messageApi }: IModalChangeQR) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState({ value: false, label: "Activar" });
  const [form] = Form.useForm();

  useEffect(() => {
    if (ally) form?.setFieldsValue({ url: ally.url, floatButton: ally.floatButton });
  }, [ally])

  const closeModal = () => {
    toggle(false);
    form?.setFieldsValue({ url: "", floatButton: false });
    setIsLoading(false);
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    const { data = undefined } = await updateQR(ally?.id, values);

    if (data?.statusCode !== 200) {
      return messageApi.error(data?.message || "Ha ocurrido un error al actualizar");
    }

    messageApi.success(data?.message || "Se ha actualizado exitosamente");
    refresh();
    setIsLoading(false);
    closeModal();
  };

  return (
    <Modal
      title={`Configuración de código QR`}
      centered
      open={isOpen}
      width={500}
      onCancel={() => closeModal()}
      footer={<></>}
    >
      <Form
        form={form}
        name="QR"
        layout="horizontal"
        onFinish={onFinish}
      >
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Form.Item label={`${checked.label} botón flotante`} name="floatButton" valuePropName="checked" >
            <Switch onChange={(value) => setChecked({ value, label: value ? "Desactivar" : "Activar" })} />
          </Form.Item>
          <Form.Item
            label="URL"
            name="url"
            rules={[
              { required: checked.value, message: "Debe ingresar el enlace" }
            ]}
          >
            <Input placeholder="Ingrese el enlace" />
          </Form.Item>
        </div>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          <Button
            onClick={() => closeModal()}
            type="default"
            htmlType="button"
            style={{ marginRight: 15 }}
          >
            Cancelar
          </Button>
          <Form.Item shouldUpdate>
            <Button loading={isLoading} type="primary" htmlType="submit">
              {isLoading ? "Un momento" : "Guardar"}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalChangeQR;
