import { FC } from "react";
import { Button, Result, Grid } from "antd";
import { useNavigate } from "react-router-dom";
import { AuthWrapperColStyled } from "src/components/layout/authLayout/AuthWrapperStyled";

type To =
  | number
  | string
  | Partial<{ pathname: string; search: string; hash: string }>;

const FourOFour: FC<{
  text: string;
  route?: any;
  textButton?: string;
}> = ({ text, route, textButton }) => {
  const navigate = useNavigate();
  const { md } = Grid.useBreakpoint();

  return (
    <AuthWrapperColStyled span={24} ismobile={!md}>
      <Result
        status="404"
        title="404"
        subTitle={text}
        extra={textButton&&<Button type="primary" onClick={() => navigate(route||"/")}>{textButton}</Button>}
      />
    </AuthWrapperColStyled>
  );
};

export default FourOFour;
