import { Grid } from "antd";
import LoginFillerComponent from "../components/LoginFillerComponent";
import Page from "src/components/layout/Page";
import AuthLayout from "src/layouts/AuthLayout";
import { AuthWrapperColStyled } from "src/components/layout/authLayout/AuthWrapperStyled";
import UpdatePasswordForm from "../components/UpdatePasswordForm";

const UpdatePassword = () => {
  const { md } = Grid.useBreakpoint();

  return (
    <Page title="Nueva contraseña | App4Riders">
      <AuthLayout>
        <AuthWrapperColStyled
          xs={{ order: 2, span: 24 }}
          md={{ order: 1, span: 12 }}
          ismobile={!md}
        >
          <LoginFillerComponent />
        </AuthWrapperColStyled>
        <AuthWrapperColStyled
          xs={{ order: 1, span: 24 }}
          md={{ order: 2, span: 12 }}
          ismobile={!md}
        >
          <UpdatePasswordForm />
        </AuthWrapperColStyled>
      </AuthLayout>
    </Page>
  );
};

export default UpdatePassword;
