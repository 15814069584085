import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Card, Row, Col, Alert, message, Tooltip } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IZoneFormProps } from "src/core/interfaces";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { IZoneInput } from "src/core/dtos";
import usePageLimit from "src/hooks/usePageLimit";
import SimpleMap from "./maps";
import PackagesSelect from "./PackagesSelect";

dayjs.extend(customParseFormat);

const transformRadio = (value: number, unit: 'km' | 'mt') => {
  if (isNaN(value)) return 0;
  const result = unit === 'mt' ? value * 1000 : unit === 'km' ? value / 1000 : value;
  return result
}

const ZoneForm: FC<IZoneFormProps> = ({
  onFinish,
  values,
  isLoading,
  error,
  form,
}) => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<IZoneInput>(values);
  const [files, setFiles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [checked, setChecked] = useState<CheckboxValueType[]>([]);
  const [latitud, setLatitud] = useState<number>(values.latitude ?? 0);
  const [longitud, setLongitud] = useState<number>(values.longitude ?? 0);
  const [range, setRange] = useState<number>(transformRadio(values.range, 'km') ?? 0);
  const [allSelected, setAllSelected] = useState(false);

  const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setRange(isNaN(value) ? 0 : value);
  };

  const onSaveAssigns = async (values: IZoneInput) => {
    const checkAll = allSelected ? [{ id: 0, order: 0 }] : [];
    const tempChecked: { id: number; order: number }[] = checkAll.concat(
      checked
        .sort((a, b) => {
          const indexa = files.findIndex((i: any) => i.id == a);
          const indexb = files.findIndex((i: any) => i.id == b);
          return indexa - indexb;
        })
        .map((item, i) => {
          return { id: +item, order: i + 1 };
        })
    );

    const payload = {
      name: values.name,
      description: values.description,
      longitude: longitud,
      latitude: latitud,
      range: transformRadio(values.range, 'mt'),
      packagesId: tempChecked,
    };
    onFinish(payload);
  };

  const handleMarkerPosition = (lat: number, lng: number) => {
    setLatitud(lat);
    setLongitud(lng);
    // Puedes realizar cualquier otra operación con las propiedades
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => onSaveAssigns({ ...values })}
      >
        <SpaceStyled block={true} direction="vertical" size="middle">
          <Card>
            {error && <Alert type="error" message={error} showIcon />}
            <Row>
              <Col xs={24} md={24} lg={8} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Nombre"
                  name="name"
                  initialValue={initialValues.name}
                  rules={[
                    { required: true, message: "El nombre es requerido" },
                  ]}
                  hasFeedback
                >
                  <Input placeholder="Ingrese el nombre" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={8} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Descripción"
                  name="description"
                  initialValue={initialValues.description}
                  rules={[
                    { required: true, message: "La descripción es requerida" },
                  ]}
                  hasFeedback
                >
                  <Input placeholder="Ingrese la descripción" size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={8} style={{ paddingRight: "7px" }}>
                <Form.Item
                  label="Radio"
                  name="range"
                  initialValue={transformRadio(initialValues.range, 'km')}
                  rules={[{ required: true, message: "El radio es requerido" }]}
                  hasFeedback
                >
                  <Input
                    placeholder="Ingrese el radio"
                    size="large"
                    onChange={handleRangeChange}
                    suffix={
                      <Tooltip title="Radio expresado en kilómetros">
                        <span style={{ color: 'rgba(0,0,0,.45)' }}>KM</span>
                      </Tooltip>
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Card>
              <SimpleMap
                position={{ latitud, longitud }}
                onMarkerPosition={handleMarkerPosition}
                radius={transformRadio(range, 'mt') ?? 0}
              />
            </Card>
            <PackagesSelect
              delay={values.delay || 0}
              checked={checked}
              setChecked={setChecked}
              messageApi={messageApi}
              setAllSelected={setAllSelected}
              allSelected={allSelected}
            />
            <Row align={"middle"} justify={"end"}>
              <Form.Item
                style={{
                  marginRight: "7px",
                  minWidth: "140px",
                  marginTop: "15px",
                }}
              >
                <Button
                  type="default"
                  block
                  size="large"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Atrás
                </Button>
              </Form.Item>
              <Form.Item
                style={{
                  marginLeft: "7px",
                  minWidth: "140px",
                  marginTop: "15px",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size="large"
                  loading={isLoading}
                >
                  Guardar
                </Button>
              </Form.Item>
            </Row>
          </Card>
        </SpaceStyled>
      </Form>
    </>
  );
};
export default ZoneForm;
