import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, message, Spin, Typography } from "antd";
import SpaceStyled from "src/components/styled/Space";
import ProfileForm from "src/modules/users/components/ProfileForm";
import Head from "src/components/Head";
import useAuth from "src/hooks/useAuth";
import { useSelector } from "react-redux";
import ProfileAdvertiserForm from "../components/ProfileAdvertiserForm";

const ProfilePage = () => {
  const { id } = useParams();
  /*   const { user } = useAuth(); */
  const { user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  console.log(user);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={18}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Typography.Title level={2} style={{ margin: "0px" }}>
                Perfil
              </Typography.Title>
              <Spin spinning={isLoading}>
                {!isLoading &&
                  (user?.role === "ADVERTISER" ? (
                    <ProfileAdvertiserForm />
                  ) : (
                    <ProfileForm />
                  ))}
              </Spin>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default ProfilePage;
