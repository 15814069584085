import { Button, Col, Row, Tooltip, Typography } from 'antd';
import React from 'react';
import Bread from './Breadcrumb';
import { topics } from 'src/core/topics';

interface IBread {
    children: any;
    level?: 2|5|1|3|4|undefined;
    tools?: object[];
    bread?: string[]|{ title: string; path?: string; Icon?: any; }[];
    icon?: JSX.Element;
} 

const Head = ({icon, children, level=2, tools, bread = [] }:IBread) => {
    const breadcrumb = bread.length>0?bread:String(window.location.href).split("/").slice(3)
    return (
        <Row gutter={[10, 10]} justify="space-between">
                <Col lg={"auto"}>
                    <Typography.Title level={level} style={{ margin: "0px" }}>
                        {icon||<span style={{marginRight:"10px"}}>
                            {topics.filter(item=>window.location.href.includes(item.path))[0]?.icon}
                        </span>}
                        {children&&children}
                    </Typography.Title>
                    <Bread items={breadcrumb} />
                </Col>
                {tools&&tools.length>0&&
                <div style={{ display: "flex", width: "auto" }}>
                    {tools.map((tool:any, i:number) => (
                        <Tooltip key={i} title={tool.tooltip}>
                            <Button
                                style={{marginLeft:"10px"}}
                                block
                                size="middle"
                                type={tool.type||"primary"}
                                icon={tool.title?tool.icon||undefined:undefined}
                                onClick={tool.onClick||(()=>{})}
                                key={i}
                            >
                                {tool.title?tool.title:tool.icon?tool.icon:"button"}
                            </Button>
                        </Tooltip>
                    ))}
                </div>}
            </Row>
    );
    return (
        <Typography.Title level={level} style={{ margin: "0px" }}>
            {children}
        </Typography.Title>
    );
}

export default Head;