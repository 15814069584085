import { Helmet } from "react-helmet";
import React, { useState } from "react";
import { Layout, theme, Grid } from "antd";
import Navbar from "src/components/layout/Sidebar";

const { Content } = Layout;

interface IApp {
  children: JSX.Element;
  title: string;
}

const MapLayout: React.FC<IApp> = ({ children, title }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
      <Helmet>
        <title>{title} | App4Riders</title>
      </Helmet>
      <Layout style={{ height: "100vh" }}>
        <Layout>
          <Navbar lonely collapsed={false} setCollapsed={() => {}} />
          <Content style={{ overflowY: "auto", background: colorBgContainer }}>
            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
export default MapLayout;
