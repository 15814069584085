import React, { ChangeEvent, useState } from "react";
import { Input } from "antd";
import Search from "antd/es/input/Search";
const MacAddressInput = ({
  onChange,
  loading,
  initialValue,
}: {
  onChange: (val: string) => void;
  loading: boolean;
  initialValue: string;
}) => {
  const [macAddress, setMacAddress] = useState<string>(initialValue || "");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedMac: string =
      value
        ?.replace(/[^0-9A-Fa-f]/g, "")
        ?.match(/.{1,2}/g)
        ?.join(":") || "";

    if (formattedMac.length >= 18) {
      return event.preventDefault();
    }

    setMacAddress(String(formattedMac).toUpperCase());
    onChange(String(formattedMac).toUpperCase());
  };

  return (
    <Input
      type="search"
      disabled={loading}
      addonBefore="MAC"
      placeholder="Escribe una dirección MAC"
      value={initialValue || macAddress}
      onChange={handleInputChange}
    />
  );
};

export default MacAddressInput;
