import React, { useEffect, useMemo, useState } from "react";
import { Modal, message, Table } from "antd";
import usePageLimit from "src/hooks/usePageLimit";
import { ColumnsType } from "antd/es/table";
import { IZone } from "src/core/dtos";
import { countPassesZones } from "src/services/passes";

export interface IModalPassesByZone {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  packages: {
    id: number;
    name: string;
  };
}

const ModalPassesByZone = ({
  isOpen,
  toggle,
  packages,
}: IModalPassesByZone) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [search, setSearch] = useState("");
  const [currentItems, setCurrentItems] = useState<IZone[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<IZone[]>([]);
  const [total, setTotal] = useState(0);

  const loadPasses = () => {
    setIsLoading(true);
    countPassesZones([
      { key: "page", value: page },
      { key: "limit", value: limit },
      // { key: "search", value: String(search) },
      { key: "Package", value: packages.id },
    ])
      .then(({ data }) => {
        if (data.statusCode === 200) {
          setCurrentItems(data.data.map((item: IZone) => {
            return { ...item, key: item.id }
          }));
          setTotal(data.data.length ? data.total : 0)
        }
      })
      .catch((error) => {
        messageApi.error("Ha ocurrido un error al cargar los datos");
      })
      .finally(() => setIsLoading(false))
  };

  const closeModal = () => {
    toggle(false);
    setCurrentItems([]);
    setSelectedRows([]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) loadPasses();
  }, [packages, isOpen, search]);

  const paginatedFiltered = useMemo(() => {
    return currentItems.slice((page - 1) * limit, page * limit)
  }, [page, limit, currentItems]);

  const tableColumns: ColumnsType<IZone> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: "10%",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Total de pases",
      dataIndex: "countTotal",
      key: "countTotal",
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <Modal
      title={`Pases por zonas`}
      open={isOpen}
      width={720}
      onCancel={closeModal}
      footer={<div></div>}
    >
      {/* <CardToolsToggle
        placeholder="Buscar dispositivo..."
        setSearch={setSearch}
        tools={
          selectedRows.length < 1
            ? []
            : [
              {
                type: "primary",
                tooltip: "Asignar dispositivos",
                title: "Asignar dispositivos",
                icon: <PlusOutlined />,
                onClick: () => addDevicesToClient(),
              },
            ]
        }
      /> */}
      {isOpen && (
        <Table
          columns={tableColumns}
          dataSource={paginatedFiltered}
          loading={isLoading}
          rowClassName="bg-white"
          pagination={{
            defaultCurrent: 1,
            current: page,
            pageSize: limit,
            defaultPageSize: 10,
            onChange(page, pageSize) {
              handlePageChange(page);
              handleLimitChange(pageSize);
            },
            showSizeChanger: true,
            total: paginatedFiltered.length,
          }}
        />
      )}
    </Modal>
  );
};

export default ModalPassesByZone;
