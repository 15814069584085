import { Col } from "antd";
import { LoginFillerComponentStyled } from "./LoginFormStyled";
import logo from "src/assets/images/logo.jpg";
const LoginFillerComponent = () => {
  return (
    <Col style={{ background: "#79ac99", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "3rem" }}>
      <img src={logo} alt="." width={"80%"} />
    </Col>
  );
};
export default LoginFillerComponent;
