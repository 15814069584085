import { Button, Col, List, Row, Tooltip } from "antd";
import React, { useState } from "react";
import { EyeFilled, MenuOutlined } from "@ant-design/icons";
import ModalInfoFile from "./ModalInfoFile";
import moment from "moment";
import image_icon from "./../../../assets/icons/image-file.png";
import video_icon from "./../../../assets/icons/video-file.png";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const ListFiles = ({ file, refresh, isAssign }: { file: any, refresh: () => void, isAssign?: boolean }) => {
    
    const [modalIsOpen, setModalIsOpen] = useState<0 | 1 | 2>(0);
    const [hover, setHover] = useState(false);
    const {
        attributes,
        listeners,
        setActivatorNodeRef,
        setNodeRef,
        transform,
        transition,
        isDragging,
      } = useSortable({
        id: file.id,
      });

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
          /translate3d\(([^,]+),/,
          'translate3d(0,',
        ),
        transition,
        ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
      };

    return (<>
        <List.Item 
            id={file.id}
            className="transited"
            onMouseEnter={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            ref={setNodeRef} style={style} {...attributes}
        >
            <Row justify={"space-between"} align={"middle"}>
                <Row justify={"start"} align={"middle"}>
                    <Col style={{ padding: "0px 20px 0px 0px" }} onMouseEnter={() => setHover(true)}>
                    <MenuOutlined
                        ref={setActivatorNodeRef}
                        style={{ touchAction: 'none', cursor: 'move' }}
                        {...listeners}
                    />  
                    </Col>
                    <Col style={{ padding: "0px 20px 0px 0px" }} onMouseEnter={() => setHover(true)}>
                        {file.type.includes("image") ?
                            <img alt="example" src={image_icon} style={{ width: "20px", filter: "saturate(0.5)" }} />
                            :
                            <img alt="example" src={video_icon} style={{ width: "20px", filter: "saturate(0.5)" }} />
                        }
                    </Col>
                    <Col onMouseEnter={() => setHover(true)} >
                        <div>{file.name || "Untitled"}</div>
                        <small>
                            <strong style={{ paddingRight: "5px" }}>Tamaño:</strong><i>0.56 Mb</i>
                            <span style={{ margin: "15px 0px" }}>┊</span>
                            <strong style={{ paddingRight: "5px" }}>Fecha:</strong><i>{moment(file.createdAt).format("DD-MMMM-YYYY")}</i>
                        </small>
                        <div style={{overflowX:"auto"}}>
                            {file?.tags?.length>0&&file?.tags?.map((item:any)=>
                            <small className='tag'>
                                {item.name}
                            </small>)}
                        </div>
                    </Col>
                </Row>
            </Row>
            <div>
                <Tooltip className="d-xs-none" title={"Vista previa"} placement="bottom">
                    <Button
                        style={{ margin: "0px 5px" }}
                        shape="circle"
                        icon={<EyeFilled onClick={() => setModalIsOpen(2)} key="show" />}
                        type="dashed"
                        size="middle"
                    />
                </Tooltip>
            </div>
        </List.Item>
        {modalIsOpen != 0 ? <ModalInfoFile file={file} isOpen={modalIsOpen} toggle={setModalIsOpen} /> : <></>}
    </>);
}

export default ListFiles;