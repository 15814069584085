import { FC, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClientForm from "src/modules/allies/components/AllyForm";
import { Row, Col, message, Form, Spin } from "antd";
import SpaceStyled from "src/components/styled/Space";
import { IAllyInput } from "src/core/dtos";
import { getAlly, updateAlly } from "src/services/allies";
import Head from "src/components/Head";
import uploadFile from 'src/services/aws/upload-file';
import { BUCKET_URL } from "src/services/aws/aws-s3-config";

const EditAlly: FC = () => {
  const { id } = useParams();
  const [valuesForm, setValuesForm] = useState<IAllyInput>({
    name: "",
    lastName: "",
    rif: "",
    typeEmployee: "",
    typeVehicle: "",
    idMac: "",
    companyName: "",
    address: "",
    city: "",
    nroPhone: "",
    nroPhone2: "",
    logo: "",
    dueDate: 1,
    email: "",
    // tags: [],
    password: "",
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [error, setError] = useState("");

  const loadAlly = async () => {

    const response: any = getAlly(Number(id));

    const { data, status } = await response || { data: undefined, status: undefined };

    if (!status) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al cargar los datos");
      setIsLoading(false);
      return;
    }

    setValuesForm({
      name: data.data.name,
      lastName: data.data.lastName,
      rif: data.data.rif,
      companyName: data.data.companyName,
      address: data.data.address,
      city: data.data.city,
      typeEmployee: data.data.typeEmployee,
      typeVehicle: data.data.typeVehicle,
      idMac: data.data?.devices[0]?.code,
      nroPhone: data.data.nroPhone,
      nroPhone2: data.data.nroPhone2,
      status: data.data.status,
      logo: data.data.logo,
      dueDate: data.data.dueDate,
      email: data.data.email,
      tags: data.data.tags,
    });

    setIsLoading(false);
  }

  useEffect(() => {
    loadAlly();
  }, [])

  const onFinish = async ({ confirm, ...values }: IAllyInput) => {
    setIsLoadingForm(true)
    let logo = valuesForm.logo;

    if (values.logo) {
      const result = await uploadFile(values.logo.name, values.logo);
      logo = `${BUCKET_URL}/images/${values.logo.name}`;
    }

    const payload = {
      name: values.name,
      lastName: values.lastName,
      rif: values.rif,
      companyName: values.companyName,
      address: values.address,
      city: values.city,
      typeEmployee: values.typeEmployee,
      typeVehicle: values.typeVehicle,
      idMac: values.idMac,
      dueDate: Number(values.dueDate),
      nroPhone: values.nroPhone,
      nroPhone2: values.nroPhone2,
      status: values.status,
      // tags: values.tags||[],
      logo: logo,
    }

    const response: any = updateAlly(Number(id), payload);

    const { data, status } = await response || { data: undefined, status: undefined };

    if (!status) {
      messageApi.error(data?.message || "Ha ocurrido un error al actualizar el aliado");
      setIsLoadingForm(false);
      return;
    }

    if (data.statusCode !== 200) {
      messageApi.error(data?.message || "Ha ocurrido un error al actualizar el aliado");
      setIsLoadingForm(false);
      return;
    }

    navigate("/riders");
    setIsLoadingForm(false);
  };

  useEffect(() => {
    if (error)
      setTimeout(() => {
        setError("");
      }, 5000);
  }, [error]);

  return (
    <>
      {contextHolder}
      <SpaceStyled block={true} direction="vertical">
        <Row justify="center">
          <Col xs={24} md={22}>
            <SpaceStyled block={true} direction="vertical" size="large">
              <Head
                bread={[
                  {
                    title: "Riders",
                    path: "/riders",
                  },
                  {
                    title: "Editar",
                  }
                ]}
              >Editar rider</Head>
              <Spin spinning={isLoading}>
                {!isLoading && (
                  <ClientForm
                    onFinish={onFinish}
                    values={valuesForm}
                    isLoading={isLoadingForm}
                    error={error}
                    form={form}
                  />
                )}
              </Spin>
            </SpaceStyled>
          </Col>
        </Row>
      </SpaceStyled>
    </>
  );
};

export default EditAlly;
