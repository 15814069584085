// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./font/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./font/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./font/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
  }

  * {
    font-family: 'Poppins';
  }

  body {
    overflow: hidden;
    padding: 0px;
    margin: 0px;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .rotate-perpetue {
    animation: spin 60s infinite;
  }

  .tag {
    margin-right: 4px;
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles.css"],"names":[],"mappings":"EAAE;IACE,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,+DAAyD;EAC3D;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,+DAAwD;EAC1D;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,+DAAsD;EACxD;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,WAAW;EACb;;EAEA;IACE;MACE,yBAAyB;IAC3B;EACF;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":["  @font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 400;\n    src: url('./font/Poppins-Regular.ttf') format('truetype');\n  }\n\n  @font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 500;\n    src: url('./font/Poppins-Medium.ttf') format('truetype');\n  }\n\n  @font-face {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 700;\n    src: url('./font/Poppins-Bold.ttf') format('truetype');\n  }\n\n  * {\n    font-family: 'Poppins';\n  }\n\n  body {\n    overflow: hidden;\n    padding: 0px;\n    margin: 0px;\n  }\n  \n  @keyframes spin {\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n\n  .rotate-perpetue {\n    animation: spin 60s infinite;\n  }\n\n  .tag {\n    margin-right: 4px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
