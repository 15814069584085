import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, Typography } from "antd";
import SpaceStyled from "../../../components/styled/Space";
import { ILoginInput } from "../../../core/dtos";
import { LoginFormCard, LoginFormWrapper } from "./LoginFormStyled";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { signIn } from "src/services/auth";
import { useDispatch } from "react-redux";
import {
  setIsLogged,
  setToken,
  setUser,
  setPermissions,
} from "src/redux/slices/auth.slice";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const handleLogin = async (values: ILoginInput) => {
    setLoading(true);

    await signIn(values.email, values.password)
      .then((res) => {
        if (res.status != 200) {
          setError("Error 500, fallo el servidor");
          alert("Error en el servidor");
          setLoading(false);
          return;
        }

        const { data, statusCode } = res.data || {
          data: undefined,
          statusCode: undefined,
        };

        if (statusCode != 200) {
          setError(data?.message || "Ha ocurrido un error");
          setLoading(false);
          return;
        }

        dispatch(
          setUser({
            id: data.id,
            email: data.email,
            name: data.name,
            role: data.role,
            allyId: data.allyId,
            advertiserId: data.advertiserId,
          })
        );

        dispatch(setToken(data.token));
        dispatch(setPermissions(data.permissions));
        dispatch(setIsLogged(true));
        setLoading(false);
      })
      .catch(({ response: { data } }) => {
        setError(data.message);
        setLoading(false);
      });
  };

  return (
    <LoginFormWrapper>
      <div style={{ textAlign: "center" }}>
        <LoginFormCard>
          <Typography.Title level={3}>Iniciar sesión</Typography.Title>
          <Form layout="vertical" onFinish={handleLogin}>
            <Form.Item
              name="email"
              label="Correo electrónico"
              rules={[
                { required: true, message: "Ingrese el correo" },
                { type: "email", message: "El correo es inválido" },
              ]}
              hasFeedback
            >
              <Input
                placeholder="Correo electrónico"
                prefix={<UserOutlined />}
                autoComplete="username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Contraseña"
              rules={[
                { required: true, message: "Ingrese la contraseña" },
                {
                  min: 8,
                  message: "La contraseña debe tener al menos 8 caracteres",
                },
                {
                  max: 20,
                  message: "La contraseña debe tener máximo 20 caracteres",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Contraseña"
                prefix={<LockOutlined />}
                autoComplete="password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                block={true}
                htmlType="submit"
                loading={loading}
              >
                Iniciar sesión
              </Button>
            </Form.Item>

            <Form.Item>
              <Typography.Text>
                <Link to="/auth/forgot">¿Olvidaste tu contraseña?</Link>
              </Typography.Text>
            </Form.Item>
            {error && (
              <div
                className="ant-typography ant-typography-danger css-dev-only-do-not-override-171nnr3"
                style={{ textAlign: "center", color: "red" }}
                onMouseEnter={() => setError(null)}
              >
                {error}
              </div>
            )}
          </Form>
        </LoginFormCard>
      </div>
    </LoginFormWrapper>
  );
};

export default LoginForm;
