import { IAPISourceList, getParams } from "src/core/dtos";
import instance from "./_http";

export const getFilesDevices = (id: number, params?: IAPISourceList<"Files">[]) => instance.get(`/files/deviceall/${id}${getParams(params)}`).catch(({ response })=>response);
export const getFiles = (params?: IAPISourceList<"Files">[]) => instance.get(`/files${getParams(params)}`).catch(({ response })=>response);
export const getFilesByPackageId = (id: number, params?: IAPISourceList<"Files">[]) => instance.get(`/files/packageall/${id}${getParams(params)}`).catch(({ response })=>response);
export const createFile = (data: any) => instance.post("/files", data, { headers: { "Content-Type": "application/json" } }).catch(({ response })=>response);
export const updateFile = (id: number, data: any) => instance.put(`/files/${id}`, data, { headers: { "Content-Type": "application/json" } }).catch(({ response })=>response);
export const deleteFile = (id: number) => instance.delete(`/files/${id}`).catch(({ response })=>response);
export const createFilesDevice = (data: any) => instance.post("/devicesfiles", data, { headers: { "Content-Type": "application/json" } }).catch(({ response })=>response);
export const cloneDevice = (id: number, data: any) => instance.post(`/devicesfiles/clone/${id}`, data, { headers: { "Content-Type": "application/json" } }).catch(({ response })=>response);
export const getTotalDelay = () => instance.get(`/files/delay/all`).catch(({ response })=>response);