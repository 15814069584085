import ImgCrop from 'antd-img-crop';
import React, { useEffect, useState } from 'react';
import { Button, Image, Space, Upload, message } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import { DeleteOutlined, DownloadOutlined, EyeOutlined, UploadOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { fallback } from 'src/core/constants';
import { updateAlly } from 'src/services/allies';
import uploadFile from 'src/services/aws/upload-file';
import { BUCKET_URL } from 'src/services/aws/aws-s3-config';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface ImageProfileProps {
  id?: number,
  initialValue?: string,
  setValue?: (value: RcFile) => void
}

const ImageProfile: React.FC<ImageProfileProps> = ({ id, initialValue, setValue }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [newFile, setNewFile] = useState<RcFile>();
  const [file, setFile] = useState({ url: initialValue || '' });

  useEffect(() => {
    if (initialValue) setFile({ url: initialValue });
  }, [initialValue])

  const upload = async (img: RcFile) => {
    const url = `${BUCKET_URL}/images/${img.name}`;
    
    if (!id && !!setValue) return setValue(img)

    const result = await uploadFile(img.name, img);
    const response: any = updateAlly(Number(id), { logo: url });

    const { data, status } = await response || { data: undefined, status: undefined };

    if (!status) {
      // messageApi.error(data?.message || "Ha ocurrido un error al actualizar la imagen");
      setIsLoading(false);
      return;
    }

    if (data.statusCode !== 200) {
      // messageApi.error(data?.message || "Ha ocurrido un error al actualizar la imagen");
      setIsLoading(false);
      return;
    }
  }
  const onDownload = () => {
    fetch(file.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block', width: '160px', height: '160px' }}>
      {contextHolder}
      <Image
        fallback={fallback}
        width={150}
        src={file.url}
        preview={{
          mask: <EyeOutlined style={{ justifyContent: 'center', alignItems: 'center', fontSize: '20px' }} />,
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: { onZoomOut, onZoomIn },
            },
          ) => (
            <Space size={20} className="toolbar-wrapper">
              <DeleteOutlined onClick={onDownload} style={{ fontSize: '23px' }} />
              <DownloadOutlined onClick={onDownload} style={{ fontSize: '23px' }} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} style={{ fontSize: '23px' }} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} style={{ fontSize: '23px' }} />
            </Space>
          ),
        }}
      />
      <ImgCrop showReset rotationSlider>
        <Upload
          beforeUpload={(img) => {
            setFile({ url: URL.createObjectURL(img) })
            upload(img)
          }}
          accept="image/png, image/jpeg, image/jpg"
          showUploadList={false}
        >
          <Button
            type="primary"
            shape="circle"
            loading={isLoading}
            icon={<UploadOutlined />}
            size='large'
            style={{ position: 'absolute', bottom: 0, right: 0, borderColor: '#ffffff', borderWidth: '2px' }}
          />
        </Upload>
      </ImgCrop>
    </div>
  );
};

export default ImageProfile;