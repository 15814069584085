import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  Modal,
  message,
  Form,
  Button,
  Input,
  Divider,
  Row,
  Col,
  Select,
  Space,
} from "antd";
import { updateFile } from "src/services/files";
import { IAdvertiser, IFile } from "src/core/dtos";
import { TagsInput } from "react-tag-input-component";
import PreviewMultimedia from "./PreviewMultimedia";
import { getAdvertisers } from "src/services/advertisers";
import usePageLimit from "src/hooks/usePageLimit";

export interface IModalEditFile {
  isOpen: boolean;
  toggle: (value: boolean) => void;
  file: IFile;
  // setFiles: Dispatch<SetStateAction<FileList | null>>;
  setActiveEvent?: (first?: boolean) => void;
}

const ModalEditFile = ({
  isOpen,
  toggle,
  setActiveEvent,
  file,
}: IModalEditFile) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { page, limit, handleLimitChange, handlePageChange } = usePageLimit();
  const [search, setSearch] = useState("");
  /*  const [tags, setTags] = useState<string[]>(); */
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const video_ref = useRef<HTMLVideoElement>(null);
  const [extension, setExtension] = useState("");
  const [delay, setDelay] = useState<number>(file.delay);
  const [data, setData] = useState<File>();
  const [advertisers, setAdvertisers] = useState<IAdvertiser[]>([]);
  const [total, setTotal] = useState(0);

  const loadAdvertisers = async () => {
    setIsLoading(true);

    const response: any = getAdvertisers([
      { key: "page", value: page },
      { key: "limit", value: limit },
      { key: "search", value: String(search) },
      /*      { key: "tags", value: String(tags?.toString()) }, */
    ]);

    const { data, status } = (await response) || {
      data: undefined,
      status: undefined,
    };

    if (!status) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    if (data?.statusCode !== 200) {
      messageApi.error(
        data?.message || "Ha ocurrido un error al cargar los datos"
      );
      setIsLoading(false);
      return;
    }

    setAdvertisers(data?.data);
    setTotal(data?.total);

    setIsLoading(false);
  };

  const loadData = async () => {
    setExtension(`.${file?.name.split(".").pop()}`);
    form.setFieldValue("name", getName(String(file.name)));
    form.setFieldValue("description", file.description);
    form.setFieldValue("delay", file.delay);
    form.setFieldValue("advertiserId", file.advertiserId);
    form.setFieldValue("type", String(file.name).includes("src") ? "src" : "");
    form.setFieldValue(
      "tags",
      file.tags.map((i: any) => i.name)
    );
  };

  const loads = async () => {
    setIsLoading(true);
    await loadData();
    await loadAdvertisers();
    setIsLoading(false);
  };

  const closeModal = () => {
    toggle(false);
    setIsLoading(false);
    form.resetFields();
    video_ref.current?.pause();
  };

  useEffect(() => {
    isOpen && loads();
  }, [file]);

  const getName = (data: string) => {
    const lastIndex = data.lastIndexOf(".");
    return data.substring(0, lastIndex);
  };

  const uploadFile = async (values: any) => {
    const { advertiserId } = values;

    setIsLoading(true);

    const initial = {
      name: String(values.name).toLocaleLowerCase() + extension,
      description: values.description,
      delay: delay,
      type: file.type,
      advertiserId: advertiserId,
      tags: values.tags || [],
      url: file.url,
    };

    const response = await updateFile(file.id, initial);

    if (response.data.statusCode != 200) {
      messageApi.error(response.data.message);
      setIsLoading(false);
      return;
    }

    messageApi.success(
      response.data.message || "Se ha actualizado el archivo correctamente"
    );
    setActiveEvent && setActiveEvent(true);
    setTimeout(() => {
      setIsLoading(false);
      closeModal();
    }, 1000);
  };

  return (
    <Modal
      width={720}
      open={isOpen}
      footer={<></>}
      maskClosable={false}
      onCancel={closeModal}
      destroyOnClose={true}
      title={`Editar archivo`}
    >
      <Form key={file.id} form={form} layout="vertical" onFinish={uploadFile}>
        <Row gutter={15}>
          <Col xs={12}>
            {file && (
              <PreviewMultimedia
                form={form}
                file={file}
                video_ref={video_ref}
              />
            )}
          </Col>
          <Col xs={12}>
            <Form.Item
              name="name"
              label="Nombre"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese un nombre",
                },
              ]}
            >
              <Input suffix={extension} defaultValue={""} />
            </Form.Item>
            <Form.Item
              name="description"
              label="Descripción"
              rules={[
                {
                  required: true,
                  message: "Por favor ingrese una descripción",
                },
              ]}
            >
              <Input defaultValue={""} placeholder="Descripción" />
            </Form.Item>
            <Form.Item name="advertiserId" label="Anunciante">
              <Select
                showSearch
                placeholder="Seleccione un anunciante"
                options={advertisers.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="delay"
              label="Duración (segundos)"
              rules={[
                { required: true, message: "Por favor ingrese la duración" },
              ]}
            >
              <Space.Compact style={{ width: "100%" }}>
                <Input
                  value={delay}
                  placeholder="Duración"
                  onKeyDown={(e) => {
                    if (
                      ![
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "0",
                        "Backspace",
                      ].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const current = Number(e.target.value);
                    setDelay(current < 500 ? current : 500);
                    form.setFieldValue(
                      "delay",
                      Number(current < 500 ? current : 500)
                    );
                  }}
                />
                <Button
                  type="dashed"
                  onClick={() => {
                    if (!video_ref?.current) return;
                    setDelay(video_ref?.current?.duration);
                    form.setFieldValue(
                      "delay",
                      Number(video_ref?.current?.duration)
                    );
                  }}
                >
                  Completo
                </Button>
              </Space.Compact>
            </Form.Item>
            <Form.Item name="tags" label="Tags del archivo">
              <TagsInput
                separators={[",", "#", " ", "Enter"]}
                placeHolder="Tags"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ marginTop: "10px" }} />
        <Row justify={"end"} align={"middle"}>
          <Button
            onClick={closeModal}
            type="default"
            htmlType="button"
            style={{ marginRight: 15 }}
          >
            Volver
          </Button>
          <Button loading={isLoading} type="primary" htmlType="submit">
            {isLoading ? "Un momento" : "Guardar cambios"}
          </Button>
        </Row>
      </Form>
      {contextHolder}
    </Modal>
  );
};

export default ModalEditFile;
