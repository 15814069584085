import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LANGUAGE, LAYOUT, THEME } from "../../core/enums";
import { ISettingsState } from "../../core/interfaces";

const initialState: ISettingsState = {
  theme: THEME.LIGHT,
  language: LANGUAGE.SPANISH,
  layout: LAYOUT.DESKTOP,
  open: true,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setTheme: (state, { payload }: PayloadAction<THEME>) => {
      state.theme = payload;
    },
    setLanguage: (state, { payload }: PayloadAction<LANGUAGE>) => {
      state.language = payload;
    },
    setLayout: (state, { payload }: PayloadAction<LAYOUT>) => {
      state.layout = payload;
    },
    setOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.open = payload;
    },
  },
});

export const { setTheme, setLanguage, setLayout, setOpen } =
  settingsSlice.actions;

export default settingsSlice.reducer;

export const settingsSelector = (state: { settingsStore: ISettingsState }) =>
  state.settingsStore;
